// ========================================
// TEXT ALIGNS
// ========================================

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

@include respond-above(xs) {
  .text-start-xs {
    text-align: left;
  }

  .text-end-xs {
    text-align: right;
  }

  .text-center-xs {
    text-align: center;
  }
}

@include respond-above(sm) {
  .text-start-sm {
    text-align: left;
  }

  .text-end-sm {
    text-align: right;
  }

  .text-center-sm {
    text-align: center;
  }
}

@include respond-above(md) {
  .text-start-md {
    text-align: left;
  }

  .text-end-md {
    text-align: right;
  }

  .text-center-md {
    text-align: center;
  }
}

@include respond-above(lg) {
  .text-start-lg {
    text-align: left;
  }

  .text-end-lg {
    text-align: right;
  }

  .text-center-lg {
    text-align: center;
  }
}

@include respond-above(xl) {
  .text-start-xl {
    text-align: left;
  }

  .text-end-xl {
    text-align: right;
  }

  .text-center-xl {
    text-align: center;
  }
}
