$info-colors: (
  '': (
    color: var(--primary-700),
  ),
  '-warning': (
    color: var(--warning-500),
  ),
  '-error': (
    color: var(--error-500),
  ),
  '-gray': (
    color: var(--gray-100),
  ),
  '-secondary': (
    color: var(--secondary-500),
  ),
  '-purple': (
    color: var(--purple),
  )
);

.info-box {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(150, 160, 169, .07);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 55px;
  position: relative;

  &.info-box--warning-bg {
    background-color: var(--warning-100);
  }

  &::before {
    border-radius: 10px 0 0 10px;
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 5px;
  }

  &::after {
    content: '\68';
    font-family: 'iconomi-icons';
    font-size: 20px;
    left: 25px;
    position: absolute;
    top: 20px;
  }
}

@each $color, $value in $info-colors {
  .info-box#{$color} {

    &::before {
      background-color: map-get($value, 'color');
    }

    &::after {
      color: map-get($value, 'color');
    }

    &.info-box-with-border {
      border-color: var(--gray-100);
    }
  }
}

.info-box-no-icon {
  padding: 30px;

  &::after {
    content: '';
  }
}

.info-box-with-border {
  border-style: solid;
  border-width: 1px 1px 1px 0;
  box-shadow: none;
}


