.emoji-image,
.emoji-image-native {
  display: inline-block;
  font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'Android Emoji';
  height: 18px;
  margin: 0 1px -4px;
  width: 18px;
}

.emoji-image {
  background-image: url('/assets/images/emojies.webp');
}
