// ========================================
// FONTS DECLARATIONS
// ========================================

@font-face {
  @include typography-regular();
  font-display: swap;
  font-family: 'Nunito';
  // sass-lint:disable-block indentation
  src: local('Nunito'),
  url('../../fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  @include typography-extra-bold();
  font-display: swap;
  font-family: 'Nunito';
  // sass-lint:disable-block indentation
  src: local('Nunito'),
  url('../../fonts/Nunito/Nunito-Extra-Bold.ttf') format('truetype');
}
