@import '../mixins/media-queries';

// ========================================
// HEADINGS
// ========================================

%h1 {
  @include typography-extra-bold();
  font-size: 56px;
}

.h1 {
  &-black {
    @extend %h1;
    color: var(--black-900);
  }

  &-white {
    @extend %h1;
    color: var(--white);
  }
}

%h1-lander {
  font-family: Nunito;
  font-size: 56px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 64px;

  @include respond-below(lg) {
    font-size: 34px;
    line-height: 1.2;
  }
}

.h1-lander {
  &-black {
    @extend %h1-lander;
    color: var(--black-900);
  }

  &-white {
    @extend %h1-lander;
    color: var(--white);
  }
}

%h2 {
  @include typography-extra-bold();
  font-size: 32px;
}

.h2 {
  &-black {
    @extend %h2;
    color: var(--black-900);
  }

  &-white {
    @extend %h2;
    color: var(--white);
  }
}

%h2-lander {
  font-family: Nunito;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 56px;

  @include respond-below(lg) {
    font-size: 34px;
    line-height: 1.2;
  }
}

.h2-lander {
  &-black {
    @extend %h2-lander;
    color: var(--black-900);
  }

  &-white {
    @extend %h2-lander;
    color: var(--white);
  }
}

%h3 {
  @include typography-extra-bold();
  font-size: 22px;
}

%h3-lander {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;

  @include respond-below(lg) {
    font-size: 22px;
    line-height: 1.2;
  }
}

.h3 {
  &-black {
    @extend %h3;
    color: var(--black-900);
  }

  &-white {
    @extend %h3;
    color: var(--white);
  }

  &-primary-500 {
    @extend %h3;
    color: var(--primary-700);
  }
}

.h3-lander {
  &-black {
    @extend %h3-lander;
    color: var(--black-900);
  }

  &-white {
    @extend %h3-lander;
    color: var(--white);
  }
}

%h4 {
  @include typography-extra-bold();
  font-size: 18px;
}

.h4 {
  &-black {
    @extend %h4;
    color: var(--black-900);

    &-bold {
      @extend %h4;
      @include typography-extra-bold();
      color: var(--black-900);
    }
  }

  &-white {
    @extend %h4;
    color: var(--white);
  }

  &-gray-900 {
    @extend %h4;
    color: var(--gray-900);
  }
}

%h5 {
  @include typography-extra-bold();
  font-size: 16px;
}

.h5 {
  &-black {
    @extend %h5;
    color: var(--black-900);
  }

  &-white {
    @extend %h5;
    color: var(--white);
  }

  &-primary {
    @extend %h5;
    color: var(--primary-700);

    &-regular {
      @extend %h5;
      @include typography-regular();
      color: var(--primary-700);
    }
  }

  &-gray {
    @extend %h5;
    @include typography-regular();
    color: var(--gray-900);
  }

}

%h6 {
  @include typography-extra-bold();
  font-size: 14px;
}

.h6 {
  &-black {
    @extend %h6;
    color: var(--black-900);
  }

  &-white {
    @extend %h6;
    color: var(--white);
  }
}

// ========================================
// PARAGRAPHS
// ========================================

%paragraph-black {
  color: var(--black-900);
}

%paragraph-black-300 {
  color: var(--black-300);
}

%paragraph-white {
  color: var(--white);
}

%paragraph-gray-300 {
  color: var(--gray-300);
}

%paragraph-gray-500 {
  color: var(--gray-500);
}

%paragraph-gray-900 {
  color: var(--gray-900);
}

%paragraph-primary {
  color: var(--primary-700);
}

%paragraph-secondary {
  color: var(--secondary-500);
}

%paragraph-danger-500 {
  color: var(--negative-500);
}

%paragraph-error-500 {
  color: var(--error-500);
}

%paragraph-positive-500 {
  color: var(--positive-500);
}

%paragraph-primary-700-business {
  color: var(--primary-700-business);
}

%paragraph-regular {
  @include typography-regular();
}

%paragraph-bold {
  @include typography-extra-bold();
}

%paragraph {
  font-size: 14px;
}

%lander-paragraph {
  font-size: 16px;
}

.lander-paragraph {
  &-black {
    &-regular {
      @extend %lander-paragraph;
      @extend %paragraph-black;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %lander-paragraph;
      @extend %paragraph-black;
      @include typography-extra-bold();
    }
  }

  &-white {
    &-regular {
      @extend %lander-paragraph;
      @extend %paragraph-white;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %lander-paragraph;
      @extend %paragraph-white;
      @include typography-extra-bold();
    }
  }

  &-gray-900 {
    &-regular {
      @extend %lander-paragraph;
      @extend %paragraph-gray-900;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %lander-paragraph;
      @extend %paragraph-gray-900;
      @include typography-extra-bold();
    }
  }
}


.paragraph {
  &-black {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-black;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-black;
      @extend %paragraph-bold;
    }
  }

  &-black-300 {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-black-300;
      @extend %paragraph-regular;
    }
  }

  &-white {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-white;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-white;
      @extend %paragraph-bold;
    }
  }

  &-gray-300 {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-gray-300;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-gray-300;
      @extend %paragraph-bold;
    }
  }

  &-gray-500 {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-gray-500;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-gray-500;
      @extend %paragraph-bold;
    }
  }

  &-gray-900 {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-gray-900;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-gray-900;
      @extend %paragraph-bold;
    }
  }

  &-primary {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-primary;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-primary;
      @extend %paragraph-bold;
    }
  }

  &-secondary {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-secondary;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-secondary;
      @extend %paragraph-bold;
    }
  }

  &-error {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-error-500;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-error-500;
      @extend %paragraph-bold;
    }
  }

  &-primary-700-business {
    &-regular {
      @extend %paragraph;
      @extend %paragraph-primary-700-business;
      @extend %paragraph-regular;
    }

    &-bold {
      @extend %paragraph;
      @extend %paragraph-primary-700-business;
      @extend %paragraph-bold;
    }
  }
}

// ========================================
// LABELS
// ========================================

%label-black {
  color: var(--black-900);
}

%label-black-300 {
  color: var(--black-300);
}

%label-white {
  color: var(--white);
}

%label-gray-300 {
  color: var(--gray-300);
}

%label-gray-500 {
  color: var(--gray-500);
}

%label-primary-700 {
  color: var(--primary-700);
}

%label-gray-900 {
  color: var(--gray-900);
}

%label-error {
  color: var(--error-500);
}

%label-positive {
  color: var(--positive-500);
}

%label-regular {
  @include typography-regular();
}

%label-bold {
  @include typography-extra-bold();
}

%label {
  font-size: 12px;
}

%lander-label {
  font-size: 14px;
}

.label {
  &-black {
    &-regular {
      @extend %label;
      @extend %label-black;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-black;
      @extend %label-bold;
    }
  }

  &-black-300 {
    &-regular {
      @extend %label;
      @extend %label-black-300;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-black-300;
      @extend %label-bold;
    }
  }

  &-white {
    &-regular {
      @extend %label;
      @extend %label-white;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-white;
      @extend %label-bold;
    }
  }

  &-gray-300 {
    &-regular {
      @extend %label;
      @extend %label-gray-300;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-gray-300;
      @extend %label-bold;
    }
  }

  &-gray-500 {
    &-regular {
      @extend %label;
      @extend %label-gray-500;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-gray-500;
      @extend %label-bold;
    }
  }

  &-gray-900 {
    &-regular {
      @extend %label;
      @extend %label-gray-900;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-gray-900;
      @extend %label-bold;
    }
  }

  &-primary-700 {
    &-regular {
      @extend %label;
      @extend %label-primary-700;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-primary-700;
      @extend %label-bold;
    }
  }

  &-error {
    &-regular {
      @extend %label;
      @extend %label-error;
      @extend %label-regular;
    }

    &-bold {
      @extend %label;
      @extend %label-error;
      @extend %label-bold;
    }
  }

  &-positive {
    &-regular {
      @extend %label;
      @extend %paragraph-positive-500;
      @extend %label-regular;
    }
  }

  &-negative {
    &-regular {
      @extend %label;
      @extend %paragraph-danger-500;
      @extend %label-regular;
    }
  }

  &-primary {
    &-bold {
      @extend %label;
      @extend %label-bold;
      color: var(--primary-700);
    }
  }

  &-info-icon {
    &::before {
      align-self: center;
      margin-right: 5px;
    }
  }

  &-secondary {
    &-regular {
      @extend %label;
      @extend %paragraph-secondary;
      @extend %label-regular;
    }
  }
}

// ========================================
// LINKS
// ========================================

// TO DO: define states (hover, active, disabled)
%link-small {
  cursor: pointer;
  font-size: 12px;
  transition: transform .2s;
}

%link-big {
  cursor: pointer;
  font-size: 14px;
  transition: transform .2s;
}

%link-primary {
  color: var(--primary-700);

  &:visited {
    color: var(--primary-700);
  }

  &:hover,
  &:focus,
  &:visited:hover {
    color: var(--primary-900);
    text-decoration: underline;
  }
}

%link-secondary {
  color: var(--secondary-500);

  &:visited {
    color: var(--secondary-500);
  }

  &:hover,
  &:focus,
  &:visited:hover {
    color: var(--secondary-900);
    text-decoration: underline;
  }
}

%link-white {
  color: var(--white);
  text-decoration: underline;

  &:visited {
    color: var(--white);
  }

  &:hover,
  &:focus,
  &:visited:hover {
    color: var(--white);
  }
}

%link-regular {
  @include typography-regular();
}

%link-bold {
  @include typography-extra-bold();
}

%link-primary-business {
  color: var(--primary-700-business);
  text-decoration: underline;

  &:visited {
    color: var(--primary-700-business);
  }

  &:hover,
  &:focus,
  &:visited:hover {
    color: var(--primary-500-business);
  }
}

.link-small {
  &-primary {
    &-regular {
      @extend %link-small;
      @extend %link-primary;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-small;
      @extend %link-primary;
      @extend %link-bold;
    }
  }

  &-secondary {
    &-regular {
      @extend %link-small;
      @extend %link-secondary;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-small;
      @extend %link-secondary;
      @extend %link-bold;
    }
  }

  &-white {
    &-regular {
      @extend %link-small;
      @extend %link-white;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-small;
      @extend %link-white;
      @extend %link-bold;
    }
  }

  &-primary-business {
    &-regular {
      @extend %link-small;
      @extend %link-primary-business;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-small;
      @extend %link-primary-business;
      @extend %link-bold;
    }
  }
}

.lander-link-big {
  &-primary {
    &-regular {
      @extend %link-big;
      @extend %link-primary;
      @extend %link-regular;
      font-size: 16px;
    }
  }
}

.link-big {
  &-primary {
    &-regular {
      @extend %link-big;
      @extend %link-primary;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-big;
      @extend %link-primary;
      @extend %link-bold;
    }
  }

  &-secondary {
    &-regular {
      @extend %link-big;
      @extend %link-secondary;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-big;
      @extend %link-secondary;
      @extend %link-bold;
    }
  }

  &-white {
    &-regular {
      @extend %link-big;
      @extend %link-white;
      @extend %link-regular;
    }

    &-bold {
      @extend %link-big;
      @extend %link-white;
      @extend %link-bold;
    }
  }
}

.lander-label {
  &-black {
    &-regular {
      @extend %lander-label;
      @extend %label-black;
      @extend %label-regular;
    }

    &-bold {
      @extend %lander-label;
      @extend %label-black;
      @extend %label-bold;
    }
  }

  &-gray-900 {
    &-regular {
      @extend %lander-label;
      @extend %label-gray-900;
      @extend %label-regular;
    }

    &-bold {
      @extend %lander-label;
      @extend %label-gray-900;
      @extend %label-bold;
    }
  }

  &-white {
    &-regular {
      @extend %lander-label;
      @extend %label-white;
      @extend %label-regular;
    }

    &-bold {
      @extend %lander-label;
      @extend %label-white;
      @extend %label-bold;
    }
  }
}

.link-disabled {
  cursor: not-allowed;
  opacity: .5;
}

// ========================================
// PERFORMANCE
// ========================================

%performance-big {
  @include typography-extra-bold();
  color: var(--black-900);
  font-size: 22px;
}

%performance-medium {
  @include typography-extra-bold();
  color: var(--black-900);
  font-size: 16px;
}

%performance-small {
  @include typography-extra-bold();
  color: var(--black-900);
  font-size: 14px;
}

%performance-extra-small {
  @include typography-extra-bold();
  color: var(--black-900);
  font-size: 12px;
}

.performance-big {
  @extend %performance-big;

  &-positive {
    @extend %performance-big;
    color: var(--positive-500);
  }

  &-negative {
    @extend %performance-big;
    color: var(--negative-500);
  }

  &-neutral {
    @extend %performance-big;
    color: var(--black-900);
  }
}

.performance-medium {
  @extend %performance-medium;

  &-positive {
    @extend %performance-medium;
    color: var(--positive-500);
  }

  &-negative {
    @extend %performance-medium;
    color: var(--negative-500);
  }

  &-neutral {
    @extend %performance-medium;
    color: var(--black-900);
  }
}

.performance-small {
  @extend %performance-small;

  &-positive {
    @extend %performance-small;
    color: var(--positive-500);
    font-weight: 400;
  }

  &-negative {
    @extend %performance-small;
    color: var(--negative-500);
    font-weight: 400;
  }

  &-neutral {
    @extend %performance-small;
    color: var(--black-900);
    font-weight: 400;
  }

  &-white {
    @extend %performance-small;
    color: var(--white);
  }

  &-gray-900 {
    @extend %performance-small;
    color: var(--gray-900);
  }
}

.performance-extra-small {
  @extend %performance-extra-small;

  &-positive {
    @extend %performance-extra-small;
    color: var(--positive-500);
    font-weight: 400;
  }

  &-negative {
    @extend %performance-extra-small;
    color: var(--negative-500);
    font-weight: 400;
  }

  &-neutral {
    @extend %performance-extra-small;
    color: var(--black-900);
    font-weight: 400;
  }

  &-white {
    @extend %performance-extra-small;
    color: var(--white);
  }

  &-gray-900 {
    @extend %performance-extra-small;
    color: var(--gray-900);
  }
}

%status-indicator-dot {
  &::before {
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }
}

.status-indicator {
  &-small {
    @extend %label;
    @extend %label-black;
    @extend %label-regular;

    @extend %status-indicator-dot;

    &.green {
      &::before {
        background: var(--positive-500);
      }
    }

    &.orange {
      &::before {
        background: var(--warning-500);
      }
    }

    &.red {
      &::before {
        background: var(--negative-500);
      }
    }

    &.gray {
      &::before {
        background: var(--gray-500);
      }
    }
  }

  &-big {
    @extend %paragraph;
    @extend %paragraph-black;
    @extend %paragraph-bold;

    @extend %status-indicator-dot;

    &.green {
      &::before {
        background: var(--positive-500);
      }
    }

    &.orange {
      &::before {
        background: var(--warning-500);
      }
    }

    &.red {
      &::before {
        background: var(--negative-500);
      }
    }

    &.gray {
      &::before {
        background: var(--gray-500);
      }
    }
  }
}


$font-sizes: (
  8: 8px,
  10: 10px,
  12: 12px,
  14: 14px,
  16: 16px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  28: 28px,
  32: 32px,
  40: 40px,
);

// sass-lint:disable no-important
@each $size, $length in $font-sizes {
  .fs-#{$size} {
    font-size: #{$length} !important;
  }
}

.word-break-all {
  word-break: break-all;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.color-black {
  color: var(--black-900);
}

.color-gray-50 {
  color: var(--gray-50);
}

.color-gray-100 {
  color: var(--gray-100);
}

.color-gray-300 {
  color: var(--gray-300);
}

.color-gray-500 {
  color: var(--gray-500);
}

.color-gray-900 {
  color: var(--gray-900);
}

.color-secondary-500 {
  color: var(--secondary-500);
}

.color-primary-500 {
  color: var(--primary-700);
}

.color-primary-100 {
  color: var(--primary-100);
}

.color-negative-500 {
  color: var(--negative-500);
}

.color-error-500 {
  color: var(--error-500);
}

.color-warning-500 {
  color: var(--warning-500);
}

.color-warning-900 {
  color: var(--warning-900);
}

.color-positive-500 {
  color: var(--positive-500);
}

.color-white {
  color: var(--white);
}

.color-gold-500 {
  color: var(--gold-500);
}

.color-orange {
  color: var(--orange-300);
}

.color-primary-700-business {
  color: var(--primary-700-business);
}

.text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-preline {
  white-space: pre-line;
}

.text-decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

// ========================================
// GLOBAL
// ========================================
b,
.font-weight-bold {
  font-weight: 800;
}

[class^='lander-paragraph-'],
[class^='paragraph-'] {
  a {
    color: var(--primary-700);
  }
}

.line-height-1 {
  line-height: 1;
}
