// ========================================
// ROOT PROPERTIES MIXIN
// ========================================

// use this mixin to include root properties (starting with double hypen)
// reason behind this as the current scss lint implementation does not support them correctly yet

@mixin root-property($property: null, $value: null) {
  @if ($property and $value) {
    #{$property}: $value;
  }
}
