@import '../variables/variables';
@import '../mixins/mixins';

// ========================================
// IMAGES
// ========================================

img {
  &.responsive-img {
    @include respond-below(sm) {
      width: 100%;
    }
  }
}
