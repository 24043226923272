.illustration-s {
  height: 100px;
}

.illustration-m {
  height: 120px;
}

.illustration-l {
  height: 200px;
}
