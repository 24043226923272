@import '../variables/grid-breakpoints';
@import '../mixins/media-queries';
@import '../mixins/icons-style';

// sass-lint:disable no-important
// sass-lint:disable nesting-depth
$button-colors: (
  'primary': (
    color: var(--white),
    background: var(--primary-500),
    hoverBackground: var(--primary-900),
    disabledBackground: var(--primary-100),
  ),
  'primary-50': (
    color: var(--primary-700),
    background: var(--primary-50),
    hoverBackground: var(--primary-100),
    disabledBackground: var(--primary-100),
  ),
  'secondary': (
    color: var(--white),
    background: var(--positive-500),
    hoverBackground: var(--secondary-900),
    disabledBackground: var(--secondary-100),
  ),
  'secondary-50': (
    color: var(--secondary-500),
    background: var(--secondary-50),
    hoverBackground: var(--secondary-100),
    disabledBackground: var(--secondary-100),
  ),
  'black': (
    color: var(--white),
    background: var(--black-900),
    hoverBackground: var(--black-900),
    disabledBackground: var(--black-900),
  ),
  'gray': (
    color: var(--white),
    background: var(--gray-500),
    hoverBackground: var(--gray-900),
    disabledBackground: var(--gray-100),
  ),
  'gray-100': (
    color: var(--black-900),
    background: var(--gray-100),
    hoverBackground: var(--gray-500),
    disabledBackground: var(--gray-100),
  ),
  'primary-100': (
    color: var(--primary-700),
    background: var(--primary-100),
    hoverBackground: var(--primary-50),
    disabledBackground: var(--primary-50),
  ),
  'warning': (
    color: var(--white),
    background: var(--warning-500),
    hoverBackground: var(--warning-900),
    disabledBackground: var(--gray-500),
  ),
  'primary-business': (
    color: var(--white),
    background: var(--primary-700-business),
    hoverBackground: #373e62,
    disabledBackground: var(--primary-100-business),
  ),
  'secondary-business': (
    color: var(--primary-700-business),
    background: var(--primary-50-business),
    hoverBackground: var(--primary-50-business),
    disabledBackground: var(--primary-100-business),
  ),
);

$button-sizes: (
  'lg': (
    fontSize: 14px,
    fontWeight: 800,
    lineHeight: 1.71,
    height: 52px,
    minWidth: 100px,
  ),
);

.btn {
  @include typography-extra-bold();
  background-color: var(--gray-500);
  border: 1px solid transparent;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  min-height: 35px;
  padding: 0 20px;
  text-align: center;
  transition: transform .2s;
  user-select: none;
  vertical-align: middle;

  @at-root {
    a#{&} {
      align-items: center;
      display: flex;
      justify-content: center;
      text-decoration: none;

      &:hover,
      &:focus,
      &.focus {
        color: var(--white);
      }
    }
  }

  &:focus,
  &.focus {
    box-shadow: none;
    outline: 0;
  }

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    background-color: var(--gray-900);
  }

  &.disabled,
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: .5;
  }

  &:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.btn-loading {
    background-image: url('/assets/images/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    color: transparent !important;
  }
}

@each $color, $value in $button-colors {
  .btn-#{$color} {
    background-color: map-get($value, 'background');
    color: map-get($value, 'color');

    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled) {
      background-color: map-get($value, 'hoverBackground');
    }

    &.disabled,
    &:disabled {
      opacity: .5;
    }

    @at-root {
      a#{&} {
        color: map-get($value, 'color');

        &:hover,
        &:focus,
        &.focus {
          color: map-get($value, 'color');
        }
      }
    }
  }
}

.btn-white {
  background-color: var(--white);
  color: var(--black-900);

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    background-color: var(--white);
    opacity: .8;
  }

  &.disabled,
  &:disabled {
    background-color: var(--white);
    opacity: .5;
  }

  @at-root {
    a#{&} {
      color: var(--black-900);

      &:hover,
      &:focus,
      &.focus {
        color: var(--black-900);
      }
    }
  }
}

.btn-outline-white {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    background-color: transparent;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
  }

  @at-root {
    a#{&} {
      color: var(--white);

      &:hover,
      &:focus,
      &.focus {
        color: var(--white);
      }
    }
  }
}

.btn-ghost-white {
  background-color: transparent;
  color: var(--white);

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    background-color: transparent;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
  }

  @at-root {
    a#{&} {
      color: var(--white);

      &:hover,
      &:focus,
      &.focus {
        color: var(--white);
      }
    }
  }
}

@each $color, $value in $button-colors {
  .btn-ghost-#{$color} {
    background-color: transparent;
    color: map-get($value, 'background');

    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled) {
      background-color: transparent;
      color: map-get($value, 'hoverBackground');
    }

    &.disabled,
    &:disabled {
      background-color: transparent;
      color: map-get($value, 'disabledBackground');
    }

    @at-root {
      a#{&} {
        color: map-get($value, 'background');

        &:hover,
        &:focus,
        &.focus {
          color: map-get($value, 'background');
        }

        &.disabled,
        &:disabled {
          color: map-get($value, 'disabledBackground');
        }
      }
    }
  }
}

@each $color, $value in $button-colors {
  .btn-outline-#{$color} {
    background-color: transparent;
    border-color: map-get($value, 'background');
    color: map-get($value, 'background');

    &:hover:not(.disabled):not(:disabled),
    &:focus:not(.disabled):not(:disabled) {
      background-color: transparent;
      border-color: map-get($value, 'hoverBackground');
      color: map-get($value, 'hoverBackground');
    }

    &.disabled,
    &:disabled {
      background-color: transparent;
      border-color: map-get($value, 'disabledBackground');
      color: map-get($value, 'disabledBackground');
    }

    @at-root {
      a#{&} {
        color: map-get($value, 'background');

        &:hover,
        &:focus,
        &.focus {
          color: map-get($value, 'background');
        }

        &.disabled,
        &:disabled {
          color: map-get($value, 'disabledBackground');
        }
      }
    }
  }
}

@each $size, $value in $button-sizes {
  .btn-#{$size} {
    font-size: map-get($value, 'fontSize');
    font-weight: map-get($value, 'fontWeight');
    height: map-get($value, 'height');
    line-height: map-get($value, 'lineHeight');
    min-width: map-get($value, 'minWidth');

    @at-root {
      a#{&} {
        text-decoration: none;
      }
    }
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-auto {
  display: block;
  min-width: auto;
  width: auto;
}

@each $size, $value in $grid-breakpoints {
  .btn-#{$size}-block {
    @include respond-above($size) {
      display: block;
      width: 100%;
    }
  }

  .btn-#{$size}-auto {
    @include respond-above($size) {
      display: inline-block;
      min-width: 100px;
      width: auto;
    }
  }
}

.range-option-button {
  border: solid 1px #f3f2f2;
  border-radius: 11px;
  cursor: pointer;
  padding: 3px 7px;

  &:hover,
  &.range-option-button--selected {
    background-color: #f1f4f6;
    border: solid 1px #f1f4f6;
    color: var(--black-900);
  }
}

.btn-none {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  display: flex;
  outline: 0;
  padding: 0;

  &:focus {
    outline: 0;
  }
}

.btn-back {
  border: 1px solid var(--gray-500);
  border-radius: 8px;
}

.btn-gray {
  background-color: var(--gray-100);
  border-radius: 8px;
  color: var(--black-900);
}

.filter-button {
  background: var(--gray-50);
  border-radius: 16px;
  padding: 7px 15px;

  &.filter-button--active {
    background: var(--primary-50);
    box-sizing: border-box;
  }

  &.filter-button--no-background {
    background: none;
  }
}

.btn-small {
  height: 25px;
}

.btn-transparent {
  background: transparent;
  border-color: transparent;
}

.btn-hero {
  padding-left: 65px !important;
  padding-right: 65px !important;

  @include respond-above(lg) {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
}
