.tag {
  border-radius: 9px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  padding: 0 10px;
  position: relative;
  text-align: center;
}

.tag-secondary {
  background-color: var(--secondary-100);
  color: var(--secondary-500);
}

.tag-error {
  background-color: rgba(208, 12, 12, .2);
  color: var(--error-500);
}

.tag-warning {
  background-color: rgba(255, 206, 110, .2);
  color: var(--warning-500);
}

.tag-warning-900 {
  background-color: #ffce6e;
  color: var(--white);
}

.tag-gray {
  background-color: rgba(178, 186, 194, .2);
  color: var(--gray-900);
}

.tag-primary {
  background-color: var(--primary-50);
  color: var(--primary-700);

  &--outline {
    background-color: transparent;
    border: 1px solid var(--primary-700);
    color: var(--primary-700);
  }
}

.tag-primary-solid {
  background-color: var(--primary-700);
  color: var(--white);
}

.tag-premium {
  background-color: rgba(32, 35, 38, .8);
  color: var(--white);
}

.tag-positive {
  background-color: rgba(38, 191, 64, .2);
  color: var(--positive-500);
}

.tag-gold {
  background-color: var(--gold);
  color: var(--white);
}

.tag-silver {
  background-color: var(--gray-900);
  color: var(--white);
}

.tag-bronze {
  background-color: var(--bronze);
  color: var(--white);
}

.tag-secondary-solid {
  background-color: var(--secondary-500);
  color: var(--white);
}

.tag-new {
  background-color: var(--secondary-500);
  border-radius: 4px;
  color: var(--white);
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  padding: 0 5px;
}

.tag-beta {
  background-color: var(--purple);
  border-radius: 4px;
  color: var(--white);
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  padding: 0 5px;
}

.tag-percents {
  min-width: 53px;
  text-align: center;
}

.tag-box {
  border-radius: 4px;
}

.tag-white {
  background-color: var(--white);
  color: var(--gray-900);
}

.tag-notice {
  background-color: #ffe8b9;
  color: #e68a06;
}
