// ========================================
// MEDIA QUERIES
// ========================================

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }
}

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper grid-breakpoints exist in the map.
  @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
    // Get the lower and upper grid-breakpoints.
    $lower-breakpoint: map-get($grid-breakpoints, $lower);
    $upper-breakpoint: map-get($grid-breakpoints, $upper);
    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    // Write the media query.
    @media (min-width: $lower) and (max-width: $upper) {
      @content;
    }
  }
}
