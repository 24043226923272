// sass-lint:disable no-important, no-ids
.input-group {
  border: solid 1px var(--gray-300);
  border-radius: 3px;
  display: flex;
  line-height: normal;
  padding: 15px 20px;
  transition: border-color .5s, box-shadow .5s;
  width: 100%;

  input {
    border: 0;
    flex-grow: 1;
  }

  .dropdown,
  button,
  input {
    height: 19px;
  }

  button {
    padding-left: 0;

    .arrow-down {
      margin-left: 10px;
    }
  }

  #dial-code {
    color: var(--black-900);
    margin-right: 5px !important;
    padding: 0 !important;
  }
}
