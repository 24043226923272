@import '../mixins/typography';

$ng-select-border: var(--gray-300);
$ng-select-border-active: var(--primary-700);
$ng-select-border-disabled: var(--gray-100);
$ng-select-border-error: var(--error-500);
$ng-select-border-radius: 10px;
$ng-select-bg: var(--white);
$ng-select-marked: var(--gray-100);
$ng-select-placeholder: var(--gray-900);
$ng-select-height: 50px;
$ng-select-value-padding-left: 20px;
$ng-select-value-font-size: 14px;

// sass-lint:disable no-important, nesting-depth, force-element-nesting
.ng-select {
  > .ng-select-container {
    transition: border-color .5s, box-shadow .5s;
  }

  &.short {
    .ng-dropdown-panel-items {
      max-height: 150px;
    }
  }

  &.small {
    &.ng-select-single {
      .ng-select-container {
        height: 32px;
        min-height: 32px;

        .ng-value-container {
          .ng-input {
            top: 4px;
          }
        }
      }
    }
  }

  &.clock {
    .ng-arrow-wrapper {
      margin-top: 0;

      .ng-arrow {
        border: 0;
        font-size: 20px;
        transform: rotate(0);

        &::before {
          @include icon-font();
          color: var(--primary-700);
          content: '\57';
        }
      }
    }
  }

  &.search-box {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-value {
    color: var(--black-900);
  }

  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg;
      border-color: $ng-select-border-active;
      box-shadow: 0 0 0 1px $ng-select-border-active;
      cursor: pointer;
      z-index: 1051 !important;

      // scss-lint:disable NestingDepth
      &:hover {
        box-shadow: 0 0 0 1px $ng-select-border-active;
      }
    }

    &.purple {
      > .ng-select-container {
        border-color: var(--purple);
        box-shadow: 0 0 0 1px var(--purple);
      }
    }

    &.ng-select-searchable {
      .ng-value,
      .ng-placeholder {
        visibility: hidden;
      }
    }

  }

  &.ng-select-disabled {
    > .ng-select-container {
      border-color: $ng-select-border-disabled;
      cursor: not-allowed;
    }

    input {
      color: rgba(55, 34, 86, .5);
      cursor: not-allowed !important;
    }

    .ng-value {
      color: var(--gray-300);
    }

    .ng-arrow-wrapper {
      cursor: not-allowed !important;

      .ng-arrow {
        border-color: var(--gray-300);
      }
    }
  }

  &.ng-invalid.ng-touched:not(.ng-select-opened) {

    > .ng-select-container {
      background-color: rgba(208, 12, 12, .1);
      border-color: $ng-select-border-error;
      box-shadow: 0 0 0 1px $ng-select-border-error;

      .ng-placeholder {
        color: transparent;
      }
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    align-items: center;
    background-color: $ng-select-bg;
    border: 1px solid $ng-select-border;
    border-radius: $ng-select-border-radius;
    min-height: $ng-select-height;

    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;

      .ng-placeholder {
        color: $ng-select-placeholder;
        padding-right: 5px;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;

      .ng-value-container {
        .ng-input {
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;
          top: 13px;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        border: 1px solid $ng-select-border;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-left: 7px;
        padding-top: 5px;

        .ng-value {
          border-radius: 2px;
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          margin-right: 5px;

          &.ng-value-disabled {

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;
        }

        .ng-placeholder {
          padding-bottom: 5px;
          padding-left: 3px;
          top: 5px;
        }
      }
    }
  }

  .ng-clear-wrapper {
    display: none;
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }

  .ng-arrow-wrapper {
    margin-top: -6px;
    padding-right: 20px;
    width: 35px;

    &:hover {
      .ng-arrow {
        border-top-color: var(--primary-700);
      }
    }

    .ng-arrow {
      border-color: var(--primary-700);
      border-style: solid;
      border-width: 0 1.5px 1.5px 0;
      padding: 4px;
      transform: rotate(45deg);
    }
  }

  &.purple {
    .ng-arrow-wrapper {

      &:hover {
        .ng-arrow {
          border-top-color: var(--purple);
        }
      }

      .ng-arrow {
        border-color: var(--purple);
      }
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-bg;
  border: 1px solid $ng-select-border;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  left: -1px !important;
  width: calc(100% + 2px) !important;

  &.ng-select-bottom {
    border-radius: 10px;
    border-top-color: $ng-select-border;
    top: 100%;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  &.ng-select-top {
    border-bottom-color: $ng-select-border;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    bottom: 100%;
    margin-bottom: -2px;
    padding-bottom: 2px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      @include typography-extra-bold();
      color: rgba(0, 0, 0, .54);
      cursor: pointer;
      padding: 8px 10px;
      user-select: none;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }
    }

    .ng-option {
      background-color: $ng-select-bg;
      border-bottom: 1px solid var(--gray-300);
      color: var(--black-900);
      padding: 15px 20px;

      &:last-of-type {
        border-bottom: 0;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: var(--black-900);
      }

      &.ng-option-disabled {
        color: var(--gray-300);
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        @include typography-regular();
        font-size: 80%;
        padding-right: 5px;
      }
    }
  }
}
