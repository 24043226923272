// ========================================
// TYPOGRAPHY
// ========================================

@mixin typography-regular() {
  font-weight: 400;
}

@mixin typography-extra-bold() {
  font-weight: 800;
}
