@import '../variables/variables';
@import '../mixins/typography';

.action-notice {
  @include typography-regular();
  background-color: var(--primary-50);
  border-radius: 8px;
  color: var(--primary-700);
  font-size: 12px;
  padding: 20px 20px 20px 50px;
  position: relative;

  * { // sass-lint:disable-line no-universal-selectors
    display: inline-block;
  }

  &::before {
    color: var(--primary-700);
    content: '\68';
    font-family: 'iconomi-icons';
    font-size: 20px;
    left: 20px;
    line-height: 20px;
    position: absolute;
    top: 20px;
  }

  &.action-notice--bulb {
    &::before {
      content: '\2a';
      font-size: 16px;
    }
  }

  &.action-notice--no-icon {
    padding: 20px;

    &.action-notice--small {
      padding: 10px;
    }

    &::before {
      content: '';
    }
  }

  &.action-notice--small {
    padding: 10px 10px 10px 40px;

    &::before {
      left: 10px;
      top: 10px;
    }
  }

  &.action-notice--warning {
    &::before {
      color: var(--warning-500);
    }
  }

  &.action-notice--warning-bg {
    background-color: var(--warning-100);
  }
}
