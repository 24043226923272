@mixin card() {
  background-color: var(--white);
  padding: 20px 15px;
  width: 100%;

  &.card-always-border {
    border-radius: 10px;
  }

  &.primary {
    background-color: #005ad7;
  }

  &.black {
    background-color: #000000;
  }

  &.gray {
    background-color: var(--gray-100);
  }

  @include respond-above(sm) {
    border-radius: 10px;
    box-shadow: 0 3px 50px 0 rgba(150, 160, 169, .07);
    padding: 30px;
    transition: box-shadow .3s ease-in-out;

    &.card--hover {
      &:hover {
        box-shadow: 0 3px 50px 0 rgba(150, 160, 169, .3);
        transition: box-shadow .2s ease-in-out;
      }
    }
  }
}

.card {
  @include card();

  &.card--no-background {
    background: transparent;
  }

  &.card--small {
    @include respond-above(md) {
      width: 470px;
    }
  }

  &.loading {
    pointer-events: none;
    position: relative;

    * { // sass-lint:disable-line no-universal-selectors
      filter: blur(5px);
    }

    &::after {
      background-image: url('/assets/images/loader.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100px;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  &.accordion {
    border: 1px solid var(--gray-300);
    box-shadow: none;
    padding: 20px;

    &.selected {
      border: 1px solid var(--primary-700);
    }
  }

  &.disabled {
    opacity: .3;
  }
}

.card-top {
  left: -15px;
  padding: 20px 15px 0;
  position: relative;
  top: -20px;
  width: calc(100% + 2 * 15px);

  @include respond-above(sm) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    left: -30px;
    padding: 30px 30px 0;

    top: -30px;
    width: calc(100% + 2 * 30px);
  }
}

.radio-button-card {
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 20px;

  &.active {
    border: 1px solid var(--primary-700);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;
  }
}

.card-md {
  @include respond-above(md) {
    @include card();
  }
}

.card--gray {
  background-color: var(--gray-50);
  border-radius: 10px;
  padding: 20px;
}

.card--border-only {
  border: 1px solid var(--gray-100);
  border-radius: 10px;
  padding: 20px;
}

.card--on-white {
  box-shadow: 0 4px 14px rgba(0, 0, 0, .07);
}

.card-corporate {
  background-color: var(--primary-700-business);
}
