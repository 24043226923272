%badge {
  border-radius: 23px;
  padding: 14px;
  text-align: center;
}

%badge-xs {
  border-radius: 10px;
  font-size: 12px;
  line-height: 1.5;
  padding: 0 10px;
}

.badge-gray-100 {
  @extend %badge;
  background: var(--gray-100);
}

.badge-xs-gray {
  @extend %badge-xs;
  background: var(--gray-100);
  color: var(--gray-900);
}

.badge-xs-positive {
  @extend %badge-xs;
  background: var(--positive-100);
  color: var(--positive-500);
}

.badge-md-gray {
  background: var(--gray-100);
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  padding: 0 30px;
  text-align: center;
}


.badge-xs-warning {
  @extend %badge-xs;
  background-color: rgba(255, 206, 110, .2);
  color: var(--warning-500);
}
