// sass-lint:disable-block no-important
@mixin icon-font() {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'iconomi-icons' !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}
