@import '../variables/grid-breakpoints';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities, (
    'width',
    'max-width',
    'viewport-width',
    'min-viewport-width',
    'height',
    'max-height',
    'viewport-height',
    'min-viewport-height',
 )
);

@import 'bootstrap/scss/utilities/api';

// custom $grid-gutter-widths because bootstrap doesn't support this by default and we want to have different gutters
// on different screen sizes

.container {
  @each $breakpoint, $gutter in $grid-gutter-widths {
    @include respond-above($breakpoint) {
      @include make-container($gutter);
    }
  }

  @media screen and (min-width: 1920px) {
    &.logged-in {
      margin-left: calc($grid-gutter-width / 2);
    }
  }

  @each $size, $value in $grid-breakpoints {
    &.container-#{$size}-align-left {
      @include respond-above($size) {
        margin-left: calc($grid-gutter-width / 2);
      }
    }
  }

  &.lander {
    @include respond-above(xl) {
      max-width: 1400px;
    }
  }
}

.container-fluid {
  @each $breakpoint, $gutter in $grid-gutter-widths {
    @include respond-above($breakpoint) {
      @include make-container($gutter);
    }
  }
}

@each $size, $value in $grid-breakpoints {
  .container-fluid-#{$size} {
    @include respond-below($size) {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
    }
  }
}

.row {
  // sass-lint:disable no-universal-selectors
  > * {
    flex-shrink: unset;
  }

  @each $breakpoint, $gutter in $grid-gutter-widths {
    @include respond-above($breakpoint) {
      @include make-row($gutter);
    }
  }
}

// sass-lint:disable no-important
@each $size, $value in $grid-breakpoints {
  .w-#{$size}-auto {
    @include respond-above($size) {
      width: auto !important;
    }
  }

  .w-#{$size}-100 {
    @include respond-above($size) {
      width: 100% !important;
    }
  }

  .w-#{$size}-50 {
    @include respond-above($size) {
      width: 50% !important;
    }
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

.flex-auto {
  flex: 0 0 auto;
}
