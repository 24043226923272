@import '../variables/z-indexes';
@import '../mixins/typography';
@import '../mixins/media-queries';
@import '../variables/grid-breakpoints';

table {
  border-collapse: collapse;
  width: 100%;

  &.table--black {
    th {
      border-bottom: 2px solid var(--black-700);
    }

    td {
      border-bottom: 1px solid var(--black-700);
    }
  }
}

th {
  @include typography-regular();
  border-bottom: 2px solid var(--gray-100);
  color: var(--gray-900);
  font-size: 12px;
  padding: 0 10px 5px;


  @include respond-above(md) {
    padding: 0 10px 20px;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

td {
  @include typography-regular();
  border-bottom: 1px solid var(--gray-100);
  color: var(--black-900);
  font-size: 14px;
  padding: 20px 10px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

tr,
.d-table-row {
  &.cursor-pointer {
    // safari fix
    * { // sass-lint:disable-line no-universal-selectors
      cursor: pointer;
    }
  }
}

tfoot {
  td {
    border-top: 1px solid var(--gray-100);
  }
}
