@import '../variables/variables';
@import '../mixins/media-queries';
@import '../variables/grid-breakpoints';


@function str-split($string, $separator) {
  $split-arr: ();
  // first index of separator in string
  $index: str-index($string, $separator);
  // loop through string
  @while $index != null {
    // get the substring from the first character to the separator
    $item: str-slice($string, 1, $index - 1);
    // push item to array
    $split-arr: append($split-arr, $item);
    // remove item and separator from string
    $string: str-slice($string, $index + 1);
    // find new index of separator
    $index: str-index($string, $separator);
  }
  // add the remaining string to list (the last item)
  $split-arr: append($split-arr, $string);

  @return $split-arr;
}

@function svg-factory($viewbox, $path, $fill) {
  $split: str-split($viewbox, ' ');
  $width: nth($split, 3);
  $height: nth($split, 4);

  // opacity is 0.9999 otherwise it uses a hex equivelent
  // firefox requires fill rgb
  // sass-lint:disable quotes
  @return "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='" + $viewbox +
  "' width='" + $width + "' height='" + $height + "'%3E%3Cpath fill='" + $fill + "' d='" + $path + "'/%3E%3C/svg%3E";
  // sass-lint:enable quotes
}
@function svg-encode($svg) {
  @return 'data:image/svg+xml;charset=utf8,' + $svg;
}

// Position
.toast-center-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  right: 0;
  top: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  right: 0;
  top: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  left: 12px;
  top: 12px;
}

.toast-top-right {
  right: 12px;
  top: 12px;
}

.toast-bottom-right {
  bottom: 12px;
  right: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  // Toast
  pointer-events: none;
  position: fixed;
  z-index: $z-index-toastr;

  .ngx-toastr {
    // sass-lint:disable max-line-length
    background-image: url(svg-encode(
      svg-factory(
        '0 0 16 16',
        'M8 .4a7.6 7.6 0 1 1 0 15.2A7.6 7.6 0 0 1 8 .4zm0 .8a6.8 6.8 0 1 0 0 13.6A6.8 6.8 0 0 0 8 1.2zm0 9.2A.8.8 0 1 1 8 12a.8.8 0 0 1 0-1.6zM8 4a.8.8 0 0 1 .8.8v4a.8.8 0 1 1-1.6 0v-4A.8.8 0 0 1 8 4z',
        'rgb(255,255,255)'
      )
    ));
    // sass-lint:enable max-line-length
    background-position: 15px 16px;
    background-repeat: no-repeat;
    background-size: 16px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .03);
    font-size: 12px;
    margin: 0 0 6px;
    overflow: hidden;
    padding: 15px 24px 15px 41px;
    pointer-events: auto;
    position: relative;
    width: 270px;

    &.toast-info {
      // sass-lint:disable max-line-length
      background-image: url(svg-encode(
        svg-factory(
          '0 0 16 16',
          'M8 .4a7.6 7.6 0 1 1 0 15.2A7.6 7.6 0 0 1 8 .4zm0 .8a6.8 6.8 0 1 0 0 13.6A6.8 6.8 0 0 0 8 1.2zm0 9.2A.8.8 0 1 1 8 12a.8.8 0 0 1 0-1.6zM8 4a.8.8 0 0 1 .8.8v4a.8.8 0 1 1-1.6 0v-4A.8.8 0 0 1 8 4z',
          'rgb(55, 34, 86)'
        )
      ));
      // sass-lint:enable max-line-length
    }

    &.vote-now-toastr {
      background: var(--primary-900);
      padding: 24px;
      width: 406px;

      @include respond-below(lg) {
        display: none;
      }
    }

    .toast-title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .toast-message {
      word-wrap: break-word;

      a:hover {
        text-decoration: underline;
      }
    }

    .toast-close-button {
      background: transparent;
      border: 0;
      color: inherit;
      float: right;
      font-size: 20px;
      font-weight: bold;
      opacity: .5;
      outline: none;
      padding: 0;
      position: relative;
      right: -10px; // sass-lint:disable-line property-units
      text-shadow: 0 1px 0 var(--white);
      top: -10px;  // sass-lint:disable-line property-units
    }

    .toast-progress {
      background-color: var(--black-900);
      bottom: 0;
      height: 4px;
      left: 0;
      opacity: .3;
      position: absolute;
    }

    .toast-close-button:hover,
    .toast-close-button:focus {
      color: var(--black-900);
      cursor: pointer;
      opacity: .75;
      text-decoration: none;
    }
  }

  .ngx-toastr:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    cursor: pointer;
    opacity: 1;
  }

  .toast-top-center,
  .toast-bottom-center {
    .ngx-toastr {
      margin-left: auto;
      margin-right: auto;
      width: 300px;
    }
  }

  .toast-top-full-width,
  .toast-bottom-full-width {
    .ngx-toastr {
      margin-left: auto;
      margin-right: auto;
      width: 96%;
    }
  }
}

.toast-success {
  background-color: var(--positive-500);
  color: var(--white);
}

.toast-error {
  background-color: var(--negative-500);
  color: var(--white);
}

.toast-info {
  background-color: var(--white);
  color: var(--black-900);
}

.toast-warning {
  background-color: var(--warning-500);
  color: var(--white);
}
