@import 'bootstrap/scss/carousel';

.h-100 {
  .carousel-inner {
    height: 100%;

    .carousel-item {
      height: 100%;
    }
  }
}
