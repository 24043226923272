.password-validator {
  padding-left: 30px;
}

.password-validator--touched {
  background: url('/assets/images/icons/assets/cancel.svg') no-repeat center left;
}

.password-validator--fulfilled {
  background: url('/assets/images/icons/assets/arrow-right.svg') no-repeat center left;
}
