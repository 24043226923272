@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import '@typeform/embed/build/css/popup.css';
:root {
  --bg-content: #eaeff4;
  --bg-menu-left: #181f47;
  --bg-menu-left-selected: #0e132b;
  --bg-menu-top: #ffffff;
  --bg-popup: #1a2238;
  --black-900: #202326;
  --black-700: #2f3337;
  --black-500: #404448;
  --black-300: #4a4e52;
  --error-100: #f1b6b6;
  --error-300: #f4abab;
  --error-500: #d00c0c;
  --error-900: #b30303;
  --gray-50: #f6f8fa;
  --gray-100: #eef2f5;
  --gray-300: #cdd3d9;
  --gray-500: #b2bac2;
  --gray-900: #96a0a9;
  --gray-900: #78828a;
  --info-100: #c2c2db;
  --info-500: #343287;
  --info-900: #151357;
  --negative-100: #f9cece;
  --negative-500: #eb5c5c;
  --negative-900: #dd3030;
  --positive-100: #beecc6;
  --positive-500: #26bf40;
  --positive-900: #177326;
  --primary-50: #ebf2fe;
  --primary-100: #d8e4fc;
  --primary-500: #005ad7;
  --primary-500: #3a79f2;
  --primary-700: #005ad7;
  --primary-900: #2955a9;
  --secondary-50: #eafff5;
  --secondary-100: #d0f5e3;
  --secondary-500: #16cd72;
  --secondary-900: #12a45b;
  --tertiary-100: #bac1ce;
  --tertiary-500: #31476d;
  --tertiary-900: #1a2239;
  --warning-100: #fff0d4;
  --warning-300: #ffce6e;
  --warning-500: #ffce6e;
  --warning-900: #ffb03f;
  --white: #ffffff;
  --gold: #bdb37d;
  --gold-500: #f6ce6f;
  --bronze: #c58c6f;
  --orange: #ff5d00;
  --purple: #7a60be;
  --orange-300: #f7931a;
  --primary-50-business: #d4d8ec;
  --primary-100-business: #afb6dc;
  --primary-300-business: #7783bd;
  --primary-500-business: #6d7fd4;
  --primary-700-business: #031137;
  --primary-900-business: #0f142c;
  --secondary-50-business: #0c0c0d;
}

/*!
 * Bootstrap Grid v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 2px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 2px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 3px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 3px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 5px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 5px;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 6px;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 6px;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 10px;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 10px;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 15px;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 15px;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 20px;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 20px;
}

.g-25,
.gx-25 {
  --bs-gutter-x: 25px;
}

.g-25,
.gy-25 {
  --bs-gutter-y: 25px;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 30px;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 30px;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 40px;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 40px;
}

.g-45,
.gx-45 {
  --bs-gutter-x: 45px;
}

.g-45,
.gy-45 {
  --bs-gutter-y: 45px;
}

.g-50,
.gx-50 {
  --bs-gutter-x: 50px;
}

.g-50,
.gy-50 {
  --bs-gutter-y: 50px;
}

.g-60,
.gx-60 {
  --bs-gutter-x: 60px;
}

.g-60,
.gy-60 {
  --bs-gutter-y: 60px;
}

.g-70,
.gx-70 {
  --bs-gutter-x: 70px;
}

.g-70,
.gy-70 {
  --bs-gutter-y: 70px;
}

.g-80,
.gx-80 {
  --bs-gutter-x: 80px;
}

.g-80,
.gy-80 {
  --bs-gutter-y: 80px;
}

.g-90,
.gx-90 {
  --bs-gutter-x: 90px;
}

.g-90,
.gy-90 {
  --bs-gutter-y: 90px;
}

.g-100,
.gx-100 {
  --bs-gutter-x: 100px;
}

.g-100,
.gy-100 {
  --bs-gutter-y: 100px;
}

.g-180,
.gx-180 {
  --bs-gutter-x: 180px;
}

.g-180,
.gy-180 {
  --bs-gutter-y: 180px;
}

.g-300,
.gx-300 {
  --bs-gutter-x: 300px;
}

.g-300,
.gy-300 {
  --bs-gutter-y: 300px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 2px;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 2px;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 3px;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 3px;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 5px;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 5px;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 6px;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 6px;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 10px;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 10px;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 15px;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 15px;
  }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 20px;
  }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 20px;
  }
  .g-sm-25,
  .gx-sm-25 {
    --bs-gutter-x: 25px;
  }
  .g-sm-25,
  .gy-sm-25 {
    --bs-gutter-y: 25px;
  }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 30px;
  }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 30px;
  }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 40px;
  }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 40px;
  }
  .g-sm-45,
  .gx-sm-45 {
    --bs-gutter-x: 45px;
  }
  .g-sm-45,
  .gy-sm-45 {
    --bs-gutter-y: 45px;
  }
  .g-sm-50,
  .gx-sm-50 {
    --bs-gutter-x: 50px;
  }
  .g-sm-50,
  .gy-sm-50 {
    --bs-gutter-y: 50px;
  }
  .g-sm-60,
  .gx-sm-60 {
    --bs-gutter-x: 60px;
  }
  .g-sm-60,
  .gy-sm-60 {
    --bs-gutter-y: 60px;
  }
  .g-sm-70,
  .gx-sm-70 {
    --bs-gutter-x: 70px;
  }
  .g-sm-70,
  .gy-sm-70 {
    --bs-gutter-y: 70px;
  }
  .g-sm-80,
  .gx-sm-80 {
    --bs-gutter-x: 80px;
  }
  .g-sm-80,
  .gy-sm-80 {
    --bs-gutter-y: 80px;
  }
  .g-sm-90,
  .gx-sm-90 {
    --bs-gutter-x: 90px;
  }
  .g-sm-90,
  .gy-sm-90 {
    --bs-gutter-y: 90px;
  }
  .g-sm-100,
  .gx-sm-100 {
    --bs-gutter-x: 100px;
  }
  .g-sm-100,
  .gy-sm-100 {
    --bs-gutter-y: 100px;
  }
  .g-sm-180,
  .gx-sm-180 {
    --bs-gutter-x: 180px;
  }
  .g-sm-180,
  .gy-sm-180 {
    --bs-gutter-y: 180px;
  }
  .g-sm-300,
  .gx-sm-300 {
    --bs-gutter-x: 300px;
  }
  .g-sm-300,
  .gy-sm-300 {
    --bs-gutter-y: 300px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 2px;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 2px;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 3px;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 3px;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 5px;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 5px;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 6px;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 6px;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 10px;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 10px;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 15px;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 15px;
  }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 20px;
  }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 20px;
  }
  .g-md-25,
  .gx-md-25 {
    --bs-gutter-x: 25px;
  }
  .g-md-25,
  .gy-md-25 {
    --bs-gutter-y: 25px;
  }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 30px;
  }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 30px;
  }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 40px;
  }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 40px;
  }
  .g-md-45,
  .gx-md-45 {
    --bs-gutter-x: 45px;
  }
  .g-md-45,
  .gy-md-45 {
    --bs-gutter-y: 45px;
  }
  .g-md-50,
  .gx-md-50 {
    --bs-gutter-x: 50px;
  }
  .g-md-50,
  .gy-md-50 {
    --bs-gutter-y: 50px;
  }
  .g-md-60,
  .gx-md-60 {
    --bs-gutter-x: 60px;
  }
  .g-md-60,
  .gy-md-60 {
    --bs-gutter-y: 60px;
  }
  .g-md-70,
  .gx-md-70 {
    --bs-gutter-x: 70px;
  }
  .g-md-70,
  .gy-md-70 {
    --bs-gutter-y: 70px;
  }
  .g-md-80,
  .gx-md-80 {
    --bs-gutter-x: 80px;
  }
  .g-md-80,
  .gy-md-80 {
    --bs-gutter-y: 80px;
  }
  .g-md-90,
  .gx-md-90 {
    --bs-gutter-x: 90px;
  }
  .g-md-90,
  .gy-md-90 {
    --bs-gutter-y: 90px;
  }
  .g-md-100,
  .gx-md-100 {
    --bs-gutter-x: 100px;
  }
  .g-md-100,
  .gy-md-100 {
    --bs-gutter-y: 100px;
  }
  .g-md-180,
  .gx-md-180 {
    --bs-gutter-x: 180px;
  }
  .g-md-180,
  .gy-md-180 {
    --bs-gutter-y: 180px;
  }
  .g-md-300,
  .gx-md-300 {
    --bs-gutter-x: 300px;
  }
  .g-md-300,
  .gy-md-300 {
    --bs-gutter-y: 300px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 2px;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 2px;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 3px;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 3px;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 5px;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 5px;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 6px;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 6px;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 10px;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 10px;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 15px;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 15px;
  }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 20px;
  }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 20px;
  }
  .g-lg-25,
  .gx-lg-25 {
    --bs-gutter-x: 25px;
  }
  .g-lg-25,
  .gy-lg-25 {
    --bs-gutter-y: 25px;
  }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 30px;
  }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 30px;
  }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 40px;
  }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 40px;
  }
  .g-lg-45,
  .gx-lg-45 {
    --bs-gutter-x: 45px;
  }
  .g-lg-45,
  .gy-lg-45 {
    --bs-gutter-y: 45px;
  }
  .g-lg-50,
  .gx-lg-50 {
    --bs-gutter-x: 50px;
  }
  .g-lg-50,
  .gy-lg-50 {
    --bs-gutter-y: 50px;
  }
  .g-lg-60,
  .gx-lg-60 {
    --bs-gutter-x: 60px;
  }
  .g-lg-60,
  .gy-lg-60 {
    --bs-gutter-y: 60px;
  }
  .g-lg-70,
  .gx-lg-70 {
    --bs-gutter-x: 70px;
  }
  .g-lg-70,
  .gy-lg-70 {
    --bs-gutter-y: 70px;
  }
  .g-lg-80,
  .gx-lg-80 {
    --bs-gutter-x: 80px;
  }
  .g-lg-80,
  .gy-lg-80 {
    --bs-gutter-y: 80px;
  }
  .g-lg-90,
  .gx-lg-90 {
    --bs-gutter-x: 90px;
  }
  .g-lg-90,
  .gy-lg-90 {
    --bs-gutter-y: 90px;
  }
  .g-lg-100,
  .gx-lg-100 {
    --bs-gutter-x: 100px;
  }
  .g-lg-100,
  .gy-lg-100 {
    --bs-gutter-y: 100px;
  }
  .g-lg-180,
  .gx-lg-180 {
    --bs-gutter-x: 180px;
  }
  .g-lg-180,
  .gy-lg-180 {
    --bs-gutter-y: 180px;
  }
  .g-lg-300,
  .gx-lg-300 {
    --bs-gutter-x: 300px;
  }
  .g-lg-300,
  .gy-lg-300 {
    --bs-gutter-y: 300px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 2px;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 2px;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 3px;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 3px;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 5px;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 5px;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 6px;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 6px;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 10px;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 10px;
  }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 15px;
  }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 15px;
  }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 20px;
  }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 20px;
  }
  .g-xl-25,
  .gx-xl-25 {
    --bs-gutter-x: 25px;
  }
  .g-xl-25,
  .gy-xl-25 {
    --bs-gutter-y: 25px;
  }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 30px;
  }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 30px;
  }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 40px;
  }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 40px;
  }
  .g-xl-45,
  .gx-xl-45 {
    --bs-gutter-x: 45px;
  }
  .g-xl-45,
  .gy-xl-45 {
    --bs-gutter-y: 45px;
  }
  .g-xl-50,
  .gx-xl-50 {
    --bs-gutter-x: 50px;
  }
  .g-xl-50,
  .gy-xl-50 {
    --bs-gutter-y: 50px;
  }
  .g-xl-60,
  .gx-xl-60 {
    --bs-gutter-x: 60px;
  }
  .g-xl-60,
  .gy-xl-60 {
    --bs-gutter-y: 60px;
  }
  .g-xl-70,
  .gx-xl-70 {
    --bs-gutter-x: 70px;
  }
  .g-xl-70,
  .gy-xl-70 {
    --bs-gutter-y: 70px;
  }
  .g-xl-80,
  .gx-xl-80 {
    --bs-gutter-x: 80px;
  }
  .g-xl-80,
  .gy-xl-80 {
    --bs-gutter-y: 80px;
  }
  .g-xl-90,
  .gx-xl-90 {
    --bs-gutter-x: 90px;
  }
  .g-xl-90,
  .gy-xl-90 {
    --bs-gutter-y: 90px;
  }
  .g-xl-100,
  .gx-xl-100 {
    --bs-gutter-x: 100px;
  }
  .g-xl-100,
  .gy-xl-100 {
    --bs-gutter-y: 100px;
  }
  .g-xl-180,
  .gx-xl-180 {
    --bs-gutter-x: 180px;
  }
  .g-xl-180,
  .gy-xl-180 {
    --bs-gutter-y: 180px;
  }
  .g-xl-300,
  .gx-xl-300 {
    --bs-gutter-x: 300px;
  }
  .g-xl-300,
  .gy-xl-300 {
    --bs-gutter-y: 300px;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 2px;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 2px;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 3px;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 3px;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 5px;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 5px;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 6px;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 6px;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 10px;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 10px;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 15px;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 15px;
  }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 20px;
  }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 20px;
  }
  .g-xxl-25,
  .gx-xxl-25 {
    --bs-gutter-x: 25px;
  }
  .g-xxl-25,
  .gy-xxl-25 {
    --bs-gutter-y: 25px;
  }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 30px;
  }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 30px;
  }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 40px;
  }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 40px;
  }
  .g-xxl-45,
  .gx-xxl-45 {
    --bs-gutter-x: 45px;
  }
  .g-xxl-45,
  .gy-xxl-45 {
    --bs-gutter-y: 45px;
  }
  .g-xxl-50,
  .gx-xxl-50 {
    --bs-gutter-x: 50px;
  }
  .g-xxl-50,
  .gy-xxl-50 {
    --bs-gutter-y: 50px;
  }
  .g-xxl-60,
  .gx-xxl-60 {
    --bs-gutter-x: 60px;
  }
  .g-xxl-60,
  .gy-xxl-60 {
    --bs-gutter-y: 60px;
  }
  .g-xxl-70,
  .gx-xxl-70 {
    --bs-gutter-x: 70px;
  }
  .g-xxl-70,
  .gy-xxl-70 {
    --bs-gutter-y: 70px;
  }
  .g-xxl-80,
  .gx-xxl-80 {
    --bs-gutter-x: 80px;
  }
  .g-xxl-80,
  .gy-xxl-80 {
    --bs-gutter-y: 80px;
  }
  .g-xxl-90,
  .gx-xxl-90 {
    --bs-gutter-x: 90px;
  }
  .g-xxl-90,
  .gy-xxl-90 {
    --bs-gutter-y: 90px;
  }
  .g-xxl-100,
  .gx-xxl-100 {
    --bs-gutter-x: 100px;
  }
  .g-xxl-100,
  .gy-xxl-100 {
    --bs-gutter-y: 100px;
  }
  .g-xxl-180,
  .gx-xxl-180 {
    --bs-gutter-x: 180px;
  }
  .g-xxl-180,
  .gy-xxl-180 {
    --bs-gutter-y: 180px;
  }
  .g-xxl-300,
  .gx-xxl-300 {
    --bs-gutter-x: 300px;
  }
  .g-xxl-300,
  .gy-xxl-300 {
    --bs-gutter-y: 300px;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-180 {
  margin: 180px !important;
}

.m-300 {
  margin: 300px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-2 {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.mx-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mx-6 {
  margin-right: 6px !important;
  margin-left: 6px !important;
}

.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.mx-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.mx-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.mx-90 {
  margin-right: 90px !important;
  margin-left: 90px !important;
}

.mx-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.mx-180 {
  margin-right: 180px !important;
  margin-left: 180px !important;
}

.mx-300 {
  margin-right: 300px !important;
  margin-left: 300px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.my-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.my-180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important;
}

.my-300 {
  margin-top: 300px !important;
  margin-bottom: 300px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-300 {
  margin-top: 300px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-2 {
  margin-right: 2px !important;
}

.me-3 {
  margin-right: 3px !important;
}

.me-5 {
  margin-right: 5px !important;
}

.me-6 {
  margin-right: 6px !important;
}

.me-10 {
  margin-right: 10px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.me-20 {
  margin-right: 20px !important;
}

.me-25 {
  margin-right: 25px !important;
}

.me-30 {
  margin-right: 30px !important;
}

.me-40 {
  margin-right: 40px !important;
}

.me-45 {
  margin-right: 45px !important;
}

.me-50 {
  margin-right: 50px !important;
}

.me-60 {
  margin-right: 60px !important;
}

.me-70 {
  margin-right: 70px !important;
}

.me-80 {
  margin-right: 80px !important;
}

.me-90 {
  margin-right: 90px !important;
}

.me-100 {
  margin-right: 100px !important;
}

.me-180 {
  margin-right: 180px !important;
}

.me-300 {
  margin-right: 300px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-300 {
  margin-bottom: 300px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-2 {
  margin-left: 2px !important;
}

.ms-3 {
  margin-left: 3px !important;
}

.ms-5 {
  margin-left: 5px !important;
}

.ms-6 {
  margin-left: 6px !important;
}

.ms-10 {
  margin-left: 10px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.ms-20 {
  margin-left: 20px !important;
}

.ms-25 {
  margin-left: 25px !important;
}

.ms-30 {
  margin-left: 30px !important;
}

.ms-40 {
  margin-left: 40px !important;
}

.ms-45 {
  margin-left: 45px !important;
}

.ms-50 {
  margin-left: 50px !important;
}

.ms-60 {
  margin-left: 60px !important;
}

.ms-70 {
  margin-left: 70px !important;
}

.ms-80 {
  margin-left: 80px !important;
}

.ms-90 {
  margin-left: 90px !important;
}

.ms-100 {
  margin-left: 100px !important;
}

.ms-180 {
  margin-left: 180px !important;
}

.ms-300 {
  margin-left: 300px !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n2 {
  margin: -2px !important;
}

.m-n3 {
  margin: -3px !important;
}

.m-n5 {
  margin: -5px !important;
}

.m-n6 {
  margin: -6px !important;
}

.m-n10 {
  margin: -10px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n20 {
  margin: -20px !important;
}

.m-n25 {
  margin: -25px !important;
}

.m-n30 {
  margin: -30px !important;
}

.m-n40 {
  margin: -40px !important;
}

.m-n45 {
  margin: -45px !important;
}

.m-n50 {
  margin: -50px !important;
}

.m-n60 {
  margin: -60px !important;
}

.m-n70 {
  margin: -70px !important;
}

.m-n80 {
  margin: -80px !important;
}

.m-n90 {
  margin: -90px !important;
}

.m-n100 {
  margin: -100px !important;
}

.m-n180 {
  margin: -180px !important;
}

.m-n300 {
  margin: -300px !important;
}

.mx-n2 {
  margin-right: -2px !important;
  margin-left: -2px !important;
}

.mx-n3 {
  margin-right: -3px !important;
  margin-left: -3px !important;
}

.mx-n5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.mx-n6 {
  margin-right: -6px !important;
  margin-left: -6px !important;
}

.mx-n10 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n20 {
  margin-right: -20px !important;
  margin-left: -20px !important;
}

.mx-n25 {
  margin-right: -25px !important;
  margin-left: -25px !important;
}

.mx-n30 {
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.mx-n40 {
  margin-right: -40px !important;
  margin-left: -40px !important;
}

.mx-n45 {
  margin-right: -45px !important;
  margin-left: -45px !important;
}

.mx-n50 {
  margin-right: -50px !important;
  margin-left: -50px !important;
}

.mx-n60 {
  margin-right: -60px !important;
  margin-left: -60px !important;
}

.mx-n70 {
  margin-right: -70px !important;
  margin-left: -70px !important;
}

.mx-n80 {
  margin-right: -80px !important;
  margin-left: -80px !important;
}

.mx-n90 {
  margin-right: -90px !important;
  margin-left: -90px !important;
}

.mx-n100 {
  margin-right: -100px !important;
  margin-left: -100px !important;
}

.mx-n180 {
  margin-right: -180px !important;
  margin-left: -180px !important;
}

.mx-n300 {
  margin-right: -300px !important;
  margin-left: -300px !important;
}

.my-n2 {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.my-n3 {
  margin-top: -3px !important;
  margin-bottom: -3px !important;
}

.my-n5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.my-n6 {
  margin-top: -6px !important;
  margin-bottom: -6px !important;
}

.my-n10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.my-n25 {
  margin-top: -25px !important;
  margin-bottom: -25px !important;
}

.my-n30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.my-n40 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.my-n45 {
  margin-top: -45px !important;
  margin-bottom: -45px !important;
}

.my-n50 {
  margin-top: -50px !important;
  margin-bottom: -50px !important;
}

.my-n60 {
  margin-top: -60px !important;
  margin-bottom: -60px !important;
}

.my-n70 {
  margin-top: -70px !important;
  margin-bottom: -70px !important;
}

.my-n80 {
  margin-top: -80px !important;
  margin-bottom: -80px !important;
}

.my-n90 {
  margin-top: -90px !important;
  margin-bottom: -90px !important;
}

.my-n100 {
  margin-top: -100px !important;
  margin-bottom: -100px !important;
}

.my-n180 {
  margin-top: -180px !important;
  margin-bottom: -180px !important;
}

.my-n300 {
  margin-top: -300px !important;
  margin-bottom: -300px !important;
}

.mt-n2 {
  margin-top: -2px !important;
}

.mt-n3 {
  margin-top: -3px !important;
}

.mt-n5 {
  margin-top: -5px !important;
}

.mt-n6 {
  margin-top: -6px !important;
}

.mt-n10 {
  margin-top: -10px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n20 {
  margin-top: -20px !important;
}

.mt-n25 {
  margin-top: -25px !important;
}

.mt-n30 {
  margin-top: -30px !important;
}

.mt-n40 {
  margin-top: -40px !important;
}

.mt-n45 {
  margin-top: -45px !important;
}

.mt-n50 {
  margin-top: -50px !important;
}

.mt-n60 {
  margin-top: -60px !important;
}

.mt-n70 {
  margin-top: -70px !important;
}

.mt-n80 {
  margin-top: -80px !important;
}

.mt-n90 {
  margin-top: -90px !important;
}

.mt-n100 {
  margin-top: -100px !important;
}

.mt-n180 {
  margin-top: -180px !important;
}

.mt-n300 {
  margin-top: -300px !important;
}

.me-n2 {
  margin-right: -2px !important;
}

.me-n3 {
  margin-right: -3px !important;
}

.me-n5 {
  margin-right: -5px !important;
}

.me-n6 {
  margin-right: -6px !important;
}

.me-n10 {
  margin-right: -10px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n20 {
  margin-right: -20px !important;
}

.me-n25 {
  margin-right: -25px !important;
}

.me-n30 {
  margin-right: -30px !important;
}

.me-n40 {
  margin-right: -40px !important;
}

.me-n45 {
  margin-right: -45px !important;
}

.me-n50 {
  margin-right: -50px !important;
}

.me-n60 {
  margin-right: -60px !important;
}

.me-n70 {
  margin-right: -70px !important;
}

.me-n80 {
  margin-right: -80px !important;
}

.me-n90 {
  margin-right: -90px !important;
}

.me-n100 {
  margin-right: -100px !important;
}

.me-n180 {
  margin-right: -180px !important;
}

.me-n300 {
  margin-right: -300px !important;
}

.mb-n2 {
  margin-bottom: -2px !important;
}

.mb-n3 {
  margin-bottom: -3px !important;
}

.mb-n5 {
  margin-bottom: -5px !important;
}

.mb-n6 {
  margin-bottom: -6px !important;
}

.mb-n10 {
  margin-bottom: -10px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n20 {
  margin-bottom: -20px !important;
}

.mb-n25 {
  margin-bottom: -25px !important;
}

.mb-n30 {
  margin-bottom: -30px !important;
}

.mb-n40 {
  margin-bottom: -40px !important;
}

.mb-n45 {
  margin-bottom: -45px !important;
}

.mb-n50 {
  margin-bottom: -50px !important;
}

.mb-n60 {
  margin-bottom: -60px !important;
}

.mb-n70 {
  margin-bottom: -70px !important;
}

.mb-n80 {
  margin-bottom: -80px !important;
}

.mb-n90 {
  margin-bottom: -90px !important;
}

.mb-n100 {
  margin-bottom: -100px !important;
}

.mb-n180 {
  margin-bottom: -180px !important;
}

.mb-n300 {
  margin-bottom: -300px !important;
}

.ms-n2 {
  margin-left: -2px !important;
}

.ms-n3 {
  margin-left: -3px !important;
}

.ms-n5 {
  margin-left: -5px !important;
}

.ms-n6 {
  margin-left: -6px !important;
}

.ms-n10 {
  margin-left: -10px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n20 {
  margin-left: -20px !important;
}

.ms-n25 {
  margin-left: -25px !important;
}

.ms-n30 {
  margin-left: -30px !important;
}

.ms-n40 {
  margin-left: -40px !important;
}

.ms-n45 {
  margin-left: -45px !important;
}

.ms-n50 {
  margin-left: -50px !important;
}

.ms-n60 {
  margin-left: -60px !important;
}

.ms-n70 {
  margin-left: -70px !important;
}

.ms-n80 {
  margin-left: -80px !important;
}

.ms-n90 {
  margin-left: -90px !important;
}

.ms-n100 {
  margin-left: -100px !important;
}

.ms-n180 {
  margin-left: -180px !important;
}

.ms-n300 {
  margin-left: -300px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 2px !important;
}

.p-3 {
  padding: 3px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-6 {
  padding: 6px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-180 {
  padding: 180px !important;
}

.p-300 {
  padding: 300px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-2 {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.px-3 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.px-6 {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.px-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.px-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.px-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.px-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.px-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.px-90 {
  padding-right: 90px !important;
  padding-left: 90px !important;
}

.px-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.px-180 {
  padding-right: 180px !important;
  padding-left: 180px !important;
}

.px-300 {
  padding-right: 300px !important;
  padding-left: 300px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.py-180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.py-300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-300 {
  padding-top: 300px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-2 {
  padding-right: 2px !important;
}

.pe-3 {
  padding-right: 3px !important;
}

.pe-5 {
  padding-right: 5px !important;
}

.pe-6 {
  padding-right: 6px !important;
}

.pe-10 {
  padding-right: 10px !important;
}

.pe-15 {
  padding-right: 15px !important;
}

.pe-20 {
  padding-right: 20px !important;
}

.pe-25 {
  padding-right: 25px !important;
}

.pe-30 {
  padding-right: 30px !important;
}

.pe-40 {
  padding-right: 40px !important;
}

.pe-45 {
  padding-right: 45px !important;
}

.pe-50 {
  padding-right: 50px !important;
}

.pe-60 {
  padding-right: 60px !important;
}

.pe-70 {
  padding-right: 70px !important;
}

.pe-80 {
  padding-right: 80px !important;
}

.pe-90 {
  padding-right: 90px !important;
}

.pe-100 {
  padding-right: 100px !important;
}

.pe-180 {
  padding-right: 180px !important;
}

.pe-300 {
  padding-right: 300px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-300 {
  padding-bottom: 300px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-2 {
  padding-left: 2px !important;
}

.ps-3 {
  padding-left: 3px !important;
}

.ps-5 {
  padding-left: 5px !important;
}

.ps-6 {
  padding-left: 6px !important;
}

.ps-10 {
  padding-left: 10px !important;
}

.ps-15 {
  padding-left: 15px !important;
}

.ps-20 {
  padding-left: 20px !important;
}

.ps-25 {
  padding-left: 25px !important;
}

.ps-30 {
  padding-left: 30px !important;
}

.ps-40 {
  padding-left: 40px !important;
}

.ps-45 {
  padding-left: 45px !important;
}

.ps-50 {
  padding-left: 50px !important;
}

.ps-60 {
  padding-left: 60px !important;
}

.ps-70 {
  padding-left: 70px !important;
}

.ps-80 {
  padding-left: 80px !important;
}

.ps-90 {
  padding-left: 90px !important;
}

.ps-100 {
  padding-left: 100px !important;
}

.ps-180 {
  padding-left: 180px !important;
}

.ps-300 {
  padding-left: 300px !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-2 {
    margin: 2px !important;
  }
  .m-sm-3 {
    margin: 3px !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .m-sm-6 {
    margin: 6px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .m-sm-25 {
    margin: 25px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .m-sm-40 {
    margin: 40px !important;
  }
  .m-sm-45 {
    margin: 45px !important;
  }
  .m-sm-50 {
    margin: 50px !important;
  }
  .m-sm-60 {
    margin: 60px !important;
  }
  .m-sm-70 {
    margin: 70px !important;
  }
  .m-sm-80 {
    margin: 80px !important;
  }
  .m-sm-90 {
    margin: 90px !important;
  }
  .m-sm-100 {
    margin: 100px !important;
  }
  .m-sm-180 {
    margin: 180px !important;
  }
  .m-sm-300 {
    margin: 300px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .mx-sm-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-sm-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-sm-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .mx-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-sm-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-sm-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-sm-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-sm-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-sm-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-sm-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .mx-sm-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .mx-sm-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-sm-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .mx-sm-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-sm-90 {
    margin-right: 90px !important;
    margin-left: 90px !important;
  }
  .mx-sm-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .mx-sm-180 {
    margin-right: 180px !important;
    margin-left: 180px !important;
  }
  .mx-sm-300 {
    margin-right: 300px !important;
    margin-left: 300px !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .my-sm-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-sm-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .my-sm-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .my-sm-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-2 {
    margin-top: 2px !important;
  }
  .mt-sm-3 {
    margin-top: 3px !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .mt-sm-6 {
    margin-top: 6px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .mt-sm-15 {
    margin-top: 15px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .mt-sm-25 {
    margin-top: 25px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
  .mt-sm-45 {
    margin-top: 45px !important;
  }
  .mt-sm-50 {
    margin-top: 50px !important;
  }
  .mt-sm-60 {
    margin-top: 60px !important;
  }
  .mt-sm-70 {
    margin-top: 70px !important;
  }
  .mt-sm-80 {
    margin-top: 80px !important;
  }
  .mt-sm-90 {
    margin-top: 90px !important;
  }
  .mt-sm-100 {
    margin-top: 100px !important;
  }
  .mt-sm-180 {
    margin-top: 180px !important;
  }
  .mt-sm-300 {
    margin-top: 300px !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-2 {
    margin-right: 2px !important;
  }
  .me-sm-3 {
    margin-right: 3px !important;
  }
  .me-sm-5 {
    margin-right: 5px !important;
  }
  .me-sm-6 {
    margin-right: 6px !important;
  }
  .me-sm-10 {
    margin-right: 10px !important;
  }
  .me-sm-15 {
    margin-right: 15px !important;
  }
  .me-sm-20 {
    margin-right: 20px !important;
  }
  .me-sm-25 {
    margin-right: 25px !important;
  }
  .me-sm-30 {
    margin-right: 30px !important;
  }
  .me-sm-40 {
    margin-right: 40px !important;
  }
  .me-sm-45 {
    margin-right: 45px !important;
  }
  .me-sm-50 {
    margin-right: 50px !important;
  }
  .me-sm-60 {
    margin-right: 60px !important;
  }
  .me-sm-70 {
    margin-right: 70px !important;
  }
  .me-sm-80 {
    margin-right: 80px !important;
  }
  .me-sm-90 {
    margin-right: 90px !important;
  }
  .me-sm-100 {
    margin-right: 100px !important;
  }
  .me-sm-180 {
    margin-right: 180px !important;
  }
  .me-sm-300 {
    margin-right: 300px !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-2 {
    margin-bottom: 2px !important;
  }
  .mb-sm-3 {
    margin-bottom: 3px !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .mb-sm-6 {
    margin-bottom: 6px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .mb-sm-25 {
    margin-bottom: 25px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
  .mb-sm-45 {
    margin-bottom: 45px !important;
  }
  .mb-sm-50 {
    margin-bottom: 50px !important;
  }
  .mb-sm-60 {
    margin-bottom: 60px !important;
  }
  .mb-sm-70 {
    margin-bottom: 70px !important;
  }
  .mb-sm-80 {
    margin-bottom: 80px !important;
  }
  .mb-sm-90 {
    margin-bottom: 90px !important;
  }
  .mb-sm-100 {
    margin-bottom: 100px !important;
  }
  .mb-sm-180 {
    margin-bottom: 180px !important;
  }
  .mb-sm-300 {
    margin-bottom: 300px !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-2 {
    margin-left: 2px !important;
  }
  .ms-sm-3 {
    margin-left: 3px !important;
  }
  .ms-sm-5 {
    margin-left: 5px !important;
  }
  .ms-sm-6 {
    margin-left: 6px !important;
  }
  .ms-sm-10 {
    margin-left: 10px !important;
  }
  .ms-sm-15 {
    margin-left: 15px !important;
  }
  .ms-sm-20 {
    margin-left: 20px !important;
  }
  .ms-sm-25 {
    margin-left: 25px !important;
  }
  .ms-sm-30 {
    margin-left: 30px !important;
  }
  .ms-sm-40 {
    margin-left: 40px !important;
  }
  .ms-sm-45 {
    margin-left: 45px !important;
  }
  .ms-sm-50 {
    margin-left: 50px !important;
  }
  .ms-sm-60 {
    margin-left: 60px !important;
  }
  .ms-sm-70 {
    margin-left: 70px !important;
  }
  .ms-sm-80 {
    margin-left: 80px !important;
  }
  .ms-sm-90 {
    margin-left: 90px !important;
  }
  .ms-sm-100 {
    margin-left: 100px !important;
  }
  .ms-sm-180 {
    margin-left: 180px !important;
  }
  .ms-sm-300 {
    margin-left: 300px !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n2 {
    margin: -2px !important;
  }
  .m-sm-n3 {
    margin: -3px !important;
  }
  .m-sm-n5 {
    margin: -5px !important;
  }
  .m-sm-n6 {
    margin: -6px !important;
  }
  .m-sm-n10 {
    margin: -10px !important;
  }
  .m-sm-n15 {
    margin: -15px !important;
  }
  .m-sm-n20 {
    margin: -20px !important;
  }
  .m-sm-n25 {
    margin: -25px !important;
  }
  .m-sm-n30 {
    margin: -30px !important;
  }
  .m-sm-n40 {
    margin: -40px !important;
  }
  .m-sm-n45 {
    margin: -45px !important;
  }
  .m-sm-n50 {
    margin: -50px !important;
  }
  .m-sm-n60 {
    margin: -60px !important;
  }
  .m-sm-n70 {
    margin: -70px !important;
  }
  .m-sm-n80 {
    margin: -80px !important;
  }
  .m-sm-n90 {
    margin: -90px !important;
  }
  .m-sm-n100 {
    margin: -100px !important;
  }
  .m-sm-n180 {
    margin: -180px !important;
  }
  .m-sm-n300 {
    margin: -300px !important;
  }
  .mx-sm-n2 {
    margin-right: -2px !important;
    margin-left: -2px !important;
  }
  .mx-sm-n3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-sm-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-sm-n6 {
    margin-right: -6px !important;
    margin-left: -6px !important;
  }
  .mx-sm-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-sm-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-sm-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-sm-n25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-sm-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-sm-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-sm-n45 {
    margin-right: -45px !important;
    margin-left: -45px !important;
  }
  .mx-sm-n50 {
    margin-right: -50px !important;
    margin-left: -50px !important;
  }
  .mx-sm-n60 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-sm-n70 {
    margin-right: -70px !important;
    margin-left: -70px !important;
  }
  .mx-sm-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important;
  }
  .mx-sm-n90 {
    margin-right: -90px !important;
    margin-left: -90px !important;
  }
  .mx-sm-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important;
  }
  .mx-sm-n180 {
    margin-right: -180px !important;
    margin-left: -180px !important;
  }
  .mx-sm-n300 {
    margin-right: -300px !important;
    margin-left: -300px !important;
  }
  .my-sm-n2 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .my-sm-n3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-sm-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-sm-n6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .my-sm-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-sm-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-sm-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-sm-n25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-sm-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-sm-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-sm-n45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .my-sm-n50 {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
  .my-sm-n60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-sm-n70 {
    margin-top: -70px !important;
    margin-bottom: -70px !important;
  }
  .my-sm-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important;
  }
  .my-sm-n90 {
    margin-top: -90px !important;
    margin-bottom: -90px !important;
  }
  .my-sm-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important;
  }
  .my-sm-n180 {
    margin-top: -180px !important;
    margin-bottom: -180px !important;
  }
  .my-sm-n300 {
    margin-top: -300px !important;
    margin-bottom: -300px !important;
  }
  .mt-sm-n2 {
    margin-top: -2px !important;
  }
  .mt-sm-n3 {
    margin-top: -3px !important;
  }
  .mt-sm-n5 {
    margin-top: -5px !important;
  }
  .mt-sm-n6 {
    margin-top: -6px !important;
  }
  .mt-sm-n10 {
    margin-top: -10px !important;
  }
  .mt-sm-n15 {
    margin-top: -15px !important;
  }
  .mt-sm-n20 {
    margin-top: -20px !important;
  }
  .mt-sm-n25 {
    margin-top: -25px !important;
  }
  .mt-sm-n30 {
    margin-top: -30px !important;
  }
  .mt-sm-n40 {
    margin-top: -40px !important;
  }
  .mt-sm-n45 {
    margin-top: -45px !important;
  }
  .mt-sm-n50 {
    margin-top: -50px !important;
  }
  .mt-sm-n60 {
    margin-top: -60px !important;
  }
  .mt-sm-n70 {
    margin-top: -70px !important;
  }
  .mt-sm-n80 {
    margin-top: -80px !important;
  }
  .mt-sm-n90 {
    margin-top: -90px !important;
  }
  .mt-sm-n100 {
    margin-top: -100px !important;
  }
  .mt-sm-n180 {
    margin-top: -180px !important;
  }
  .mt-sm-n300 {
    margin-top: -300px !important;
  }
  .me-sm-n2 {
    margin-right: -2px !important;
  }
  .me-sm-n3 {
    margin-right: -3px !important;
  }
  .me-sm-n5 {
    margin-right: -5px !important;
  }
  .me-sm-n6 {
    margin-right: -6px !important;
  }
  .me-sm-n10 {
    margin-right: -10px !important;
  }
  .me-sm-n15 {
    margin-right: -15px !important;
  }
  .me-sm-n20 {
    margin-right: -20px !important;
  }
  .me-sm-n25 {
    margin-right: -25px !important;
  }
  .me-sm-n30 {
    margin-right: -30px !important;
  }
  .me-sm-n40 {
    margin-right: -40px !important;
  }
  .me-sm-n45 {
    margin-right: -45px !important;
  }
  .me-sm-n50 {
    margin-right: -50px !important;
  }
  .me-sm-n60 {
    margin-right: -60px !important;
  }
  .me-sm-n70 {
    margin-right: -70px !important;
  }
  .me-sm-n80 {
    margin-right: -80px !important;
  }
  .me-sm-n90 {
    margin-right: -90px !important;
  }
  .me-sm-n100 {
    margin-right: -100px !important;
  }
  .me-sm-n180 {
    margin-right: -180px !important;
  }
  .me-sm-n300 {
    margin-right: -300px !important;
  }
  .mb-sm-n2 {
    margin-bottom: -2px !important;
  }
  .mb-sm-n3 {
    margin-bottom: -3px !important;
  }
  .mb-sm-n5 {
    margin-bottom: -5px !important;
  }
  .mb-sm-n6 {
    margin-bottom: -6px !important;
  }
  .mb-sm-n10 {
    margin-bottom: -10px !important;
  }
  .mb-sm-n15 {
    margin-bottom: -15px !important;
  }
  .mb-sm-n20 {
    margin-bottom: -20px !important;
  }
  .mb-sm-n25 {
    margin-bottom: -25px !important;
  }
  .mb-sm-n30 {
    margin-bottom: -30px !important;
  }
  .mb-sm-n40 {
    margin-bottom: -40px !important;
  }
  .mb-sm-n45 {
    margin-bottom: -45px !important;
  }
  .mb-sm-n50 {
    margin-bottom: -50px !important;
  }
  .mb-sm-n60 {
    margin-bottom: -60px !important;
  }
  .mb-sm-n70 {
    margin-bottom: -70px !important;
  }
  .mb-sm-n80 {
    margin-bottom: -80px !important;
  }
  .mb-sm-n90 {
    margin-bottom: -90px !important;
  }
  .mb-sm-n100 {
    margin-bottom: -100px !important;
  }
  .mb-sm-n180 {
    margin-bottom: -180px !important;
  }
  .mb-sm-n300 {
    margin-bottom: -300px !important;
  }
  .ms-sm-n2 {
    margin-left: -2px !important;
  }
  .ms-sm-n3 {
    margin-left: -3px !important;
  }
  .ms-sm-n5 {
    margin-left: -5px !important;
  }
  .ms-sm-n6 {
    margin-left: -6px !important;
  }
  .ms-sm-n10 {
    margin-left: -10px !important;
  }
  .ms-sm-n15 {
    margin-left: -15px !important;
  }
  .ms-sm-n20 {
    margin-left: -20px !important;
  }
  .ms-sm-n25 {
    margin-left: -25px !important;
  }
  .ms-sm-n30 {
    margin-left: -30px !important;
  }
  .ms-sm-n40 {
    margin-left: -40px !important;
  }
  .ms-sm-n45 {
    margin-left: -45px !important;
  }
  .ms-sm-n50 {
    margin-left: -50px !important;
  }
  .ms-sm-n60 {
    margin-left: -60px !important;
  }
  .ms-sm-n70 {
    margin-left: -70px !important;
  }
  .ms-sm-n80 {
    margin-left: -80px !important;
  }
  .ms-sm-n90 {
    margin-left: -90px !important;
  }
  .ms-sm-n100 {
    margin-left: -100px !important;
  }
  .ms-sm-n180 {
    margin-left: -180px !important;
  }
  .ms-sm-n300 {
    margin-left: -300px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-2 {
    padding: 2px !important;
  }
  .p-sm-3 {
    padding: 3px !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .p-sm-6 {
    padding: 6px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .p-sm-25 {
    padding: 25px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .p-sm-45 {
    padding: 45px !important;
  }
  .p-sm-50 {
    padding: 50px !important;
  }
  .p-sm-60 {
    padding: 60px !important;
  }
  .p-sm-70 {
    padding: 70px !important;
  }
  .p-sm-80 {
    padding: 80px !important;
  }
  .p-sm-90 {
    padding: 90px !important;
  }
  .p-sm-100 {
    padding: 100px !important;
  }
  .p-sm-180 {
    padding: 180px !important;
  }
  .p-sm-300 {
    padding: 300px !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-2 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .px-sm-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-sm-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-sm-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .px-sm-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-sm-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-sm-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-sm-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-sm-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-sm-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-sm-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .px-sm-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .px-sm-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-sm-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .px-sm-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .px-sm-90 {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
  .px-sm-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .px-sm-180 {
    padding-right: 180px !important;
    padding-left: 180px !important;
  }
  .px-sm-300 {
    padding-right: 300px !important;
    padding-left: 300px !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .py-sm-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-sm-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .py-sm-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .py-sm-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-2 {
    padding-top: 2px !important;
  }
  .pt-sm-3 {
    padding-top: 3px !important;
  }
  .pt-sm-5 {
    padding-top: 5px !important;
  }
  .pt-sm-6 {
    padding-top: 6px !important;
  }
  .pt-sm-10 {
    padding-top: 10px !important;
  }
  .pt-sm-15 {
    padding-top: 15px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .pt-sm-25 {
    padding-top: 25px !important;
  }
  .pt-sm-30 {
    padding-top: 30px !important;
  }
  .pt-sm-40 {
    padding-top: 40px !important;
  }
  .pt-sm-45 {
    padding-top: 45px !important;
  }
  .pt-sm-50 {
    padding-top: 50px !important;
  }
  .pt-sm-60 {
    padding-top: 60px !important;
  }
  .pt-sm-70 {
    padding-top: 70px !important;
  }
  .pt-sm-80 {
    padding-top: 80px !important;
  }
  .pt-sm-90 {
    padding-top: 90px !important;
  }
  .pt-sm-100 {
    padding-top: 100px !important;
  }
  .pt-sm-180 {
    padding-top: 180px !important;
  }
  .pt-sm-300 {
    padding-top: 300px !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-2 {
    padding-right: 2px !important;
  }
  .pe-sm-3 {
    padding-right: 3px !important;
  }
  .pe-sm-5 {
    padding-right: 5px !important;
  }
  .pe-sm-6 {
    padding-right: 6px !important;
  }
  .pe-sm-10 {
    padding-right: 10px !important;
  }
  .pe-sm-15 {
    padding-right: 15px !important;
  }
  .pe-sm-20 {
    padding-right: 20px !important;
  }
  .pe-sm-25 {
    padding-right: 25px !important;
  }
  .pe-sm-30 {
    padding-right: 30px !important;
  }
  .pe-sm-40 {
    padding-right: 40px !important;
  }
  .pe-sm-45 {
    padding-right: 45px !important;
  }
  .pe-sm-50 {
    padding-right: 50px !important;
  }
  .pe-sm-60 {
    padding-right: 60px !important;
  }
  .pe-sm-70 {
    padding-right: 70px !important;
  }
  .pe-sm-80 {
    padding-right: 80px !important;
  }
  .pe-sm-90 {
    padding-right: 90px !important;
  }
  .pe-sm-100 {
    padding-right: 100px !important;
  }
  .pe-sm-180 {
    padding-right: 180px !important;
  }
  .pe-sm-300 {
    padding-right: 300px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-2 {
    padding-bottom: 2px !important;
  }
  .pb-sm-3 {
    padding-bottom: 3px !important;
  }
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
  .pb-sm-6 {
    padding-bottom: 6px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .pb-sm-25 {
    padding-bottom: 25px !important;
  }
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
  .pb-sm-45 {
    padding-bottom: 45px !important;
  }
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
  .pb-sm-70 {
    padding-bottom: 70px !important;
  }
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
  .pb-sm-90 {
    padding-bottom: 90px !important;
  }
  .pb-sm-100 {
    padding-bottom: 100px !important;
  }
  .pb-sm-180 {
    padding-bottom: 180px !important;
  }
  .pb-sm-300 {
    padding-bottom: 300px !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-2 {
    padding-left: 2px !important;
  }
  .ps-sm-3 {
    padding-left: 3px !important;
  }
  .ps-sm-5 {
    padding-left: 5px !important;
  }
  .ps-sm-6 {
    padding-left: 6px !important;
  }
  .ps-sm-10 {
    padding-left: 10px !important;
  }
  .ps-sm-15 {
    padding-left: 15px !important;
  }
  .ps-sm-20 {
    padding-left: 20px !important;
  }
  .ps-sm-25 {
    padding-left: 25px !important;
  }
  .ps-sm-30 {
    padding-left: 30px !important;
  }
  .ps-sm-40 {
    padding-left: 40px !important;
  }
  .ps-sm-45 {
    padding-left: 45px !important;
  }
  .ps-sm-50 {
    padding-left: 50px !important;
  }
  .ps-sm-60 {
    padding-left: 60px !important;
  }
  .ps-sm-70 {
    padding-left: 70px !important;
  }
  .ps-sm-80 {
    padding-left: 80px !important;
  }
  .ps-sm-90 {
    padding-left: 90px !important;
  }
  .ps-sm-100 {
    padding-left: 100px !important;
  }
  .ps-sm-180 {
    padding-left: 180px !important;
  }
  .ps-sm-300 {
    padding-left: 300px !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-2 {
    margin: 2px !important;
  }
  .m-md-3 {
    margin: 3px !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .m-md-6 {
    margin: 6px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .m-md-25 {
    margin: 25px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .m-md-40 {
    margin: 40px !important;
  }
  .m-md-45 {
    margin: 45px !important;
  }
  .m-md-50 {
    margin: 50px !important;
  }
  .m-md-60 {
    margin: 60px !important;
  }
  .m-md-70 {
    margin: 70px !important;
  }
  .m-md-80 {
    margin: 80px !important;
  }
  .m-md-90 {
    margin: 90px !important;
  }
  .m-md-100 {
    margin: 100px !important;
  }
  .m-md-180 {
    margin: 180px !important;
  }
  .m-md-300 {
    margin: 300px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .mx-md-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-md-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-md-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .mx-md-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-md-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-md-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-md-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-md-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-md-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-md-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .mx-md-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .mx-md-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-md-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .mx-md-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-md-90 {
    margin-right: 90px !important;
    margin-left: 90px !important;
  }
  .mx-md-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .mx-md-180 {
    margin-right: 180px !important;
    margin-left: 180px !important;
  }
  .mx-md-300 {
    margin-right: 300px !important;
    margin-left: 300px !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .my-md-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-md-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .my-md-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .my-md-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-2 {
    margin-top: 2px !important;
  }
  .mt-md-3 {
    margin-top: 3px !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .mt-md-6 {
    margin-top: 6px !important;
  }
  .mt-md-10 {
    margin-top: 10px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .mt-md-25 {
    margin-top: 25px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .mt-md-40 {
    margin-top: 40px !important;
  }
  .mt-md-45 {
    margin-top: 45px !important;
  }
  .mt-md-50 {
    margin-top: 50px !important;
  }
  .mt-md-60 {
    margin-top: 60px !important;
  }
  .mt-md-70 {
    margin-top: 70px !important;
  }
  .mt-md-80 {
    margin-top: 80px !important;
  }
  .mt-md-90 {
    margin-top: 90px !important;
  }
  .mt-md-100 {
    margin-top: 100px !important;
  }
  .mt-md-180 {
    margin-top: 180px !important;
  }
  .mt-md-300 {
    margin-top: 300px !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-2 {
    margin-right: 2px !important;
  }
  .me-md-3 {
    margin-right: 3px !important;
  }
  .me-md-5 {
    margin-right: 5px !important;
  }
  .me-md-6 {
    margin-right: 6px !important;
  }
  .me-md-10 {
    margin-right: 10px !important;
  }
  .me-md-15 {
    margin-right: 15px !important;
  }
  .me-md-20 {
    margin-right: 20px !important;
  }
  .me-md-25 {
    margin-right: 25px !important;
  }
  .me-md-30 {
    margin-right: 30px !important;
  }
  .me-md-40 {
    margin-right: 40px !important;
  }
  .me-md-45 {
    margin-right: 45px !important;
  }
  .me-md-50 {
    margin-right: 50px !important;
  }
  .me-md-60 {
    margin-right: 60px !important;
  }
  .me-md-70 {
    margin-right: 70px !important;
  }
  .me-md-80 {
    margin-right: 80px !important;
  }
  .me-md-90 {
    margin-right: 90px !important;
  }
  .me-md-100 {
    margin-right: 100px !important;
  }
  .me-md-180 {
    margin-right: 180px !important;
  }
  .me-md-300 {
    margin-right: 300px !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-2 {
    margin-bottom: 2px !important;
  }
  .mb-md-3 {
    margin-bottom: 3px !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .mb-md-6 {
    margin-bottom: 6px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .mb-md-25 {
    margin-bottom: 25px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
  .mb-md-45 {
    margin-bottom: 45px !important;
  }
  .mb-md-50 {
    margin-bottom: 50px !important;
  }
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
  .mb-md-70 {
    margin-bottom: 70px !important;
  }
  .mb-md-80 {
    margin-bottom: 80px !important;
  }
  .mb-md-90 {
    margin-bottom: 90px !important;
  }
  .mb-md-100 {
    margin-bottom: 100px !important;
  }
  .mb-md-180 {
    margin-bottom: 180px !important;
  }
  .mb-md-300 {
    margin-bottom: 300px !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-2 {
    margin-left: 2px !important;
  }
  .ms-md-3 {
    margin-left: 3px !important;
  }
  .ms-md-5 {
    margin-left: 5px !important;
  }
  .ms-md-6 {
    margin-left: 6px !important;
  }
  .ms-md-10 {
    margin-left: 10px !important;
  }
  .ms-md-15 {
    margin-left: 15px !important;
  }
  .ms-md-20 {
    margin-left: 20px !important;
  }
  .ms-md-25 {
    margin-left: 25px !important;
  }
  .ms-md-30 {
    margin-left: 30px !important;
  }
  .ms-md-40 {
    margin-left: 40px !important;
  }
  .ms-md-45 {
    margin-left: 45px !important;
  }
  .ms-md-50 {
    margin-left: 50px !important;
  }
  .ms-md-60 {
    margin-left: 60px !important;
  }
  .ms-md-70 {
    margin-left: 70px !important;
  }
  .ms-md-80 {
    margin-left: 80px !important;
  }
  .ms-md-90 {
    margin-left: 90px !important;
  }
  .ms-md-100 {
    margin-left: 100px !important;
  }
  .ms-md-180 {
    margin-left: 180px !important;
  }
  .ms-md-300 {
    margin-left: 300px !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n2 {
    margin: -2px !important;
  }
  .m-md-n3 {
    margin: -3px !important;
  }
  .m-md-n5 {
    margin: -5px !important;
  }
  .m-md-n6 {
    margin: -6px !important;
  }
  .m-md-n10 {
    margin: -10px !important;
  }
  .m-md-n15 {
    margin: -15px !important;
  }
  .m-md-n20 {
    margin: -20px !important;
  }
  .m-md-n25 {
    margin: -25px !important;
  }
  .m-md-n30 {
    margin: -30px !important;
  }
  .m-md-n40 {
    margin: -40px !important;
  }
  .m-md-n45 {
    margin: -45px !important;
  }
  .m-md-n50 {
    margin: -50px !important;
  }
  .m-md-n60 {
    margin: -60px !important;
  }
  .m-md-n70 {
    margin: -70px !important;
  }
  .m-md-n80 {
    margin: -80px !important;
  }
  .m-md-n90 {
    margin: -90px !important;
  }
  .m-md-n100 {
    margin: -100px !important;
  }
  .m-md-n180 {
    margin: -180px !important;
  }
  .m-md-n300 {
    margin: -300px !important;
  }
  .mx-md-n2 {
    margin-right: -2px !important;
    margin-left: -2px !important;
  }
  .mx-md-n3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-md-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-md-n6 {
    margin-right: -6px !important;
    margin-left: -6px !important;
  }
  .mx-md-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-md-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-md-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-md-n25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-md-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-md-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-md-n45 {
    margin-right: -45px !important;
    margin-left: -45px !important;
  }
  .mx-md-n50 {
    margin-right: -50px !important;
    margin-left: -50px !important;
  }
  .mx-md-n60 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-md-n70 {
    margin-right: -70px !important;
    margin-left: -70px !important;
  }
  .mx-md-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important;
  }
  .mx-md-n90 {
    margin-right: -90px !important;
    margin-left: -90px !important;
  }
  .mx-md-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important;
  }
  .mx-md-n180 {
    margin-right: -180px !important;
    margin-left: -180px !important;
  }
  .mx-md-n300 {
    margin-right: -300px !important;
    margin-left: -300px !important;
  }
  .my-md-n2 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .my-md-n3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-md-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-md-n6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .my-md-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-md-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-md-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-md-n25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-md-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-md-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-md-n45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .my-md-n50 {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
  .my-md-n60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-md-n70 {
    margin-top: -70px !important;
    margin-bottom: -70px !important;
  }
  .my-md-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important;
  }
  .my-md-n90 {
    margin-top: -90px !important;
    margin-bottom: -90px !important;
  }
  .my-md-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important;
  }
  .my-md-n180 {
    margin-top: -180px !important;
    margin-bottom: -180px !important;
  }
  .my-md-n300 {
    margin-top: -300px !important;
    margin-bottom: -300px !important;
  }
  .mt-md-n2 {
    margin-top: -2px !important;
  }
  .mt-md-n3 {
    margin-top: -3px !important;
  }
  .mt-md-n5 {
    margin-top: -5px !important;
  }
  .mt-md-n6 {
    margin-top: -6px !important;
  }
  .mt-md-n10 {
    margin-top: -10px !important;
  }
  .mt-md-n15 {
    margin-top: -15px !important;
  }
  .mt-md-n20 {
    margin-top: -20px !important;
  }
  .mt-md-n25 {
    margin-top: -25px !important;
  }
  .mt-md-n30 {
    margin-top: -30px !important;
  }
  .mt-md-n40 {
    margin-top: -40px !important;
  }
  .mt-md-n45 {
    margin-top: -45px !important;
  }
  .mt-md-n50 {
    margin-top: -50px !important;
  }
  .mt-md-n60 {
    margin-top: -60px !important;
  }
  .mt-md-n70 {
    margin-top: -70px !important;
  }
  .mt-md-n80 {
    margin-top: -80px !important;
  }
  .mt-md-n90 {
    margin-top: -90px !important;
  }
  .mt-md-n100 {
    margin-top: -100px !important;
  }
  .mt-md-n180 {
    margin-top: -180px !important;
  }
  .mt-md-n300 {
    margin-top: -300px !important;
  }
  .me-md-n2 {
    margin-right: -2px !important;
  }
  .me-md-n3 {
    margin-right: -3px !important;
  }
  .me-md-n5 {
    margin-right: -5px !important;
  }
  .me-md-n6 {
    margin-right: -6px !important;
  }
  .me-md-n10 {
    margin-right: -10px !important;
  }
  .me-md-n15 {
    margin-right: -15px !important;
  }
  .me-md-n20 {
    margin-right: -20px !important;
  }
  .me-md-n25 {
    margin-right: -25px !important;
  }
  .me-md-n30 {
    margin-right: -30px !important;
  }
  .me-md-n40 {
    margin-right: -40px !important;
  }
  .me-md-n45 {
    margin-right: -45px !important;
  }
  .me-md-n50 {
    margin-right: -50px !important;
  }
  .me-md-n60 {
    margin-right: -60px !important;
  }
  .me-md-n70 {
    margin-right: -70px !important;
  }
  .me-md-n80 {
    margin-right: -80px !important;
  }
  .me-md-n90 {
    margin-right: -90px !important;
  }
  .me-md-n100 {
    margin-right: -100px !important;
  }
  .me-md-n180 {
    margin-right: -180px !important;
  }
  .me-md-n300 {
    margin-right: -300px !important;
  }
  .mb-md-n2 {
    margin-bottom: -2px !important;
  }
  .mb-md-n3 {
    margin-bottom: -3px !important;
  }
  .mb-md-n5 {
    margin-bottom: -5px !important;
  }
  .mb-md-n6 {
    margin-bottom: -6px !important;
  }
  .mb-md-n10 {
    margin-bottom: -10px !important;
  }
  .mb-md-n15 {
    margin-bottom: -15px !important;
  }
  .mb-md-n20 {
    margin-bottom: -20px !important;
  }
  .mb-md-n25 {
    margin-bottom: -25px !important;
  }
  .mb-md-n30 {
    margin-bottom: -30px !important;
  }
  .mb-md-n40 {
    margin-bottom: -40px !important;
  }
  .mb-md-n45 {
    margin-bottom: -45px !important;
  }
  .mb-md-n50 {
    margin-bottom: -50px !important;
  }
  .mb-md-n60 {
    margin-bottom: -60px !important;
  }
  .mb-md-n70 {
    margin-bottom: -70px !important;
  }
  .mb-md-n80 {
    margin-bottom: -80px !important;
  }
  .mb-md-n90 {
    margin-bottom: -90px !important;
  }
  .mb-md-n100 {
    margin-bottom: -100px !important;
  }
  .mb-md-n180 {
    margin-bottom: -180px !important;
  }
  .mb-md-n300 {
    margin-bottom: -300px !important;
  }
  .ms-md-n2 {
    margin-left: -2px !important;
  }
  .ms-md-n3 {
    margin-left: -3px !important;
  }
  .ms-md-n5 {
    margin-left: -5px !important;
  }
  .ms-md-n6 {
    margin-left: -6px !important;
  }
  .ms-md-n10 {
    margin-left: -10px !important;
  }
  .ms-md-n15 {
    margin-left: -15px !important;
  }
  .ms-md-n20 {
    margin-left: -20px !important;
  }
  .ms-md-n25 {
    margin-left: -25px !important;
  }
  .ms-md-n30 {
    margin-left: -30px !important;
  }
  .ms-md-n40 {
    margin-left: -40px !important;
  }
  .ms-md-n45 {
    margin-left: -45px !important;
  }
  .ms-md-n50 {
    margin-left: -50px !important;
  }
  .ms-md-n60 {
    margin-left: -60px !important;
  }
  .ms-md-n70 {
    margin-left: -70px !important;
  }
  .ms-md-n80 {
    margin-left: -80px !important;
  }
  .ms-md-n90 {
    margin-left: -90px !important;
  }
  .ms-md-n100 {
    margin-left: -100px !important;
  }
  .ms-md-n180 {
    margin-left: -180px !important;
  }
  .ms-md-n300 {
    margin-left: -300px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-2 {
    padding: 2px !important;
  }
  .p-md-3 {
    padding: 3px !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .p-md-6 {
    padding: 6px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .p-md-25 {
    padding: 25px !important;
  }
  .p-md-30 {
    padding: 30px !important;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .p-md-45 {
    padding: 45px !important;
  }
  .p-md-50 {
    padding: 50px !important;
  }
  .p-md-60 {
    padding: 60px !important;
  }
  .p-md-70 {
    padding: 70px !important;
  }
  .p-md-80 {
    padding: 80px !important;
  }
  .p-md-90 {
    padding: 90px !important;
  }
  .p-md-100 {
    padding: 100px !important;
  }
  .p-md-180 {
    padding: 180px !important;
  }
  .p-md-300 {
    padding: 300px !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-2 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .px-md-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-md-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-md-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .px-md-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-md-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-md-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-md-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-md-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-md-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-md-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .px-md-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .px-md-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-md-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .px-md-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .px-md-90 {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
  .px-md-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .px-md-180 {
    padding-right: 180px !important;
    padding-left: 180px !important;
  }
  .px-md-300 {
    padding-right: 300px !important;
    padding-left: 300px !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .py-md-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-md-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .py-md-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .py-md-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-2 {
    padding-top: 2px !important;
  }
  .pt-md-3 {
    padding-top: 3px !important;
  }
  .pt-md-5 {
    padding-top: 5px !important;
  }
  .pt-md-6 {
    padding-top: 6px !important;
  }
  .pt-md-10 {
    padding-top: 10px !important;
  }
  .pt-md-15 {
    padding-top: 15px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .pt-md-25 {
    padding-top: 25px !important;
  }
  .pt-md-30 {
    padding-top: 30px !important;
  }
  .pt-md-40 {
    padding-top: 40px !important;
  }
  .pt-md-45 {
    padding-top: 45px !important;
  }
  .pt-md-50 {
    padding-top: 50px !important;
  }
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pt-md-70 {
    padding-top: 70px !important;
  }
  .pt-md-80 {
    padding-top: 80px !important;
  }
  .pt-md-90 {
    padding-top: 90px !important;
  }
  .pt-md-100 {
    padding-top: 100px !important;
  }
  .pt-md-180 {
    padding-top: 180px !important;
  }
  .pt-md-300 {
    padding-top: 300px !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-2 {
    padding-right: 2px !important;
  }
  .pe-md-3 {
    padding-right: 3px !important;
  }
  .pe-md-5 {
    padding-right: 5px !important;
  }
  .pe-md-6 {
    padding-right: 6px !important;
  }
  .pe-md-10 {
    padding-right: 10px !important;
  }
  .pe-md-15 {
    padding-right: 15px !important;
  }
  .pe-md-20 {
    padding-right: 20px !important;
  }
  .pe-md-25 {
    padding-right: 25px !important;
  }
  .pe-md-30 {
    padding-right: 30px !important;
  }
  .pe-md-40 {
    padding-right: 40px !important;
  }
  .pe-md-45 {
    padding-right: 45px !important;
  }
  .pe-md-50 {
    padding-right: 50px !important;
  }
  .pe-md-60 {
    padding-right: 60px !important;
  }
  .pe-md-70 {
    padding-right: 70px !important;
  }
  .pe-md-80 {
    padding-right: 80px !important;
  }
  .pe-md-90 {
    padding-right: 90px !important;
  }
  .pe-md-100 {
    padding-right: 100px !important;
  }
  .pe-md-180 {
    padding-right: 180px !important;
  }
  .pe-md-300 {
    padding-right: 300px !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-2 {
    padding-bottom: 2px !important;
  }
  .pb-md-3 {
    padding-bottom: 3px !important;
  }
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
  .pb-md-6 {
    padding-bottom: 6px !important;
  }
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .pb-md-25 {
    padding-bottom: 25px !important;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
  .pb-md-45 {
    padding-bottom: 45px !important;
  }
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
  .pb-md-90 {
    padding-bottom: 90px !important;
  }
  .pb-md-100 {
    padding-bottom: 100px !important;
  }
  .pb-md-180 {
    padding-bottom: 180px !important;
  }
  .pb-md-300 {
    padding-bottom: 300px !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-2 {
    padding-left: 2px !important;
  }
  .ps-md-3 {
    padding-left: 3px !important;
  }
  .ps-md-5 {
    padding-left: 5px !important;
  }
  .ps-md-6 {
    padding-left: 6px !important;
  }
  .ps-md-10 {
    padding-left: 10px !important;
  }
  .ps-md-15 {
    padding-left: 15px !important;
  }
  .ps-md-20 {
    padding-left: 20px !important;
  }
  .ps-md-25 {
    padding-left: 25px !important;
  }
  .ps-md-30 {
    padding-left: 30px !important;
  }
  .ps-md-40 {
    padding-left: 40px !important;
  }
  .ps-md-45 {
    padding-left: 45px !important;
  }
  .ps-md-50 {
    padding-left: 50px !important;
  }
  .ps-md-60 {
    padding-left: 60px !important;
  }
  .ps-md-70 {
    padding-left: 70px !important;
  }
  .ps-md-80 {
    padding-left: 80px !important;
  }
  .ps-md-90 {
    padding-left: 90px !important;
  }
  .ps-md-100 {
    padding-left: 100px !important;
  }
  .ps-md-180 {
    padding-left: 180px !important;
  }
  .ps-md-300 {
    padding-left: 300px !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-2 {
    margin: 2px !important;
  }
  .m-lg-3 {
    margin: 3px !important;
  }
  .m-lg-5 {
    margin: 5px !important;
  }
  .m-lg-6 {
    margin: 6px !important;
  }
  .m-lg-10 {
    margin: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .m-lg-25 {
    margin: 25px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .m-lg-40 {
    margin: 40px !important;
  }
  .m-lg-45 {
    margin: 45px !important;
  }
  .m-lg-50 {
    margin: 50px !important;
  }
  .m-lg-60 {
    margin: 60px !important;
  }
  .m-lg-70 {
    margin: 70px !important;
  }
  .m-lg-80 {
    margin: 80px !important;
  }
  .m-lg-90 {
    margin: 90px !important;
  }
  .m-lg-100 {
    margin: 100px !important;
  }
  .m-lg-180 {
    margin: 180px !important;
  }
  .m-lg-300 {
    margin: 300px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .mx-lg-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-lg-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-lg-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .mx-lg-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-lg-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-lg-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-lg-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-lg-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-lg-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-lg-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .mx-lg-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .mx-lg-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-lg-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .mx-lg-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-lg-90 {
    margin-right: 90px !important;
    margin-left: 90px !important;
  }
  .mx-lg-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .mx-lg-180 {
    margin-right: 180px !important;
    margin-left: 180px !important;
  }
  .mx-lg-300 {
    margin-right: 300px !important;
    margin-left: 300px !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .my-lg-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-lg-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .my-lg-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .my-lg-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-2 {
    margin-top: 2px !important;
  }
  .mt-lg-3 {
    margin-top: 3px !important;
  }
  .mt-lg-5 {
    margin-top: 5px !important;
  }
  .mt-lg-6 {
    margin-top: 6px !important;
  }
  .mt-lg-10 {
    margin-top: 10px !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .mt-lg-20 {
    margin-top: 20px !important;
  }
  .mt-lg-25 {
    margin-top: 25px !important;
  }
  .mt-lg-30 {
    margin-top: 30px !important;
  }
  .mt-lg-40 {
    margin-top: 40px !important;
  }
  .mt-lg-45 {
    margin-top: 45px !important;
  }
  .mt-lg-50 {
    margin-top: 50px !important;
  }
  .mt-lg-60 {
    margin-top: 60px !important;
  }
  .mt-lg-70 {
    margin-top: 70px !important;
  }
  .mt-lg-80 {
    margin-top: 80px !important;
  }
  .mt-lg-90 {
    margin-top: 90px !important;
  }
  .mt-lg-100 {
    margin-top: 100px !important;
  }
  .mt-lg-180 {
    margin-top: 180px !important;
  }
  .mt-lg-300 {
    margin-top: 300px !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-2 {
    margin-right: 2px !important;
  }
  .me-lg-3 {
    margin-right: 3px !important;
  }
  .me-lg-5 {
    margin-right: 5px !important;
  }
  .me-lg-6 {
    margin-right: 6px !important;
  }
  .me-lg-10 {
    margin-right: 10px !important;
  }
  .me-lg-15 {
    margin-right: 15px !important;
  }
  .me-lg-20 {
    margin-right: 20px !important;
  }
  .me-lg-25 {
    margin-right: 25px !important;
  }
  .me-lg-30 {
    margin-right: 30px !important;
  }
  .me-lg-40 {
    margin-right: 40px !important;
  }
  .me-lg-45 {
    margin-right: 45px !important;
  }
  .me-lg-50 {
    margin-right: 50px !important;
  }
  .me-lg-60 {
    margin-right: 60px !important;
  }
  .me-lg-70 {
    margin-right: 70px !important;
  }
  .me-lg-80 {
    margin-right: 80px !important;
  }
  .me-lg-90 {
    margin-right: 90px !important;
  }
  .me-lg-100 {
    margin-right: 100px !important;
  }
  .me-lg-180 {
    margin-right: 180px !important;
  }
  .me-lg-300 {
    margin-right: 300px !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-2 {
    margin-bottom: 2px !important;
  }
  .mb-lg-3 {
    margin-bottom: 3px !important;
  }
  .mb-lg-5 {
    margin-bottom: 5px !important;
  }
  .mb-lg-6 {
    margin-bottom: 6px !important;
  }
  .mb-lg-10 {
    margin-bottom: 10px !important;
  }
  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
  .mb-lg-25 {
    margin-bottom: 25px !important;
  }
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
  .mb-lg-40 {
    margin-bottom: 40px !important;
  }
  .mb-lg-45 {
    margin-bottom: 45px !important;
  }
  .mb-lg-50 {
    margin-bottom: 50px !important;
  }
  .mb-lg-60 {
    margin-bottom: 60px !important;
  }
  .mb-lg-70 {
    margin-bottom: 70px !important;
  }
  .mb-lg-80 {
    margin-bottom: 80px !important;
  }
  .mb-lg-90 {
    margin-bottom: 90px !important;
  }
  .mb-lg-100 {
    margin-bottom: 100px !important;
  }
  .mb-lg-180 {
    margin-bottom: 180px !important;
  }
  .mb-lg-300 {
    margin-bottom: 300px !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-2 {
    margin-left: 2px !important;
  }
  .ms-lg-3 {
    margin-left: 3px !important;
  }
  .ms-lg-5 {
    margin-left: 5px !important;
  }
  .ms-lg-6 {
    margin-left: 6px !important;
  }
  .ms-lg-10 {
    margin-left: 10px !important;
  }
  .ms-lg-15 {
    margin-left: 15px !important;
  }
  .ms-lg-20 {
    margin-left: 20px !important;
  }
  .ms-lg-25 {
    margin-left: 25px !important;
  }
  .ms-lg-30 {
    margin-left: 30px !important;
  }
  .ms-lg-40 {
    margin-left: 40px !important;
  }
  .ms-lg-45 {
    margin-left: 45px !important;
  }
  .ms-lg-50 {
    margin-left: 50px !important;
  }
  .ms-lg-60 {
    margin-left: 60px !important;
  }
  .ms-lg-70 {
    margin-left: 70px !important;
  }
  .ms-lg-80 {
    margin-left: 80px !important;
  }
  .ms-lg-90 {
    margin-left: 90px !important;
  }
  .ms-lg-100 {
    margin-left: 100px !important;
  }
  .ms-lg-180 {
    margin-left: 180px !important;
  }
  .ms-lg-300 {
    margin-left: 300px !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n2 {
    margin: -2px !important;
  }
  .m-lg-n3 {
    margin: -3px !important;
  }
  .m-lg-n5 {
    margin: -5px !important;
  }
  .m-lg-n6 {
    margin: -6px !important;
  }
  .m-lg-n10 {
    margin: -10px !important;
  }
  .m-lg-n15 {
    margin: -15px !important;
  }
  .m-lg-n20 {
    margin: -20px !important;
  }
  .m-lg-n25 {
    margin: -25px !important;
  }
  .m-lg-n30 {
    margin: -30px !important;
  }
  .m-lg-n40 {
    margin: -40px !important;
  }
  .m-lg-n45 {
    margin: -45px !important;
  }
  .m-lg-n50 {
    margin: -50px !important;
  }
  .m-lg-n60 {
    margin: -60px !important;
  }
  .m-lg-n70 {
    margin: -70px !important;
  }
  .m-lg-n80 {
    margin: -80px !important;
  }
  .m-lg-n90 {
    margin: -90px !important;
  }
  .m-lg-n100 {
    margin: -100px !important;
  }
  .m-lg-n180 {
    margin: -180px !important;
  }
  .m-lg-n300 {
    margin: -300px !important;
  }
  .mx-lg-n2 {
    margin-right: -2px !important;
    margin-left: -2px !important;
  }
  .mx-lg-n3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-lg-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-lg-n6 {
    margin-right: -6px !important;
    margin-left: -6px !important;
  }
  .mx-lg-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-lg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-lg-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-lg-n25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-lg-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-lg-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-lg-n45 {
    margin-right: -45px !important;
    margin-left: -45px !important;
  }
  .mx-lg-n50 {
    margin-right: -50px !important;
    margin-left: -50px !important;
  }
  .mx-lg-n60 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-lg-n70 {
    margin-right: -70px !important;
    margin-left: -70px !important;
  }
  .mx-lg-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important;
  }
  .mx-lg-n90 {
    margin-right: -90px !important;
    margin-left: -90px !important;
  }
  .mx-lg-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important;
  }
  .mx-lg-n180 {
    margin-right: -180px !important;
    margin-left: -180px !important;
  }
  .mx-lg-n300 {
    margin-right: -300px !important;
    margin-left: -300px !important;
  }
  .my-lg-n2 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .my-lg-n3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-lg-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-lg-n6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .my-lg-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-lg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-lg-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-lg-n25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-lg-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-lg-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-lg-n45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .my-lg-n50 {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
  .my-lg-n60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-lg-n70 {
    margin-top: -70px !important;
    margin-bottom: -70px !important;
  }
  .my-lg-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important;
  }
  .my-lg-n90 {
    margin-top: -90px !important;
    margin-bottom: -90px !important;
  }
  .my-lg-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important;
  }
  .my-lg-n180 {
    margin-top: -180px !important;
    margin-bottom: -180px !important;
  }
  .my-lg-n300 {
    margin-top: -300px !important;
    margin-bottom: -300px !important;
  }
  .mt-lg-n2 {
    margin-top: -2px !important;
  }
  .mt-lg-n3 {
    margin-top: -3px !important;
  }
  .mt-lg-n5 {
    margin-top: -5px !important;
  }
  .mt-lg-n6 {
    margin-top: -6px !important;
  }
  .mt-lg-n10 {
    margin-top: -10px !important;
  }
  .mt-lg-n15 {
    margin-top: -15px !important;
  }
  .mt-lg-n20 {
    margin-top: -20px !important;
  }
  .mt-lg-n25 {
    margin-top: -25px !important;
  }
  .mt-lg-n30 {
    margin-top: -30px !important;
  }
  .mt-lg-n40 {
    margin-top: -40px !important;
  }
  .mt-lg-n45 {
    margin-top: -45px !important;
  }
  .mt-lg-n50 {
    margin-top: -50px !important;
  }
  .mt-lg-n60 {
    margin-top: -60px !important;
  }
  .mt-lg-n70 {
    margin-top: -70px !important;
  }
  .mt-lg-n80 {
    margin-top: -80px !important;
  }
  .mt-lg-n90 {
    margin-top: -90px !important;
  }
  .mt-lg-n100 {
    margin-top: -100px !important;
  }
  .mt-lg-n180 {
    margin-top: -180px !important;
  }
  .mt-lg-n300 {
    margin-top: -300px !important;
  }
  .me-lg-n2 {
    margin-right: -2px !important;
  }
  .me-lg-n3 {
    margin-right: -3px !important;
  }
  .me-lg-n5 {
    margin-right: -5px !important;
  }
  .me-lg-n6 {
    margin-right: -6px !important;
  }
  .me-lg-n10 {
    margin-right: -10px !important;
  }
  .me-lg-n15 {
    margin-right: -15px !important;
  }
  .me-lg-n20 {
    margin-right: -20px !important;
  }
  .me-lg-n25 {
    margin-right: -25px !important;
  }
  .me-lg-n30 {
    margin-right: -30px !important;
  }
  .me-lg-n40 {
    margin-right: -40px !important;
  }
  .me-lg-n45 {
    margin-right: -45px !important;
  }
  .me-lg-n50 {
    margin-right: -50px !important;
  }
  .me-lg-n60 {
    margin-right: -60px !important;
  }
  .me-lg-n70 {
    margin-right: -70px !important;
  }
  .me-lg-n80 {
    margin-right: -80px !important;
  }
  .me-lg-n90 {
    margin-right: -90px !important;
  }
  .me-lg-n100 {
    margin-right: -100px !important;
  }
  .me-lg-n180 {
    margin-right: -180px !important;
  }
  .me-lg-n300 {
    margin-right: -300px !important;
  }
  .mb-lg-n2 {
    margin-bottom: -2px !important;
  }
  .mb-lg-n3 {
    margin-bottom: -3px !important;
  }
  .mb-lg-n5 {
    margin-bottom: -5px !important;
  }
  .mb-lg-n6 {
    margin-bottom: -6px !important;
  }
  .mb-lg-n10 {
    margin-bottom: -10px !important;
  }
  .mb-lg-n15 {
    margin-bottom: -15px !important;
  }
  .mb-lg-n20 {
    margin-bottom: -20px !important;
  }
  .mb-lg-n25 {
    margin-bottom: -25px !important;
  }
  .mb-lg-n30 {
    margin-bottom: -30px !important;
  }
  .mb-lg-n40 {
    margin-bottom: -40px !important;
  }
  .mb-lg-n45 {
    margin-bottom: -45px !important;
  }
  .mb-lg-n50 {
    margin-bottom: -50px !important;
  }
  .mb-lg-n60 {
    margin-bottom: -60px !important;
  }
  .mb-lg-n70 {
    margin-bottom: -70px !important;
  }
  .mb-lg-n80 {
    margin-bottom: -80px !important;
  }
  .mb-lg-n90 {
    margin-bottom: -90px !important;
  }
  .mb-lg-n100 {
    margin-bottom: -100px !important;
  }
  .mb-lg-n180 {
    margin-bottom: -180px !important;
  }
  .mb-lg-n300 {
    margin-bottom: -300px !important;
  }
  .ms-lg-n2 {
    margin-left: -2px !important;
  }
  .ms-lg-n3 {
    margin-left: -3px !important;
  }
  .ms-lg-n5 {
    margin-left: -5px !important;
  }
  .ms-lg-n6 {
    margin-left: -6px !important;
  }
  .ms-lg-n10 {
    margin-left: -10px !important;
  }
  .ms-lg-n15 {
    margin-left: -15px !important;
  }
  .ms-lg-n20 {
    margin-left: -20px !important;
  }
  .ms-lg-n25 {
    margin-left: -25px !important;
  }
  .ms-lg-n30 {
    margin-left: -30px !important;
  }
  .ms-lg-n40 {
    margin-left: -40px !important;
  }
  .ms-lg-n45 {
    margin-left: -45px !important;
  }
  .ms-lg-n50 {
    margin-left: -50px !important;
  }
  .ms-lg-n60 {
    margin-left: -60px !important;
  }
  .ms-lg-n70 {
    margin-left: -70px !important;
  }
  .ms-lg-n80 {
    margin-left: -80px !important;
  }
  .ms-lg-n90 {
    margin-left: -90px !important;
  }
  .ms-lg-n100 {
    margin-left: -100px !important;
  }
  .ms-lg-n180 {
    margin-left: -180px !important;
  }
  .ms-lg-n300 {
    margin-left: -300px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-2 {
    padding: 2px !important;
  }
  .p-lg-3 {
    padding: 3px !important;
  }
  .p-lg-5 {
    padding: 5px !important;
  }
  .p-lg-6 {
    padding: 6px !important;
  }
  .p-lg-10 {
    padding: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .p-lg-25 {
    padding: 25px !important;
  }
  .p-lg-30 {
    padding: 30px !important;
  }
  .p-lg-40 {
    padding: 40px !important;
  }
  .p-lg-45 {
    padding: 45px !important;
  }
  .p-lg-50 {
    padding: 50px !important;
  }
  .p-lg-60 {
    padding: 60px !important;
  }
  .p-lg-70 {
    padding: 70px !important;
  }
  .p-lg-80 {
    padding: 80px !important;
  }
  .p-lg-90 {
    padding: 90px !important;
  }
  .p-lg-100 {
    padding: 100px !important;
  }
  .p-lg-180 {
    padding: 180px !important;
  }
  .p-lg-300 {
    padding: 300px !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-2 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .px-lg-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-lg-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-lg-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .px-lg-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-lg-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-lg-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-lg-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-lg-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-lg-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-lg-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .px-lg-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .px-lg-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-lg-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .px-lg-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .px-lg-90 {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
  .px-lg-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .px-lg-180 {
    padding-right: 180px !important;
    padding-left: 180px !important;
  }
  .px-lg-300 {
    padding-right: 300px !important;
    padding-left: 300px !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .py-lg-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-lg-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .py-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .py-lg-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .py-lg-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-2 {
    padding-top: 2px !important;
  }
  .pt-lg-3 {
    padding-top: 3px !important;
  }
  .pt-lg-5 {
    padding-top: 5px !important;
  }
  .pt-lg-6 {
    padding-top: 6px !important;
  }
  .pt-lg-10 {
    padding-top: 10px !important;
  }
  .pt-lg-15 {
    padding-top: 15px !important;
  }
  .pt-lg-20 {
    padding-top: 20px !important;
  }
  .pt-lg-25 {
    padding-top: 25px !important;
  }
  .pt-lg-30 {
    padding-top: 30px !important;
  }
  .pt-lg-40 {
    padding-top: 40px !important;
  }
  .pt-lg-45 {
    padding-top: 45px !important;
  }
  .pt-lg-50 {
    padding-top: 50px !important;
  }
  .pt-lg-60 {
    padding-top: 60px !important;
  }
  .pt-lg-70 {
    padding-top: 70px !important;
  }
  .pt-lg-80 {
    padding-top: 80px !important;
  }
  .pt-lg-90 {
    padding-top: 90px !important;
  }
  .pt-lg-100 {
    padding-top: 100px !important;
  }
  .pt-lg-180 {
    padding-top: 180px !important;
  }
  .pt-lg-300 {
    padding-top: 300px !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-2 {
    padding-right: 2px !important;
  }
  .pe-lg-3 {
    padding-right: 3px !important;
  }
  .pe-lg-5 {
    padding-right: 5px !important;
  }
  .pe-lg-6 {
    padding-right: 6px !important;
  }
  .pe-lg-10 {
    padding-right: 10px !important;
  }
  .pe-lg-15 {
    padding-right: 15px !important;
  }
  .pe-lg-20 {
    padding-right: 20px !important;
  }
  .pe-lg-25 {
    padding-right: 25px !important;
  }
  .pe-lg-30 {
    padding-right: 30px !important;
  }
  .pe-lg-40 {
    padding-right: 40px !important;
  }
  .pe-lg-45 {
    padding-right: 45px !important;
  }
  .pe-lg-50 {
    padding-right: 50px !important;
  }
  .pe-lg-60 {
    padding-right: 60px !important;
  }
  .pe-lg-70 {
    padding-right: 70px !important;
  }
  .pe-lg-80 {
    padding-right: 80px !important;
  }
  .pe-lg-90 {
    padding-right: 90px !important;
  }
  .pe-lg-100 {
    padding-right: 100px !important;
  }
  .pe-lg-180 {
    padding-right: 180px !important;
  }
  .pe-lg-300 {
    padding-right: 300px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-2 {
    padding-bottom: 2px !important;
  }
  .pb-lg-3 {
    padding-bottom: 3px !important;
  }
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
  .pb-lg-6 {
    padding-bottom: 6px !important;
  }
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
  .pb-lg-25 {
    padding-bottom: 25px !important;
  }
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
  .pb-lg-40 {
    padding-bottom: 40px !important;
  }
  .pb-lg-45 {
    padding-bottom: 45px !important;
  }
  .pb-lg-50 {
    padding-bottom: 50px !important;
  }
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
  .pb-lg-70 {
    padding-bottom: 70px !important;
  }
  .pb-lg-80 {
    padding-bottom: 80px !important;
  }
  .pb-lg-90 {
    padding-bottom: 90px !important;
  }
  .pb-lg-100 {
    padding-bottom: 100px !important;
  }
  .pb-lg-180 {
    padding-bottom: 180px !important;
  }
  .pb-lg-300 {
    padding-bottom: 300px !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-2 {
    padding-left: 2px !important;
  }
  .ps-lg-3 {
    padding-left: 3px !important;
  }
  .ps-lg-5 {
    padding-left: 5px !important;
  }
  .ps-lg-6 {
    padding-left: 6px !important;
  }
  .ps-lg-10 {
    padding-left: 10px !important;
  }
  .ps-lg-15 {
    padding-left: 15px !important;
  }
  .ps-lg-20 {
    padding-left: 20px !important;
  }
  .ps-lg-25 {
    padding-left: 25px !important;
  }
  .ps-lg-30 {
    padding-left: 30px !important;
  }
  .ps-lg-40 {
    padding-left: 40px !important;
  }
  .ps-lg-45 {
    padding-left: 45px !important;
  }
  .ps-lg-50 {
    padding-left: 50px !important;
  }
  .ps-lg-60 {
    padding-left: 60px !important;
  }
  .ps-lg-70 {
    padding-left: 70px !important;
  }
  .ps-lg-80 {
    padding-left: 80px !important;
  }
  .ps-lg-90 {
    padding-left: 90px !important;
  }
  .ps-lg-100 {
    padding-left: 100px !important;
  }
  .ps-lg-180 {
    padding-left: 180px !important;
  }
  .ps-lg-300 {
    padding-left: 300px !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-2 {
    margin: 2px !important;
  }
  .m-xl-3 {
    margin: 3px !important;
  }
  .m-xl-5 {
    margin: 5px !important;
  }
  .m-xl-6 {
    margin: 6px !important;
  }
  .m-xl-10 {
    margin: 10px !important;
  }
  .m-xl-15 {
    margin: 15px !important;
  }
  .m-xl-20 {
    margin: 20px !important;
  }
  .m-xl-25 {
    margin: 25px !important;
  }
  .m-xl-30 {
    margin: 30px !important;
  }
  .m-xl-40 {
    margin: 40px !important;
  }
  .m-xl-45 {
    margin: 45px !important;
  }
  .m-xl-50 {
    margin: 50px !important;
  }
  .m-xl-60 {
    margin: 60px !important;
  }
  .m-xl-70 {
    margin: 70px !important;
  }
  .m-xl-80 {
    margin: 80px !important;
  }
  .m-xl-90 {
    margin: 90px !important;
  }
  .m-xl-100 {
    margin: 100px !important;
  }
  .m-xl-180 {
    margin: 180px !important;
  }
  .m-xl-300 {
    margin: 300px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .mx-xl-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-xl-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-xl-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .mx-xl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-xl-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xl-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-xl-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-xl-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-xl-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-xl-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .mx-xl-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .mx-xl-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-xl-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .mx-xl-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-xl-90 {
    margin-right: 90px !important;
    margin-left: 90px !important;
  }
  .mx-xl-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .mx-xl-180 {
    margin-right: 180px !important;
    margin-left: 180px !important;
  }
  .mx-xl-300 {
    margin-right: 300px !important;
    margin-left: 300px !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .my-xl-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-xl-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-xl-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .my-xl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-xl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-xl-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-xl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-xl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-xl-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .my-xl-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .my-xl-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-xl-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .my-xl-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-xl-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .my-xl-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .my-xl-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .my-xl-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-2 {
    margin-top: 2px !important;
  }
  .mt-xl-3 {
    margin-top: 3px !important;
  }
  .mt-xl-5 {
    margin-top: 5px !important;
  }
  .mt-xl-6 {
    margin-top: 6px !important;
  }
  .mt-xl-10 {
    margin-top: 10px !important;
  }
  .mt-xl-15 {
    margin-top: 15px !important;
  }
  .mt-xl-20 {
    margin-top: 20px !important;
  }
  .mt-xl-25 {
    margin-top: 25px !important;
  }
  .mt-xl-30 {
    margin-top: 30px !important;
  }
  .mt-xl-40 {
    margin-top: 40px !important;
  }
  .mt-xl-45 {
    margin-top: 45px !important;
  }
  .mt-xl-50 {
    margin-top: 50px !important;
  }
  .mt-xl-60 {
    margin-top: 60px !important;
  }
  .mt-xl-70 {
    margin-top: 70px !important;
  }
  .mt-xl-80 {
    margin-top: 80px !important;
  }
  .mt-xl-90 {
    margin-top: 90px !important;
  }
  .mt-xl-100 {
    margin-top: 100px !important;
  }
  .mt-xl-180 {
    margin-top: 180px !important;
  }
  .mt-xl-300 {
    margin-top: 300px !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-2 {
    margin-right: 2px !important;
  }
  .me-xl-3 {
    margin-right: 3px !important;
  }
  .me-xl-5 {
    margin-right: 5px !important;
  }
  .me-xl-6 {
    margin-right: 6px !important;
  }
  .me-xl-10 {
    margin-right: 10px !important;
  }
  .me-xl-15 {
    margin-right: 15px !important;
  }
  .me-xl-20 {
    margin-right: 20px !important;
  }
  .me-xl-25 {
    margin-right: 25px !important;
  }
  .me-xl-30 {
    margin-right: 30px !important;
  }
  .me-xl-40 {
    margin-right: 40px !important;
  }
  .me-xl-45 {
    margin-right: 45px !important;
  }
  .me-xl-50 {
    margin-right: 50px !important;
  }
  .me-xl-60 {
    margin-right: 60px !important;
  }
  .me-xl-70 {
    margin-right: 70px !important;
  }
  .me-xl-80 {
    margin-right: 80px !important;
  }
  .me-xl-90 {
    margin-right: 90px !important;
  }
  .me-xl-100 {
    margin-right: 100px !important;
  }
  .me-xl-180 {
    margin-right: 180px !important;
  }
  .me-xl-300 {
    margin-right: 300px !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-2 {
    margin-bottom: 2px !important;
  }
  .mb-xl-3 {
    margin-bottom: 3px !important;
  }
  .mb-xl-5 {
    margin-bottom: 5px !important;
  }
  .mb-xl-6 {
    margin-bottom: 6px !important;
  }
  .mb-xl-10 {
    margin-bottom: 10px !important;
  }
  .mb-xl-15 {
    margin-bottom: 15px !important;
  }
  .mb-xl-20 {
    margin-bottom: 20px !important;
  }
  .mb-xl-25 {
    margin-bottom: 25px !important;
  }
  .mb-xl-30 {
    margin-bottom: 30px !important;
  }
  .mb-xl-40 {
    margin-bottom: 40px !important;
  }
  .mb-xl-45 {
    margin-bottom: 45px !important;
  }
  .mb-xl-50 {
    margin-bottom: 50px !important;
  }
  .mb-xl-60 {
    margin-bottom: 60px !important;
  }
  .mb-xl-70 {
    margin-bottom: 70px !important;
  }
  .mb-xl-80 {
    margin-bottom: 80px !important;
  }
  .mb-xl-90 {
    margin-bottom: 90px !important;
  }
  .mb-xl-100 {
    margin-bottom: 100px !important;
  }
  .mb-xl-180 {
    margin-bottom: 180px !important;
  }
  .mb-xl-300 {
    margin-bottom: 300px !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-2 {
    margin-left: 2px !important;
  }
  .ms-xl-3 {
    margin-left: 3px !important;
  }
  .ms-xl-5 {
    margin-left: 5px !important;
  }
  .ms-xl-6 {
    margin-left: 6px !important;
  }
  .ms-xl-10 {
    margin-left: 10px !important;
  }
  .ms-xl-15 {
    margin-left: 15px !important;
  }
  .ms-xl-20 {
    margin-left: 20px !important;
  }
  .ms-xl-25 {
    margin-left: 25px !important;
  }
  .ms-xl-30 {
    margin-left: 30px !important;
  }
  .ms-xl-40 {
    margin-left: 40px !important;
  }
  .ms-xl-45 {
    margin-left: 45px !important;
  }
  .ms-xl-50 {
    margin-left: 50px !important;
  }
  .ms-xl-60 {
    margin-left: 60px !important;
  }
  .ms-xl-70 {
    margin-left: 70px !important;
  }
  .ms-xl-80 {
    margin-left: 80px !important;
  }
  .ms-xl-90 {
    margin-left: 90px !important;
  }
  .ms-xl-100 {
    margin-left: 100px !important;
  }
  .ms-xl-180 {
    margin-left: 180px !important;
  }
  .ms-xl-300 {
    margin-left: 300px !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n2 {
    margin: -2px !important;
  }
  .m-xl-n3 {
    margin: -3px !important;
  }
  .m-xl-n5 {
    margin: -5px !important;
  }
  .m-xl-n6 {
    margin: -6px !important;
  }
  .m-xl-n10 {
    margin: -10px !important;
  }
  .m-xl-n15 {
    margin: -15px !important;
  }
  .m-xl-n20 {
    margin: -20px !important;
  }
  .m-xl-n25 {
    margin: -25px !important;
  }
  .m-xl-n30 {
    margin: -30px !important;
  }
  .m-xl-n40 {
    margin: -40px !important;
  }
  .m-xl-n45 {
    margin: -45px !important;
  }
  .m-xl-n50 {
    margin: -50px !important;
  }
  .m-xl-n60 {
    margin: -60px !important;
  }
  .m-xl-n70 {
    margin: -70px !important;
  }
  .m-xl-n80 {
    margin: -80px !important;
  }
  .m-xl-n90 {
    margin: -90px !important;
  }
  .m-xl-n100 {
    margin: -100px !important;
  }
  .m-xl-n180 {
    margin: -180px !important;
  }
  .m-xl-n300 {
    margin: -300px !important;
  }
  .mx-xl-n2 {
    margin-right: -2px !important;
    margin-left: -2px !important;
  }
  .mx-xl-n3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-xl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-xl-n6 {
    margin-right: -6px !important;
    margin-left: -6px !important;
  }
  .mx-xl-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-xl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xl-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-xl-n25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-xl-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-xl-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-xl-n45 {
    margin-right: -45px !important;
    margin-left: -45px !important;
  }
  .mx-xl-n50 {
    margin-right: -50px !important;
    margin-left: -50px !important;
  }
  .mx-xl-n60 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-xl-n70 {
    margin-right: -70px !important;
    margin-left: -70px !important;
  }
  .mx-xl-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important;
  }
  .mx-xl-n90 {
    margin-right: -90px !important;
    margin-left: -90px !important;
  }
  .mx-xl-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important;
  }
  .mx-xl-n180 {
    margin-right: -180px !important;
    margin-left: -180px !important;
  }
  .mx-xl-n300 {
    margin-right: -300px !important;
    margin-left: -300px !important;
  }
  .my-xl-n2 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .my-xl-n3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-xl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-xl-n6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .my-xl-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-xl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xl-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-xl-n25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-xl-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-xl-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-xl-n45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .my-xl-n50 {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
  .my-xl-n60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-xl-n70 {
    margin-top: -70px !important;
    margin-bottom: -70px !important;
  }
  .my-xl-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important;
  }
  .my-xl-n90 {
    margin-top: -90px !important;
    margin-bottom: -90px !important;
  }
  .my-xl-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important;
  }
  .my-xl-n180 {
    margin-top: -180px !important;
    margin-bottom: -180px !important;
  }
  .my-xl-n300 {
    margin-top: -300px !important;
    margin-bottom: -300px !important;
  }
  .mt-xl-n2 {
    margin-top: -2px !important;
  }
  .mt-xl-n3 {
    margin-top: -3px !important;
  }
  .mt-xl-n5 {
    margin-top: -5px !important;
  }
  .mt-xl-n6 {
    margin-top: -6px !important;
  }
  .mt-xl-n10 {
    margin-top: -10px !important;
  }
  .mt-xl-n15 {
    margin-top: -15px !important;
  }
  .mt-xl-n20 {
    margin-top: -20px !important;
  }
  .mt-xl-n25 {
    margin-top: -25px !important;
  }
  .mt-xl-n30 {
    margin-top: -30px !important;
  }
  .mt-xl-n40 {
    margin-top: -40px !important;
  }
  .mt-xl-n45 {
    margin-top: -45px !important;
  }
  .mt-xl-n50 {
    margin-top: -50px !important;
  }
  .mt-xl-n60 {
    margin-top: -60px !important;
  }
  .mt-xl-n70 {
    margin-top: -70px !important;
  }
  .mt-xl-n80 {
    margin-top: -80px !important;
  }
  .mt-xl-n90 {
    margin-top: -90px !important;
  }
  .mt-xl-n100 {
    margin-top: -100px !important;
  }
  .mt-xl-n180 {
    margin-top: -180px !important;
  }
  .mt-xl-n300 {
    margin-top: -300px !important;
  }
  .me-xl-n2 {
    margin-right: -2px !important;
  }
  .me-xl-n3 {
    margin-right: -3px !important;
  }
  .me-xl-n5 {
    margin-right: -5px !important;
  }
  .me-xl-n6 {
    margin-right: -6px !important;
  }
  .me-xl-n10 {
    margin-right: -10px !important;
  }
  .me-xl-n15 {
    margin-right: -15px !important;
  }
  .me-xl-n20 {
    margin-right: -20px !important;
  }
  .me-xl-n25 {
    margin-right: -25px !important;
  }
  .me-xl-n30 {
    margin-right: -30px !important;
  }
  .me-xl-n40 {
    margin-right: -40px !important;
  }
  .me-xl-n45 {
    margin-right: -45px !important;
  }
  .me-xl-n50 {
    margin-right: -50px !important;
  }
  .me-xl-n60 {
    margin-right: -60px !important;
  }
  .me-xl-n70 {
    margin-right: -70px !important;
  }
  .me-xl-n80 {
    margin-right: -80px !important;
  }
  .me-xl-n90 {
    margin-right: -90px !important;
  }
  .me-xl-n100 {
    margin-right: -100px !important;
  }
  .me-xl-n180 {
    margin-right: -180px !important;
  }
  .me-xl-n300 {
    margin-right: -300px !important;
  }
  .mb-xl-n2 {
    margin-bottom: -2px !important;
  }
  .mb-xl-n3 {
    margin-bottom: -3px !important;
  }
  .mb-xl-n5 {
    margin-bottom: -5px !important;
  }
  .mb-xl-n6 {
    margin-bottom: -6px !important;
  }
  .mb-xl-n10 {
    margin-bottom: -10px !important;
  }
  .mb-xl-n15 {
    margin-bottom: -15px !important;
  }
  .mb-xl-n20 {
    margin-bottom: -20px !important;
  }
  .mb-xl-n25 {
    margin-bottom: -25px !important;
  }
  .mb-xl-n30 {
    margin-bottom: -30px !important;
  }
  .mb-xl-n40 {
    margin-bottom: -40px !important;
  }
  .mb-xl-n45 {
    margin-bottom: -45px !important;
  }
  .mb-xl-n50 {
    margin-bottom: -50px !important;
  }
  .mb-xl-n60 {
    margin-bottom: -60px !important;
  }
  .mb-xl-n70 {
    margin-bottom: -70px !important;
  }
  .mb-xl-n80 {
    margin-bottom: -80px !important;
  }
  .mb-xl-n90 {
    margin-bottom: -90px !important;
  }
  .mb-xl-n100 {
    margin-bottom: -100px !important;
  }
  .mb-xl-n180 {
    margin-bottom: -180px !important;
  }
  .mb-xl-n300 {
    margin-bottom: -300px !important;
  }
  .ms-xl-n2 {
    margin-left: -2px !important;
  }
  .ms-xl-n3 {
    margin-left: -3px !important;
  }
  .ms-xl-n5 {
    margin-left: -5px !important;
  }
  .ms-xl-n6 {
    margin-left: -6px !important;
  }
  .ms-xl-n10 {
    margin-left: -10px !important;
  }
  .ms-xl-n15 {
    margin-left: -15px !important;
  }
  .ms-xl-n20 {
    margin-left: -20px !important;
  }
  .ms-xl-n25 {
    margin-left: -25px !important;
  }
  .ms-xl-n30 {
    margin-left: -30px !important;
  }
  .ms-xl-n40 {
    margin-left: -40px !important;
  }
  .ms-xl-n45 {
    margin-left: -45px !important;
  }
  .ms-xl-n50 {
    margin-left: -50px !important;
  }
  .ms-xl-n60 {
    margin-left: -60px !important;
  }
  .ms-xl-n70 {
    margin-left: -70px !important;
  }
  .ms-xl-n80 {
    margin-left: -80px !important;
  }
  .ms-xl-n90 {
    margin-left: -90px !important;
  }
  .ms-xl-n100 {
    margin-left: -100px !important;
  }
  .ms-xl-n180 {
    margin-left: -180px !important;
  }
  .ms-xl-n300 {
    margin-left: -300px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-2 {
    padding: 2px !important;
  }
  .p-xl-3 {
    padding: 3px !important;
  }
  .p-xl-5 {
    padding: 5px !important;
  }
  .p-xl-6 {
    padding: 6px !important;
  }
  .p-xl-10 {
    padding: 10px !important;
  }
  .p-xl-15 {
    padding: 15px !important;
  }
  .p-xl-20 {
    padding: 20px !important;
  }
  .p-xl-25 {
    padding: 25px !important;
  }
  .p-xl-30 {
    padding: 30px !important;
  }
  .p-xl-40 {
    padding: 40px !important;
  }
  .p-xl-45 {
    padding: 45px !important;
  }
  .p-xl-50 {
    padding: 50px !important;
  }
  .p-xl-60 {
    padding: 60px !important;
  }
  .p-xl-70 {
    padding: 70px !important;
  }
  .p-xl-80 {
    padding: 80px !important;
  }
  .p-xl-90 {
    padding: 90px !important;
  }
  .p-xl-100 {
    padding: 100px !important;
  }
  .p-xl-180 {
    padding: 180px !important;
  }
  .p-xl-300 {
    padding: 300px !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-2 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .px-xl-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-xl-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-xl-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .px-xl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-xl-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-xl-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-xl-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-xl-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-xl-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-xl-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .px-xl-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .px-xl-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-xl-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .px-xl-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .px-xl-90 {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
  .px-xl-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .px-xl-180 {
    padding-right: 180px !important;
    padding-left: 180px !important;
  }
  .px-xl-300 {
    padding-right: 300px !important;
    padding-left: 300px !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .py-xl-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-xl-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-xl-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .py-xl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-xl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-xl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-xl-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-xl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-xl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-xl-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .py-xl-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .py-xl-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-xl-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .py-xl-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .py-xl-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .py-xl-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .py-xl-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .py-xl-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-2 {
    padding-top: 2px !important;
  }
  .pt-xl-3 {
    padding-top: 3px !important;
  }
  .pt-xl-5 {
    padding-top: 5px !important;
  }
  .pt-xl-6 {
    padding-top: 6px !important;
  }
  .pt-xl-10 {
    padding-top: 10px !important;
  }
  .pt-xl-15 {
    padding-top: 15px !important;
  }
  .pt-xl-20 {
    padding-top: 20px !important;
  }
  .pt-xl-25 {
    padding-top: 25px !important;
  }
  .pt-xl-30 {
    padding-top: 30px !important;
  }
  .pt-xl-40 {
    padding-top: 40px !important;
  }
  .pt-xl-45 {
    padding-top: 45px !important;
  }
  .pt-xl-50 {
    padding-top: 50px !important;
  }
  .pt-xl-60 {
    padding-top: 60px !important;
  }
  .pt-xl-70 {
    padding-top: 70px !important;
  }
  .pt-xl-80 {
    padding-top: 80px !important;
  }
  .pt-xl-90 {
    padding-top: 90px !important;
  }
  .pt-xl-100 {
    padding-top: 100px !important;
  }
  .pt-xl-180 {
    padding-top: 180px !important;
  }
  .pt-xl-300 {
    padding-top: 300px !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-2 {
    padding-right: 2px !important;
  }
  .pe-xl-3 {
    padding-right: 3px !important;
  }
  .pe-xl-5 {
    padding-right: 5px !important;
  }
  .pe-xl-6 {
    padding-right: 6px !important;
  }
  .pe-xl-10 {
    padding-right: 10px !important;
  }
  .pe-xl-15 {
    padding-right: 15px !important;
  }
  .pe-xl-20 {
    padding-right: 20px !important;
  }
  .pe-xl-25 {
    padding-right: 25px !important;
  }
  .pe-xl-30 {
    padding-right: 30px !important;
  }
  .pe-xl-40 {
    padding-right: 40px !important;
  }
  .pe-xl-45 {
    padding-right: 45px !important;
  }
  .pe-xl-50 {
    padding-right: 50px !important;
  }
  .pe-xl-60 {
    padding-right: 60px !important;
  }
  .pe-xl-70 {
    padding-right: 70px !important;
  }
  .pe-xl-80 {
    padding-right: 80px !important;
  }
  .pe-xl-90 {
    padding-right: 90px !important;
  }
  .pe-xl-100 {
    padding-right: 100px !important;
  }
  .pe-xl-180 {
    padding-right: 180px !important;
  }
  .pe-xl-300 {
    padding-right: 300px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-2 {
    padding-bottom: 2px !important;
  }
  .pb-xl-3 {
    padding-bottom: 3px !important;
  }
  .pb-xl-5 {
    padding-bottom: 5px !important;
  }
  .pb-xl-6 {
    padding-bottom: 6px !important;
  }
  .pb-xl-10 {
    padding-bottom: 10px !important;
  }
  .pb-xl-15 {
    padding-bottom: 15px !important;
  }
  .pb-xl-20 {
    padding-bottom: 20px !important;
  }
  .pb-xl-25 {
    padding-bottom: 25px !important;
  }
  .pb-xl-30 {
    padding-bottom: 30px !important;
  }
  .pb-xl-40 {
    padding-bottom: 40px !important;
  }
  .pb-xl-45 {
    padding-bottom: 45px !important;
  }
  .pb-xl-50 {
    padding-bottom: 50px !important;
  }
  .pb-xl-60 {
    padding-bottom: 60px !important;
  }
  .pb-xl-70 {
    padding-bottom: 70px !important;
  }
  .pb-xl-80 {
    padding-bottom: 80px !important;
  }
  .pb-xl-90 {
    padding-bottom: 90px !important;
  }
  .pb-xl-100 {
    padding-bottom: 100px !important;
  }
  .pb-xl-180 {
    padding-bottom: 180px !important;
  }
  .pb-xl-300 {
    padding-bottom: 300px !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-2 {
    padding-left: 2px !important;
  }
  .ps-xl-3 {
    padding-left: 3px !important;
  }
  .ps-xl-5 {
    padding-left: 5px !important;
  }
  .ps-xl-6 {
    padding-left: 6px !important;
  }
  .ps-xl-10 {
    padding-left: 10px !important;
  }
  .ps-xl-15 {
    padding-left: 15px !important;
  }
  .ps-xl-20 {
    padding-left: 20px !important;
  }
  .ps-xl-25 {
    padding-left: 25px !important;
  }
  .ps-xl-30 {
    padding-left: 30px !important;
  }
  .ps-xl-40 {
    padding-left: 40px !important;
  }
  .ps-xl-45 {
    padding-left: 45px !important;
  }
  .ps-xl-50 {
    padding-left: 50px !important;
  }
  .ps-xl-60 {
    padding-left: 60px !important;
  }
  .ps-xl-70 {
    padding-left: 70px !important;
  }
  .ps-xl-80 {
    padding-left: 80px !important;
  }
  .ps-xl-90 {
    padding-left: 90px !important;
  }
  .ps-xl-100 {
    padding-left: 100px !important;
  }
  .ps-xl-180 {
    padding-left: 180px !important;
  }
  .ps-xl-300 {
    padding-left: 300px !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-2 {
    margin: 2px !important;
  }
  .m-xxl-3 {
    margin: 3px !important;
  }
  .m-xxl-5 {
    margin: 5px !important;
  }
  .m-xxl-6 {
    margin: 6px !important;
  }
  .m-xxl-10 {
    margin: 10px !important;
  }
  .m-xxl-15 {
    margin: 15px !important;
  }
  .m-xxl-20 {
    margin: 20px !important;
  }
  .m-xxl-25 {
    margin: 25px !important;
  }
  .m-xxl-30 {
    margin: 30px !important;
  }
  .m-xxl-40 {
    margin: 40px !important;
  }
  .m-xxl-45 {
    margin: 45px !important;
  }
  .m-xxl-50 {
    margin: 50px !important;
  }
  .m-xxl-60 {
    margin: 60px !important;
  }
  .m-xxl-70 {
    margin: 70px !important;
  }
  .m-xxl-80 {
    margin: 80px !important;
  }
  .m-xxl-90 {
    margin: 90px !important;
  }
  .m-xxl-100 {
    margin: 100px !important;
  }
  .m-xxl-180 {
    margin: 180px !important;
  }
  .m-xxl-300 {
    margin: 300px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .mx-xxl-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-xxl-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-xxl-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .mx-xxl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-xxl-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xxl-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-xxl-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-xxl-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-xxl-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-xxl-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .mx-xxl-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .mx-xxl-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-xxl-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .mx-xxl-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-xxl-90 {
    margin-right: 90px !important;
    margin-left: 90px !important;
  }
  .mx-xxl-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .mx-xxl-180 {
    margin-right: 180px !important;
    margin-left: 180px !important;
  }
  .mx-xxl-300 {
    margin-right: 300px !important;
    margin-left: 300px !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .my-xxl-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-xxl-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-xxl-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .my-xxl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-xxl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xxl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-xxl-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-xxl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-xxl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-xxl-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .my-xxl-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .my-xxl-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-xxl-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .my-xxl-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-xxl-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .my-xxl-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .my-xxl-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .my-xxl-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-2 {
    margin-top: 2px !important;
  }
  .mt-xxl-3 {
    margin-top: 3px !important;
  }
  .mt-xxl-5 {
    margin-top: 5px !important;
  }
  .mt-xxl-6 {
    margin-top: 6px !important;
  }
  .mt-xxl-10 {
    margin-top: 10px !important;
  }
  .mt-xxl-15 {
    margin-top: 15px !important;
  }
  .mt-xxl-20 {
    margin-top: 20px !important;
  }
  .mt-xxl-25 {
    margin-top: 25px !important;
  }
  .mt-xxl-30 {
    margin-top: 30px !important;
  }
  .mt-xxl-40 {
    margin-top: 40px !important;
  }
  .mt-xxl-45 {
    margin-top: 45px !important;
  }
  .mt-xxl-50 {
    margin-top: 50px !important;
  }
  .mt-xxl-60 {
    margin-top: 60px !important;
  }
  .mt-xxl-70 {
    margin-top: 70px !important;
  }
  .mt-xxl-80 {
    margin-top: 80px !important;
  }
  .mt-xxl-90 {
    margin-top: 90px !important;
  }
  .mt-xxl-100 {
    margin-top: 100px !important;
  }
  .mt-xxl-180 {
    margin-top: 180px !important;
  }
  .mt-xxl-300 {
    margin-top: 300px !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-2 {
    margin-right: 2px !important;
  }
  .me-xxl-3 {
    margin-right: 3px !important;
  }
  .me-xxl-5 {
    margin-right: 5px !important;
  }
  .me-xxl-6 {
    margin-right: 6px !important;
  }
  .me-xxl-10 {
    margin-right: 10px !important;
  }
  .me-xxl-15 {
    margin-right: 15px !important;
  }
  .me-xxl-20 {
    margin-right: 20px !important;
  }
  .me-xxl-25 {
    margin-right: 25px !important;
  }
  .me-xxl-30 {
    margin-right: 30px !important;
  }
  .me-xxl-40 {
    margin-right: 40px !important;
  }
  .me-xxl-45 {
    margin-right: 45px !important;
  }
  .me-xxl-50 {
    margin-right: 50px !important;
  }
  .me-xxl-60 {
    margin-right: 60px !important;
  }
  .me-xxl-70 {
    margin-right: 70px !important;
  }
  .me-xxl-80 {
    margin-right: 80px !important;
  }
  .me-xxl-90 {
    margin-right: 90px !important;
  }
  .me-xxl-100 {
    margin-right: 100px !important;
  }
  .me-xxl-180 {
    margin-right: 180px !important;
  }
  .me-xxl-300 {
    margin-right: 300px !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-2 {
    margin-bottom: 2px !important;
  }
  .mb-xxl-3 {
    margin-bottom: 3px !important;
  }
  .mb-xxl-5 {
    margin-bottom: 5px !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6px !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10px !important;
  }
  .mb-xxl-15 {
    margin-bottom: 15px !important;
  }
  .mb-xxl-20 {
    margin-bottom: 20px !important;
  }
  .mb-xxl-25 {
    margin-bottom: 25px !important;
  }
  .mb-xxl-30 {
    margin-bottom: 30px !important;
  }
  .mb-xxl-40 {
    margin-bottom: 40px !important;
  }
  .mb-xxl-45 {
    margin-bottom: 45px !important;
  }
  .mb-xxl-50 {
    margin-bottom: 50px !important;
  }
  .mb-xxl-60 {
    margin-bottom: 60px !important;
  }
  .mb-xxl-70 {
    margin-bottom: 70px !important;
  }
  .mb-xxl-80 {
    margin-bottom: 80px !important;
  }
  .mb-xxl-90 {
    margin-bottom: 90px !important;
  }
  .mb-xxl-100 {
    margin-bottom: 100px !important;
  }
  .mb-xxl-180 {
    margin-bottom: 180px !important;
  }
  .mb-xxl-300 {
    margin-bottom: 300px !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-2 {
    margin-left: 2px !important;
  }
  .ms-xxl-3 {
    margin-left: 3px !important;
  }
  .ms-xxl-5 {
    margin-left: 5px !important;
  }
  .ms-xxl-6 {
    margin-left: 6px !important;
  }
  .ms-xxl-10 {
    margin-left: 10px !important;
  }
  .ms-xxl-15 {
    margin-left: 15px !important;
  }
  .ms-xxl-20 {
    margin-left: 20px !important;
  }
  .ms-xxl-25 {
    margin-left: 25px !important;
  }
  .ms-xxl-30 {
    margin-left: 30px !important;
  }
  .ms-xxl-40 {
    margin-left: 40px !important;
  }
  .ms-xxl-45 {
    margin-left: 45px !important;
  }
  .ms-xxl-50 {
    margin-left: 50px !important;
  }
  .ms-xxl-60 {
    margin-left: 60px !important;
  }
  .ms-xxl-70 {
    margin-left: 70px !important;
  }
  .ms-xxl-80 {
    margin-left: 80px !important;
  }
  .ms-xxl-90 {
    margin-left: 90px !important;
  }
  .ms-xxl-100 {
    margin-left: 100px !important;
  }
  .ms-xxl-180 {
    margin-left: 180px !important;
  }
  .ms-xxl-300 {
    margin-left: 300px !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n2 {
    margin: -2px !important;
  }
  .m-xxl-n3 {
    margin: -3px !important;
  }
  .m-xxl-n5 {
    margin: -5px !important;
  }
  .m-xxl-n6 {
    margin: -6px !important;
  }
  .m-xxl-n10 {
    margin: -10px !important;
  }
  .m-xxl-n15 {
    margin: -15px !important;
  }
  .m-xxl-n20 {
    margin: -20px !important;
  }
  .m-xxl-n25 {
    margin: -25px !important;
  }
  .m-xxl-n30 {
    margin: -30px !important;
  }
  .m-xxl-n40 {
    margin: -40px !important;
  }
  .m-xxl-n45 {
    margin: -45px !important;
  }
  .m-xxl-n50 {
    margin: -50px !important;
  }
  .m-xxl-n60 {
    margin: -60px !important;
  }
  .m-xxl-n70 {
    margin: -70px !important;
  }
  .m-xxl-n80 {
    margin: -80px !important;
  }
  .m-xxl-n90 {
    margin: -90px !important;
  }
  .m-xxl-n100 {
    margin: -100px !important;
  }
  .m-xxl-n180 {
    margin: -180px !important;
  }
  .m-xxl-n300 {
    margin: -300px !important;
  }
  .mx-xxl-n2 {
    margin-right: -2px !important;
    margin-left: -2px !important;
  }
  .mx-xxl-n3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .mx-xxl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-xxl-n6 {
    margin-right: -6px !important;
    margin-left: -6px !important;
  }
  .mx-xxl-n10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-xxl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xxl-n20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-xxl-n25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .mx-xxl-n30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .mx-xxl-n40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-xxl-n45 {
    margin-right: -45px !important;
    margin-left: -45px !important;
  }
  .mx-xxl-n50 {
    margin-right: -50px !important;
    margin-left: -50px !important;
  }
  .mx-xxl-n60 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-xxl-n70 {
    margin-right: -70px !important;
    margin-left: -70px !important;
  }
  .mx-xxl-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important;
  }
  .mx-xxl-n90 {
    margin-right: -90px !important;
    margin-left: -90px !important;
  }
  .mx-xxl-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important;
  }
  .mx-xxl-n180 {
    margin-right: -180px !important;
    margin-left: -180px !important;
  }
  .mx-xxl-n300 {
    margin-right: -300px !important;
    margin-left: -300px !important;
  }
  .my-xxl-n2 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .my-xxl-n3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .my-xxl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-xxl-n6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .my-xxl-n10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-xxl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xxl-n20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-xxl-n25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .my-xxl-n30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .my-xxl-n40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-xxl-n45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .my-xxl-n50 {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
  .my-xxl-n60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-xxl-n70 {
    margin-top: -70px !important;
    margin-bottom: -70px !important;
  }
  .my-xxl-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important;
  }
  .my-xxl-n90 {
    margin-top: -90px !important;
    margin-bottom: -90px !important;
  }
  .my-xxl-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important;
  }
  .my-xxl-n180 {
    margin-top: -180px !important;
    margin-bottom: -180px !important;
  }
  .my-xxl-n300 {
    margin-top: -300px !important;
    margin-bottom: -300px !important;
  }
  .mt-xxl-n2 {
    margin-top: -2px !important;
  }
  .mt-xxl-n3 {
    margin-top: -3px !important;
  }
  .mt-xxl-n5 {
    margin-top: -5px !important;
  }
  .mt-xxl-n6 {
    margin-top: -6px !important;
  }
  .mt-xxl-n10 {
    margin-top: -10px !important;
  }
  .mt-xxl-n15 {
    margin-top: -15px !important;
  }
  .mt-xxl-n20 {
    margin-top: -20px !important;
  }
  .mt-xxl-n25 {
    margin-top: -25px !important;
  }
  .mt-xxl-n30 {
    margin-top: -30px !important;
  }
  .mt-xxl-n40 {
    margin-top: -40px !important;
  }
  .mt-xxl-n45 {
    margin-top: -45px !important;
  }
  .mt-xxl-n50 {
    margin-top: -50px !important;
  }
  .mt-xxl-n60 {
    margin-top: -60px !important;
  }
  .mt-xxl-n70 {
    margin-top: -70px !important;
  }
  .mt-xxl-n80 {
    margin-top: -80px !important;
  }
  .mt-xxl-n90 {
    margin-top: -90px !important;
  }
  .mt-xxl-n100 {
    margin-top: -100px !important;
  }
  .mt-xxl-n180 {
    margin-top: -180px !important;
  }
  .mt-xxl-n300 {
    margin-top: -300px !important;
  }
  .me-xxl-n2 {
    margin-right: -2px !important;
  }
  .me-xxl-n3 {
    margin-right: -3px !important;
  }
  .me-xxl-n5 {
    margin-right: -5px !important;
  }
  .me-xxl-n6 {
    margin-right: -6px !important;
  }
  .me-xxl-n10 {
    margin-right: -10px !important;
  }
  .me-xxl-n15 {
    margin-right: -15px !important;
  }
  .me-xxl-n20 {
    margin-right: -20px !important;
  }
  .me-xxl-n25 {
    margin-right: -25px !important;
  }
  .me-xxl-n30 {
    margin-right: -30px !important;
  }
  .me-xxl-n40 {
    margin-right: -40px !important;
  }
  .me-xxl-n45 {
    margin-right: -45px !important;
  }
  .me-xxl-n50 {
    margin-right: -50px !important;
  }
  .me-xxl-n60 {
    margin-right: -60px !important;
  }
  .me-xxl-n70 {
    margin-right: -70px !important;
  }
  .me-xxl-n80 {
    margin-right: -80px !important;
  }
  .me-xxl-n90 {
    margin-right: -90px !important;
  }
  .me-xxl-n100 {
    margin-right: -100px !important;
  }
  .me-xxl-n180 {
    margin-right: -180px !important;
  }
  .me-xxl-n300 {
    margin-right: -300px !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -2px !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -3px !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -5px !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -6px !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -10px !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -15px !important;
  }
  .mb-xxl-n20 {
    margin-bottom: -20px !important;
  }
  .mb-xxl-n25 {
    margin-bottom: -25px !important;
  }
  .mb-xxl-n30 {
    margin-bottom: -30px !important;
  }
  .mb-xxl-n40 {
    margin-bottom: -40px !important;
  }
  .mb-xxl-n45 {
    margin-bottom: -45px !important;
  }
  .mb-xxl-n50 {
    margin-bottom: -50px !important;
  }
  .mb-xxl-n60 {
    margin-bottom: -60px !important;
  }
  .mb-xxl-n70 {
    margin-bottom: -70px !important;
  }
  .mb-xxl-n80 {
    margin-bottom: -80px !important;
  }
  .mb-xxl-n90 {
    margin-bottom: -90px !important;
  }
  .mb-xxl-n100 {
    margin-bottom: -100px !important;
  }
  .mb-xxl-n180 {
    margin-bottom: -180px !important;
  }
  .mb-xxl-n300 {
    margin-bottom: -300px !important;
  }
  .ms-xxl-n2 {
    margin-left: -2px !important;
  }
  .ms-xxl-n3 {
    margin-left: -3px !important;
  }
  .ms-xxl-n5 {
    margin-left: -5px !important;
  }
  .ms-xxl-n6 {
    margin-left: -6px !important;
  }
  .ms-xxl-n10 {
    margin-left: -10px !important;
  }
  .ms-xxl-n15 {
    margin-left: -15px !important;
  }
  .ms-xxl-n20 {
    margin-left: -20px !important;
  }
  .ms-xxl-n25 {
    margin-left: -25px !important;
  }
  .ms-xxl-n30 {
    margin-left: -30px !important;
  }
  .ms-xxl-n40 {
    margin-left: -40px !important;
  }
  .ms-xxl-n45 {
    margin-left: -45px !important;
  }
  .ms-xxl-n50 {
    margin-left: -50px !important;
  }
  .ms-xxl-n60 {
    margin-left: -60px !important;
  }
  .ms-xxl-n70 {
    margin-left: -70px !important;
  }
  .ms-xxl-n80 {
    margin-left: -80px !important;
  }
  .ms-xxl-n90 {
    margin-left: -90px !important;
  }
  .ms-xxl-n100 {
    margin-left: -100px !important;
  }
  .ms-xxl-n180 {
    margin-left: -180px !important;
  }
  .ms-xxl-n300 {
    margin-left: -300px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-2 {
    padding: 2px !important;
  }
  .p-xxl-3 {
    padding: 3px !important;
  }
  .p-xxl-5 {
    padding: 5px !important;
  }
  .p-xxl-6 {
    padding: 6px !important;
  }
  .p-xxl-10 {
    padding: 10px !important;
  }
  .p-xxl-15 {
    padding: 15px !important;
  }
  .p-xxl-20 {
    padding: 20px !important;
  }
  .p-xxl-25 {
    padding: 25px !important;
  }
  .p-xxl-30 {
    padding: 30px !important;
  }
  .p-xxl-40 {
    padding: 40px !important;
  }
  .p-xxl-45 {
    padding: 45px !important;
  }
  .p-xxl-50 {
    padding: 50px !important;
  }
  .p-xxl-60 {
    padding: 60px !important;
  }
  .p-xxl-70 {
    padding: 70px !important;
  }
  .p-xxl-80 {
    padding: 80px !important;
  }
  .p-xxl-90 {
    padding: 90px !important;
  }
  .p-xxl-100 {
    padding: 100px !important;
  }
  .p-xxl-180 {
    padding: 180px !important;
  }
  .p-xxl-300 {
    padding: 300px !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-2 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .px-xxl-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-xxl-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-xxl-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .px-xxl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-xxl-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-xxl-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-xxl-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-xxl-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-xxl-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-xxl-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .px-xxl-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .px-xxl-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-xxl-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .px-xxl-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .px-xxl-90 {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
  .px-xxl-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .px-xxl-180 {
    padding-right: 180px !important;
    padding-left: 180px !important;
  }
  .px-xxl-300 {
    padding-right: 300px !important;
    padding-left: 300px !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .py-xxl-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-xxl-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-xxl-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .py-xxl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-xxl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-xxl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-xxl-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-xxl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-xxl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-xxl-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .py-xxl-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .py-xxl-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-xxl-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .py-xxl-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .py-xxl-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .py-xxl-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .py-xxl-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .py-xxl-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-2 {
    padding-top: 2px !important;
  }
  .pt-xxl-3 {
    padding-top: 3px !important;
  }
  .pt-xxl-5 {
    padding-top: 5px !important;
  }
  .pt-xxl-6 {
    padding-top: 6px !important;
  }
  .pt-xxl-10 {
    padding-top: 10px !important;
  }
  .pt-xxl-15 {
    padding-top: 15px !important;
  }
  .pt-xxl-20 {
    padding-top: 20px !important;
  }
  .pt-xxl-25 {
    padding-top: 25px !important;
  }
  .pt-xxl-30 {
    padding-top: 30px !important;
  }
  .pt-xxl-40 {
    padding-top: 40px !important;
  }
  .pt-xxl-45 {
    padding-top: 45px !important;
  }
  .pt-xxl-50 {
    padding-top: 50px !important;
  }
  .pt-xxl-60 {
    padding-top: 60px !important;
  }
  .pt-xxl-70 {
    padding-top: 70px !important;
  }
  .pt-xxl-80 {
    padding-top: 80px !important;
  }
  .pt-xxl-90 {
    padding-top: 90px !important;
  }
  .pt-xxl-100 {
    padding-top: 100px !important;
  }
  .pt-xxl-180 {
    padding-top: 180px !important;
  }
  .pt-xxl-300 {
    padding-top: 300px !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-2 {
    padding-right: 2px !important;
  }
  .pe-xxl-3 {
    padding-right: 3px !important;
  }
  .pe-xxl-5 {
    padding-right: 5px !important;
  }
  .pe-xxl-6 {
    padding-right: 6px !important;
  }
  .pe-xxl-10 {
    padding-right: 10px !important;
  }
  .pe-xxl-15 {
    padding-right: 15px !important;
  }
  .pe-xxl-20 {
    padding-right: 20px !important;
  }
  .pe-xxl-25 {
    padding-right: 25px !important;
  }
  .pe-xxl-30 {
    padding-right: 30px !important;
  }
  .pe-xxl-40 {
    padding-right: 40px !important;
  }
  .pe-xxl-45 {
    padding-right: 45px !important;
  }
  .pe-xxl-50 {
    padding-right: 50px !important;
  }
  .pe-xxl-60 {
    padding-right: 60px !important;
  }
  .pe-xxl-70 {
    padding-right: 70px !important;
  }
  .pe-xxl-80 {
    padding-right: 80px !important;
  }
  .pe-xxl-90 {
    padding-right: 90px !important;
  }
  .pe-xxl-100 {
    padding-right: 100px !important;
  }
  .pe-xxl-180 {
    padding-right: 180px !important;
  }
  .pe-xxl-300 {
    padding-right: 300px !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-2 {
    padding-bottom: 2px !important;
  }
  .pb-xxl-3 {
    padding-bottom: 3px !important;
  }
  .pb-xxl-5 {
    padding-bottom: 5px !important;
  }
  .pb-xxl-6 {
    padding-bottom: 6px !important;
  }
  .pb-xxl-10 {
    padding-bottom: 10px !important;
  }
  .pb-xxl-15 {
    padding-bottom: 15px !important;
  }
  .pb-xxl-20 {
    padding-bottom: 20px !important;
  }
  .pb-xxl-25 {
    padding-bottom: 25px !important;
  }
  .pb-xxl-30 {
    padding-bottom: 30px !important;
  }
  .pb-xxl-40 {
    padding-bottom: 40px !important;
  }
  .pb-xxl-45 {
    padding-bottom: 45px !important;
  }
  .pb-xxl-50 {
    padding-bottom: 50px !important;
  }
  .pb-xxl-60 {
    padding-bottom: 60px !important;
  }
  .pb-xxl-70 {
    padding-bottom: 70px !important;
  }
  .pb-xxl-80 {
    padding-bottom: 80px !important;
  }
  .pb-xxl-90 {
    padding-bottom: 90px !important;
  }
  .pb-xxl-100 {
    padding-bottom: 100px !important;
  }
  .pb-xxl-180 {
    padding-bottom: 180px !important;
  }
  .pb-xxl-300 {
    padding-bottom: 300px !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-2 {
    padding-left: 2px !important;
  }
  .ps-xxl-3 {
    padding-left: 3px !important;
  }
  .ps-xxl-5 {
    padding-left: 5px !important;
  }
  .ps-xxl-6 {
    padding-left: 6px !important;
  }
  .ps-xxl-10 {
    padding-left: 10px !important;
  }
  .ps-xxl-15 {
    padding-left: 15px !important;
  }
  .ps-xxl-20 {
    padding-left: 20px !important;
  }
  .ps-xxl-25 {
    padding-left: 25px !important;
  }
  .ps-xxl-30 {
    padding-left: 30px !important;
  }
  .ps-xxl-40 {
    padding-left: 40px !important;
  }
  .ps-xxl-45 {
    padding-left: 45px !important;
  }
  .ps-xxl-50 {
    padding-left: 50px !important;
  }
  .ps-xxl-60 {
    padding-left: 60px !important;
  }
  .ps-xxl-70 {
    padding-left: 70px !important;
  }
  .ps-xxl-80 {
    padding-left: 80px !important;
  }
  .ps-xxl-90 {
    padding-left: 90px !important;
  }
  .ps-xxl-100 {
    padding-left: 100px !important;
  }
  .ps-xxl-180 {
    padding-left: 180px !important;
  }
  .ps-xxl-300 {
    padding-left: 300px !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.floating-header {
  background-image: linear-gradient(101deg, #005ad7, #005ad7 100%);
  height: 325px;
  position: absolute;
  right: 0;
  top: 60px;
  width: 100%;
  z-index: -1;
}
@media (min-width: 576px) {
  .floating-header {
    top: 60px;
  }
}
@media (min-width: 768px) {
  .floating-header {
    top: 60px;
  }
}
@media (min-width: 992px) {
  .floating-header {
    top: 72px;
  }
}
@media (min-width: 1200px) {
  .floating-header {
    top: 72px;
  }
}

.positive-500 {
  background-color: var(--positive-500);
}

.gray-300 {
  background-color: var(--gray-300);
}

@font-face {
  font-weight: 400;
  font-display: swap;
  font-family: "Nunito";
  src: local("Nunito"), url("../../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}
@font-face {
  font-weight: 800;
  font-display: swap;
  font-family: "Nunito";
  src: local("Nunito"), url("../../fonts/Nunito/Nunito-Extra-Bold.ttf") format("truetype");
}
iframe.hidden-iframe {
  display: none;
  height: 0;
  visibility: hidden;
  width: 0;
}

@media (max-width: 575px) {
  img.responsive-img {
    width: 100%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@page {
  margin: 0;
  size: auto;
}
@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    page-break-after: always;
  }
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  font-weight: 400;
  background-color: var(--bg-content);
  color: var(--white);
  font-family: "Nunito", Arial, sans-serif;
  font-feature-settings: "lnum";
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  position: relative;
}
body.splash-screen {
  overflow: hidden;
  position: relative;
}
body.splash-screen::before {
  background: url("/assets/images/icons/assets/logo.svg") no-repeat center;
  background-color: var(--gray-50);
  content: "";
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 1000000;
}
@media (max-width: 767px) {
  body {
    font-size: 16px;
  }
}
body.is-menu-opened {
  height: 100vh;
  overflow: hidden;
}
body.white {
  background-color: var(--white);
}
@media print {
  body {
    background-color: var(--white);
  }
  body * {
    visibility: hidden;
  }
  body .section-to-print {
    left: 0;
    position: absolute;
    top: 0;
    visibility: visible;
  }
  body .section-to-print * {
    visibility: visible;
  }
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

p {
  margin: 0;
}

label {
  display: inline-block;
  margin: 0;
}

input,
textarea,
select,
a {
  outline: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
a:not([href]) {
  color: inherit;
}

.content-wrapper {
  z-index: 1;
}

.separator-black-700, .separator-blue-500, .separator-gray-900, .separator-gray-300, .separator-gray-100 {
  height: 1px;
  width: 100%;
}

.separator-gray-100 {
  background-color: var(--gray-100);
}

.separator-gray-300 {
  background-color: var(--gray-300);
}

.separator-gray-900 {
  background-color: var(--gray-900);
}

.separator-blue-500 {
  background-color: var(--primary-700);
  height: 2px;
}

.separator-black-700 {
  background-color: var(--black-700);
}

.separator-2 {
  height: 2px;
}

.separator-vertical {
  background-color: var(--gray-100);
  height: 100%;
  width: 1px;
}

.d-full-viewport-height {
  height: 100vh;
}

.d-min-viewport-height {
  min-height: 100vh;
}

.logo-img {
  height: 30px;
}

.dark-wrapper {
  background-color: var(--bg-menu-left);
}

.light-wrapper {
  background-color: var(--gray-50);
}

i {
  align-self: center;
  display: flex;
}

:focus {
  outline: 0;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  border: solid 1px var(--gray-300);
  border-radius: 10px;
  font-size: 14px;
  height: 150px;
  line-height: normal;
  overflow: auto;
  padding: 15px 20px;
  resize: none;
  transition: border-color 0.5s, box-shadow 0.5s;
  width: 100%;
}
textarea::placeholder {
  font-weight: 400;
  color: var(--gray-300);
  font-size: 14px;
  line-height: normal;
}
@media (max-width: 767px) {
  textarea::placeholder {
    font-size: 16px;
  }
}
textarea:focus {
  border-color: var(--primary-700);
  box-shadow: 0 0 0 1px var(--primary-700);
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[hidden] {
  display: none !important;
}

.hide-value {
  filter: blur(7px);
}

.visibility-hidden {
  visibility: hidden;
}

.z-index-100 {
  z-index: 100;
}

.maintenance-absolute {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  right: 0;
  top: 120px;
  z-index: 10;
}
.maintenance-absolute .info-box {
  box-shadow: none;
}

.ultimate-chat-pending-transactions {
  bottom: 45px !important;
  right: 6px !important;
}

.ultimate-chat-opened {
  z-index: 12000 !important;
}

.ultimate-chat-hidden {
  right: -10000px !important;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

@media (min-width: 0) {
  .text-start-xs {
    text-align: left;
  }
  .text-end-xs {
    text-align: right;
  }
  .text-center-xs {
    text-align: center;
  }
}
@media (min-width: 576px) {
  .text-start-sm {
    text-align: left;
  }
  .text-end-sm {
    text-align: right;
  }
  .text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .text-start-md {
    text-align: left;
  }
  .text-end-md {
    text-align: right;
  }
  .text-center-md {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .text-start-lg {
    text-align: left;
  }
  .text-end-lg {
    text-align: right;
  }
  .text-center-lg {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .text-start-xl {
    text-align: left;
  }
  .text-end-xl {
    text-align: right;
  }
  .text-center-xl {
    text-align: center;
  }
}
.h1-white, .h1-black {
  font-weight: 800;
  font-size: 56px;
}

.h1-black {
  color: var(--black-900);
}
.h1-white {
  color: var(--white);
}

.h1-lander-white, .h1-lander-black {
  font-family: Nunito;
  font-size: 56px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 64px;
}
@media (max-width: 991px) {
  .h1-lander-white, .h1-lander-black {
    font-size: 34px;
    line-height: 1.2;
  }
}

.h1-lander-black {
  color: var(--black-900);
}
.h1-lander-white {
  color: var(--white);
}

.h2-white, .h2-black {
  font-weight: 800;
  font-size: 32px;
}

.h2-black {
  color: var(--black-900);
}
.h2-white {
  color: var(--white);
}

.h2-lander-white, .h2-lander-black {
  font-family: Nunito;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 56px;
}
@media (max-width: 991px) {
  .h2-lander-white, .h2-lander-black {
    font-size: 34px;
    line-height: 1.2;
  }
}

.h2-lander-black {
  color: var(--black-900);
}
.h2-lander-white {
  color: var(--white);
}

.h3-primary-500, .h3-white, .h3-black {
  font-weight: 800;
  font-size: 22px;
}

.h3-lander-white, .h3-lander-black {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}
@media (max-width: 991px) {
  .h3-lander-white, .h3-lander-black {
    font-size: 22px;
    line-height: 1.2;
  }
}

.h3-black {
  color: var(--black-900);
}
.h3-white {
  color: var(--white);
}
.h3-primary-500 {
  color: var(--primary-700);
}

.h3-lander-black {
  color: var(--black-900);
}
.h3-lander-white {
  color: var(--white);
}

.h4-gray-900, .h4-white, .h4-black-bold, .h4-black {
  font-weight: 800;
  font-size: 18px;
}

.h4-black {
  color: var(--black-900);
}
.h4-black-bold {
  font-weight: 800;
  color: var(--black-900);
}
.h4-white {
  color: var(--white);
}
.h4-gray-900 {
  color: var(--gray-900);
}

.h5-gray, .h5-primary-regular, .h5-primary, .h5-white, .h5-black {
  font-weight: 800;
  font-size: 16px;
}

.h5-black {
  color: var(--black-900);
}
.h5-white {
  color: var(--white);
}
.h5-primary {
  color: var(--primary-700);
}
.h5-primary-regular {
  font-weight: 400;
  color: var(--primary-700);
}
.h5-gray {
  font-weight: 400;
  color: var(--gray-900);
}

.h6-white, .h6-black {
  font-weight: 800;
  font-size: 14px;
}

.h6-black {
  color: var(--black-900);
}
.h6-white {
  color: var(--white);
}

.status-indicator-big, .paragraph-black-bold, .paragraph-black-regular, .lander-paragraph-black-bold, .lander-paragraph-black-regular {
  color: var(--black-900);
}

.paragraph-black-300-regular {
  color: var(--black-300);
}

.paragraph-white-bold, .paragraph-white-regular, .lander-paragraph-white-bold, .lander-paragraph-white-regular {
  color: var(--white);
}

.paragraph-gray-300-bold, .paragraph-gray-300-regular {
  color: var(--gray-300);
}

.paragraph-gray-500-bold, .paragraph-gray-500-regular {
  color: var(--gray-500);
}

.paragraph-gray-900-bold, .paragraph-gray-900-regular, .lander-paragraph-gray-900-bold, .lander-paragraph-gray-900-regular {
  color: var(--gray-900);
}

.paragraph-primary-bold, .paragraph-primary-regular {
  color: var(--primary-700);
}

.label-secondary-regular, .paragraph-secondary-bold, .paragraph-secondary-regular {
  color: var(--secondary-500);
}

.label-negative-regular {
  color: var(--negative-500);
}

.paragraph-error-bold, .paragraph-error-regular {
  color: var(--error-500);
}

.label-positive-regular {
  color: var(--positive-500);
}

.paragraph-primary-700-business-bold, .paragraph-primary-700-business-regular {
  color: var(--primary-700-business);
}

.paragraph-primary-700-business-regular, .paragraph-error-regular, .paragraph-secondary-regular, .paragraph-primary-regular, .paragraph-gray-900-regular, .paragraph-gray-500-regular, .paragraph-gray-300-regular, .paragraph-white-regular, .paragraph-black-300-regular, .paragraph-black-regular, .lander-paragraph-gray-900-regular, .lander-paragraph-white-regular, .lander-paragraph-black-regular {
  font-weight: 400;
}

.status-indicator-big, .paragraph-primary-700-business-bold, .paragraph-error-bold, .paragraph-secondary-bold, .paragraph-primary-bold, .paragraph-gray-900-bold, .paragraph-gray-500-bold, .paragraph-gray-300-bold, .paragraph-white-bold, .paragraph-black-bold {
  font-weight: 800;
}

.status-indicator-big, .paragraph-primary-700-business-bold, .paragraph-primary-700-business-regular, .paragraph-error-bold, .paragraph-error-regular, .paragraph-secondary-bold, .paragraph-secondary-regular, .paragraph-primary-bold, .paragraph-primary-regular, .paragraph-gray-900-bold, .paragraph-gray-900-regular, .paragraph-gray-500-bold, .paragraph-gray-500-regular, .paragraph-gray-300-bold, .paragraph-gray-300-regular, .paragraph-white-bold, .paragraph-white-regular, .paragraph-black-300-regular, .paragraph-black-bold, .paragraph-black-regular {
  font-size: 14px;
}

.lander-paragraph-gray-900-bold, .lander-paragraph-gray-900-regular, .lander-paragraph-white-bold, .lander-paragraph-white-regular, .lander-paragraph-black-bold, .lander-paragraph-black-regular {
  font-size: 16px;
}

.lander-paragraph-black-bold {
  font-weight: 800;
}
.lander-paragraph-white-bold {
  font-weight: 800;
}
.lander-paragraph-gray-900-bold {
  font-weight: 800;
}

.status-indicator-small, .lander-label-black-bold, .lander-label-black-regular, .label-black-bold, .label-black-regular {
  color: var(--black-900);
}

.label-black-300-bold, .label-black-300-regular {
  color: var(--black-300);
}

.lander-label-white-bold, .lander-label-white-regular, .label-white-bold, .label-white-regular {
  color: var(--white);
}

.label-gray-300-bold, .label-gray-300-regular {
  color: var(--gray-300);
}

.label-gray-500-bold, .label-gray-500-regular {
  color: var(--gray-500);
}

.label-primary-700-bold, .label-primary-700-regular {
  color: var(--primary-700);
}

.lander-label-gray-900-bold, .lander-label-gray-900-regular, .label-gray-900-bold, .label-gray-900-regular {
  color: var(--gray-900);
}

.label-error-bold, .label-error-regular {
  color: var(--error-500);
}

.status-indicator-small, .lander-label-white-regular, .lander-label-gray-900-regular, .lander-label-black-regular, .label-secondary-regular, .label-negative-regular, .label-positive-regular, .label-error-regular, .label-primary-700-regular, .label-gray-900-regular, .label-gray-500-regular, .label-gray-300-regular, .label-white-regular, .label-black-300-regular, .label-black-regular {
  font-weight: 400;
}

.lander-label-white-bold, .lander-label-gray-900-bold, .lander-label-black-bold, .label-primary-bold, .label-error-bold, .label-primary-700-bold, .label-gray-900-bold, .label-gray-500-bold, .label-gray-300-bold, .label-white-bold, .label-black-300-bold, .label-black-bold {
  font-weight: 800;
}

.status-indicator-small, .label-secondary-regular, .label-primary-bold, .label-negative-regular, .label-positive-regular, .label-error-bold, .label-error-regular, .label-primary-700-bold, .label-primary-700-regular, .label-gray-900-bold, .label-gray-900-regular, .label-gray-500-bold, .label-gray-500-regular, .label-gray-300-bold, .label-gray-300-regular, .label-white-bold, .label-white-regular, .label-black-300-bold, .label-black-300-regular, .label-black-bold, .label-black-regular {
  font-size: 12px;
}

.lander-label-white-bold, .lander-label-white-regular, .lander-label-gray-900-bold, .lander-label-gray-900-regular, .lander-label-black-bold, .lander-label-black-regular {
  font-size: 14px;
}

.label-primary-bold {
  color: var(--primary-700);
}
.label-info-icon::before {
  align-self: center;
  margin-right: 5px;
}
.link-small-primary-business-bold, .link-small-primary-business-regular, .link-small-white-bold, .link-small-white-regular, .link-small-secondary-bold, .link-small-secondary-regular, .link-small-primary-bold, .link-small-primary-regular {
  cursor: pointer;
  font-size: 12px;
  transition: transform 0.2s;
}

.link-big-white-bold, .link-big-white-regular, .link-big-secondary-bold, .link-big-secondary-regular, .link-big-primary-bold, .link-big-primary-regular, .lander-link-big-primary-regular {
  cursor: pointer;
  font-size: 14px;
  transition: transform 0.2s;
}

.link-big-primary-bold, .link-big-primary-regular, .lander-link-big-primary-regular, .link-small-primary-bold, .link-small-primary-regular {
  color: var(--primary-700);
}
.link-big-primary-bold:visited, .link-big-primary-regular:visited, .lander-link-big-primary-regular:visited, .link-small-primary-bold:visited, .link-small-primary-regular:visited {
  color: var(--primary-700);
}
.link-big-primary-bold:hover, .link-big-primary-regular:hover, .lander-link-big-primary-regular:hover, .link-small-primary-bold:hover, .link-small-primary-regular:hover, .link-big-primary-bold:focus, .link-big-primary-regular:focus, .lander-link-big-primary-regular:focus, .link-small-primary-bold:focus, .link-small-primary-regular:focus {
  color: var(--primary-900);
  text-decoration: underline;
}

.link-big-secondary-bold, .link-big-secondary-regular, .link-small-secondary-bold, .link-small-secondary-regular {
  color: var(--secondary-500);
}
.link-big-secondary-bold:visited, .link-big-secondary-regular:visited, .link-small-secondary-bold:visited, .link-small-secondary-regular:visited {
  color: var(--secondary-500);
}
.link-big-secondary-bold:hover, .link-big-secondary-regular:hover, .link-small-secondary-bold:hover, .link-small-secondary-regular:hover, .link-big-secondary-bold:focus, .link-big-secondary-regular:focus, .link-small-secondary-bold:focus, .link-small-secondary-regular:focus {
  color: var(--secondary-900);
  text-decoration: underline;
}

.link-big-white-bold, .link-big-white-regular, .link-small-white-bold, .link-small-white-regular {
  color: var(--white);
  text-decoration: underline;
}
.link-big-white-bold:visited, .link-big-white-regular:visited, .link-small-white-bold:visited, .link-small-white-regular:visited {
  color: var(--white);
}
.link-big-white-bold:hover, .link-big-white-regular:hover, .link-small-white-bold:hover, .link-small-white-regular:hover, .link-big-white-bold:focus, .link-big-white-regular:focus, .link-small-white-bold:focus, .link-small-white-regular:focus {
  color: var(--white);
}

.link-big-white-regular, .link-big-secondary-regular, .link-big-primary-regular, .lander-link-big-primary-regular, .link-small-primary-business-regular, .link-small-white-regular, .link-small-secondary-regular, .link-small-primary-regular {
  font-weight: 400;
}

.link-big-white-bold, .link-big-secondary-bold, .link-big-primary-bold, .link-small-primary-business-bold, .link-small-white-bold, .link-small-secondary-bold, .link-small-primary-bold {
  font-weight: 800;
}

.link-small-primary-business-bold, .link-small-primary-business-regular {
  color: var(--primary-700-business);
  text-decoration: underline;
}
.link-small-primary-business-bold:visited, .link-small-primary-business-regular:visited {
  color: var(--primary-700-business);
}
.link-small-primary-business-bold:hover, .link-small-primary-business-regular:hover, .link-small-primary-business-bold:focus, .link-small-primary-business-regular:focus {
  color: var(--primary-500-business);
}

.lander-link-big-primary-regular {
  font-size: 16px;
}

.link-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.performance-big-neutral, .performance-big-negative, .performance-big-positive, .performance-big {
  font-weight: 800;
  color: var(--black-900);
  font-size: 22px;
}

.performance-medium-neutral, .performance-medium-negative, .performance-medium-positive, .performance-medium {
  font-weight: 800;
  color: var(--black-900);
  font-size: 16px;
}

.performance-small-gray-900, .performance-small-white, .performance-small-neutral, .performance-small-negative, .performance-small-positive, .performance-small {
  font-weight: 800;
  color: var(--black-900);
  font-size: 14px;
}

.performance-extra-small-gray-900, .performance-extra-small-white, .performance-extra-small-neutral, .performance-extra-small-negative, .performance-extra-small-positive, .performance-extra-small {
  font-weight: 800;
  color: var(--black-900);
  font-size: 12px;
}

.performance-big-positive {
  color: var(--positive-500);
}
.performance-big-negative {
  color: var(--negative-500);
}
.performance-big-neutral {
  color: var(--black-900);
}

.performance-medium-positive {
  color: var(--positive-500);
}
.performance-medium-negative {
  color: var(--negative-500);
}
.performance-medium-neutral {
  color: var(--black-900);
}

.performance-small-positive {
  color: var(--positive-500);
  font-weight: 400;
}
.performance-small-negative {
  color: var(--negative-500);
  font-weight: 400;
}
.performance-small-neutral {
  color: var(--black-900);
  font-weight: 400;
}
.performance-small-white {
  color: var(--white);
}
.performance-small-gray-900 {
  color: var(--gray-900);
}

.performance-extra-small-positive {
  color: var(--positive-500);
  font-weight: 400;
}
.performance-extra-small-negative {
  color: var(--negative-500);
  font-weight: 400;
}
.performance-extra-small-neutral {
  color: var(--black-900);
  font-weight: 400;
}
.performance-extra-small-white {
  color: var(--white);
}
.performance-extra-small-gray-900 {
  color: var(--gray-900);
}

.status-indicator-big::before, .status-indicator-small::before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 10px;
  margin-right: 10px;
  width: 10px;
}

.status-indicator-small.green::before {
  background: var(--positive-500);
}
.status-indicator-small.orange::before {
  background: var(--warning-500);
}
.status-indicator-small.red::before {
  background: var(--negative-500);
}
.status-indicator-small.gray::before {
  background: var(--gray-500);
}
.status-indicator-big.green::before {
  background: var(--positive-500);
}
.status-indicator-big.orange::before {
  background: var(--warning-500);
}
.status-indicator-big.red::before {
  background: var(--negative-500);
}
.status-indicator-big.gray::before {
  background: var(--gray-500);
}

.fs-8 {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.word-break-all {
  word-break: break-all;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.color-black {
  color: var(--black-900);
}

.color-gray-50 {
  color: var(--gray-50);
}

.color-gray-100 {
  color: var(--gray-100);
}

.color-gray-300 {
  color: var(--gray-300);
}

.color-gray-500 {
  color: var(--gray-500);
}

.color-gray-900 {
  color: var(--gray-900);
}

.color-secondary-500 {
  color: var(--secondary-500);
}

.color-primary-500 {
  color: var(--primary-700);
}

.color-primary-100 {
  color: var(--primary-100);
}

.color-negative-500 {
  color: var(--negative-500);
}

.color-error-500 {
  color: var(--error-500);
}

.color-warning-500 {
  color: var(--warning-500);
}

.color-warning-900 {
  color: var(--warning-900);
}

.color-positive-500 {
  color: var(--positive-500);
}

.color-white {
  color: var(--white);
}

.color-gold-500 {
  color: var(--gold-500);
}

.color-orange {
  color: var(--orange-300);
}

.color-primary-700-business {
  color: var(--primary-700-business);
}

.text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-preline {
  white-space: pre-line;
}

.text-decoration-none {
  text-decoration: none;
}
.text-decoration-none:hover {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

b,
.font-weight-bold {
  font-weight: 800;
}

[class^=lander-paragraph-] a,
[class^=paragraph-] a {
  color: var(--primary-700);
}

.line-height-1 {
  line-height: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

@media (min-width: 0) {
  .container {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .container {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 1920px) {
  .container.logged-in {
    margin-left: 15px;
  }
}
@media (min-width: 0) {
  .container.container-xs-align-left {
    margin-left: 15px;
  }
}
@media (min-width: 576px) {
  .container.container-sm-align-left {
    margin-left: 15px;
  }
}
@media (min-width: 768px) {
  .container.container-md-align-left {
    margin-left: 15px;
  }
}
@media (min-width: 992px) {
  .container.container-lg-align-left {
    margin-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container.container-xl-align-left {
    margin-left: 15px;
  }
}
@media (min-width: 1400px) {
  .container.container-xxl-align-left {
    margin-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container.lander {
    max-width: 1400px;
  }
}

@media (min-width: 0) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: -1) {
  .container-fluid-xs {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .container-fluid-sm {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .container-fluid-md {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .container-fluid-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .container-fluid-xl {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

@media (max-width: 1399px) {
  .container-fluid-xxl {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

.row > * {
  flex-shrink: unset;
}
@media (min-width: 0) {
  .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
@media (min-width: 768px) {
  .row {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

@media (min-width: 0) {
  .w-xs-auto {
    width: auto !important;
  }
}

@media (min-width: 0) {
  .w-xs-100 {
    width: 100% !important;
  }
}

@media (min-width: 0) {
  .w-xs-50 {
    width: 50% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    width: 50% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-auto {
    width: auto !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-100 {
    width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-50 {
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}
.flex-auto {
  flex: 0 0 auto;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.shake-animation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, 0);
  }
}
.floating {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: floating;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

.btn {
  font-weight: 800;
  background-color: var(--gray-500);
  border: 1px solid transparent;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  min-height: 35px;
  padding: 0 20px;
  text-align: center;
  transition: transform 0.2s;
  user-select: none;
  vertical-align: middle;
}
a.btn {
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
a.btn:hover, a.btn:focus, a.btn.focus {
  color: var(--white);
}

.btn:focus, .btn.focus {
  box-shadow: none;
  outline: 0;
}
.btn:hover:not(:disabled):not(.disabled), .btn:focus:not(:disabled):not(.disabled) {
  background-color: var(--gray-900);
}
.btn.disabled, .btn:disabled {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled).active:focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn.btn-loading {
  background-image: url("/assets/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  color: transparent !important;
}

.btn-primary {
  background-color: var(--primary-500);
  color: var(--white);
}
.btn-primary:hover:not(:disabled):not(.disabled), .btn-primary:focus:not(:disabled):not(.disabled) {
  background-color: var(--primary-900);
}
.btn-primary.disabled, .btn-primary:disabled {
  opacity: 0.5;
}
a.btn-primary {
  color: var(--white);
}
a.btn-primary:hover, a.btn-primary:focus, a.btn-primary.focus {
  color: var(--white);
}

.btn-primary-50 {
  background-color: var(--primary-50);
  color: var(--primary-700);
}
.btn-primary-50:hover:not(:disabled):not(.disabled), .btn-primary-50:focus:not(:disabled):not(.disabled) {
  background-color: var(--primary-100);
}
.btn-primary-50.disabled, .btn-primary-50:disabled {
  opacity: 0.5;
}
a.btn-primary-50 {
  color: var(--primary-700);
}
a.btn-primary-50:hover, a.btn-primary-50:focus, a.btn-primary-50.focus {
  color: var(--primary-700);
}

.btn-secondary {
  background-color: var(--positive-500);
  color: var(--white);
}
.btn-secondary:hover:not(:disabled):not(.disabled), .btn-secondary:focus:not(:disabled):not(.disabled) {
  background-color: var(--secondary-900);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  opacity: 0.5;
}
a.btn-secondary {
  color: var(--white);
}
a.btn-secondary:hover, a.btn-secondary:focus, a.btn-secondary.focus {
  color: var(--white);
}

.btn-secondary-50 {
  background-color: var(--secondary-50);
  color: var(--secondary-500);
}
.btn-secondary-50:hover:not(:disabled):not(.disabled), .btn-secondary-50:focus:not(:disabled):not(.disabled) {
  background-color: var(--secondary-100);
}
.btn-secondary-50.disabled, .btn-secondary-50:disabled {
  opacity: 0.5;
}
a.btn-secondary-50 {
  color: var(--secondary-500);
}
a.btn-secondary-50:hover, a.btn-secondary-50:focus, a.btn-secondary-50.focus {
  color: var(--secondary-500);
}

.btn-black {
  background-color: var(--black-900);
  color: var(--white);
}
.btn-black:hover:not(:disabled):not(.disabled), .btn-black:focus:not(:disabled):not(.disabled) {
  background-color: var(--black-900);
}
.btn-black.disabled, .btn-black:disabled {
  opacity: 0.5;
}
a.btn-black {
  color: var(--white);
}
a.btn-black:hover, a.btn-black:focus, a.btn-black.focus {
  color: var(--white);
}

.btn-gray {
  background-color: var(--gray-500);
  color: var(--white);
}
.btn-gray:hover:not(:disabled):not(.disabled), .btn-gray:focus:not(:disabled):not(.disabled) {
  background-color: var(--gray-900);
}
.btn-gray.disabled, .btn-gray:disabled {
  opacity: 0.5;
}
a.btn-gray {
  color: var(--white);
}
a.btn-gray:hover, a.btn-gray:focus, a.btn-gray.focus {
  color: var(--white);
}

.btn-gray-100 {
  background-color: var(--gray-100);
  color: var(--black-900);
}
.btn-gray-100:hover:not(:disabled):not(.disabled), .btn-gray-100:focus:not(:disabled):not(.disabled) {
  background-color: var(--gray-500);
}
.btn-gray-100.disabled, .btn-gray-100:disabled {
  opacity: 0.5;
}
a.btn-gray-100 {
  color: var(--black-900);
}
a.btn-gray-100:hover, a.btn-gray-100:focus, a.btn-gray-100.focus {
  color: var(--black-900);
}

.btn-primary-100 {
  background-color: var(--primary-100);
  color: var(--primary-700);
}
.btn-primary-100:hover:not(:disabled):not(.disabled), .btn-primary-100:focus:not(:disabled):not(.disabled) {
  background-color: var(--primary-50);
}
.btn-primary-100.disabled, .btn-primary-100:disabled {
  opacity: 0.5;
}
a.btn-primary-100 {
  color: var(--primary-700);
}
a.btn-primary-100:hover, a.btn-primary-100:focus, a.btn-primary-100.focus {
  color: var(--primary-700);
}

.btn-warning {
  background-color: var(--warning-500);
  color: var(--white);
}
.btn-warning:hover:not(:disabled):not(.disabled), .btn-warning:focus:not(:disabled):not(.disabled) {
  background-color: var(--warning-900);
}
.btn-warning.disabled, .btn-warning:disabled {
  opacity: 0.5;
}
a.btn-warning {
  color: var(--white);
}
a.btn-warning:hover, a.btn-warning:focus, a.btn-warning.focus {
  color: var(--white);
}

.btn-primary-business {
  background-color: var(--primary-700-business);
  color: var(--white);
}
.btn-primary-business:hover:not(:disabled):not(.disabled), .btn-primary-business:focus:not(:disabled):not(.disabled) {
  background-color: #373e62;
}
.btn-primary-business.disabled, .btn-primary-business:disabled {
  opacity: 0.5;
}
a.btn-primary-business {
  color: var(--white);
}
a.btn-primary-business:hover, a.btn-primary-business:focus, a.btn-primary-business.focus {
  color: var(--white);
}

.btn-secondary-business {
  background-color: var(--primary-50-business);
  color: var(--primary-700-business);
}
.btn-secondary-business:hover:not(:disabled):not(.disabled), .btn-secondary-business:focus:not(:disabled):not(.disabled) {
  background-color: var(--primary-50-business);
}
.btn-secondary-business.disabled, .btn-secondary-business:disabled {
  opacity: 0.5;
}
a.btn-secondary-business {
  color: var(--primary-700-business);
}
a.btn-secondary-business:hover, a.btn-secondary-business:focus, a.btn-secondary-business.focus {
  color: var(--primary-700-business);
}

.btn-white {
  background-color: var(--white);
  color: var(--black-900);
}
.btn-white:hover:not(:disabled):not(.disabled), .btn-white:focus:not(:disabled):not(.disabled) {
  background-color: var(--white);
  opacity: 0.8;
}
.btn-white.disabled, .btn-white:disabled {
  background-color: var(--white);
  opacity: 0.5;
}
a.btn-white {
  color: var(--black-900);
}
a.btn-white:hover, a.btn-white:focus, a.btn-white.focus {
  color: var(--black-900);
}

.btn-outline-white {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}
.btn-outline-white:hover:not(:disabled):not(.disabled), .btn-outline-white:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  background-color: transparent;
}
a.btn-outline-white {
  color: var(--white);
}
a.btn-outline-white:hover, a.btn-outline-white:focus, a.btn-outline-white.focus {
  color: var(--white);
}

.btn-ghost-white {
  background-color: transparent;
  color: var(--white);
}
.btn-ghost-white:hover:not(:disabled):not(.disabled), .btn-ghost-white:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
}
.btn-ghost-white.disabled, .btn-ghost-white:disabled {
  background-color: transparent;
}
a.btn-ghost-white {
  color: var(--white);
}
a.btn-ghost-white:hover, a.btn-ghost-white:focus, a.btn-ghost-white.focus {
  color: var(--white);
}

.btn-ghost-primary {
  background-color: transparent;
  color: var(--primary-500);
}
.btn-ghost-primary:hover:not(:disabled):not(.disabled), .btn-ghost-primary:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--primary-900);
}
.btn-ghost-primary.disabled, .btn-ghost-primary:disabled {
  background-color: transparent;
  color: var(--primary-100);
}
a.btn-ghost-primary {
  color: var(--primary-500);
}
a.btn-ghost-primary:hover, a.btn-ghost-primary:focus, a.btn-ghost-primary.focus {
  color: var(--primary-500);
}
a.btn-ghost-primary.disabled, a.btn-ghost-primary:disabled {
  color: var(--primary-100);
}

.btn-ghost-primary-50 {
  background-color: transparent;
  color: var(--primary-50);
}
.btn-ghost-primary-50:hover:not(:disabled):not(.disabled), .btn-ghost-primary-50:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--primary-100);
}
.btn-ghost-primary-50.disabled, .btn-ghost-primary-50:disabled {
  background-color: transparent;
  color: var(--primary-100);
}
a.btn-ghost-primary-50 {
  color: var(--primary-50);
}
a.btn-ghost-primary-50:hover, a.btn-ghost-primary-50:focus, a.btn-ghost-primary-50.focus {
  color: var(--primary-50);
}
a.btn-ghost-primary-50.disabled, a.btn-ghost-primary-50:disabled {
  color: var(--primary-100);
}

.btn-ghost-secondary {
  background-color: transparent;
  color: var(--positive-500);
}
.btn-ghost-secondary:hover:not(:disabled):not(.disabled), .btn-ghost-secondary:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--secondary-900);
}
.btn-ghost-secondary.disabled, .btn-ghost-secondary:disabled {
  background-color: transparent;
  color: var(--secondary-100);
}
a.btn-ghost-secondary {
  color: var(--positive-500);
}
a.btn-ghost-secondary:hover, a.btn-ghost-secondary:focus, a.btn-ghost-secondary.focus {
  color: var(--positive-500);
}
a.btn-ghost-secondary.disabled, a.btn-ghost-secondary:disabled {
  color: var(--secondary-100);
}

.btn-ghost-secondary-50 {
  background-color: transparent;
  color: var(--secondary-50);
}
.btn-ghost-secondary-50:hover:not(:disabled):not(.disabled), .btn-ghost-secondary-50:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--secondary-100);
}
.btn-ghost-secondary-50.disabled, .btn-ghost-secondary-50:disabled {
  background-color: transparent;
  color: var(--secondary-100);
}
a.btn-ghost-secondary-50 {
  color: var(--secondary-50);
}
a.btn-ghost-secondary-50:hover, a.btn-ghost-secondary-50:focus, a.btn-ghost-secondary-50.focus {
  color: var(--secondary-50);
}
a.btn-ghost-secondary-50.disabled, a.btn-ghost-secondary-50:disabled {
  color: var(--secondary-100);
}

.btn-ghost-black {
  background-color: transparent;
  color: var(--black-900);
}
.btn-ghost-black:hover:not(:disabled):not(.disabled), .btn-ghost-black:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--black-900);
}
.btn-ghost-black.disabled, .btn-ghost-black:disabled {
  background-color: transparent;
  color: var(--black-900);
}
a.btn-ghost-black {
  color: var(--black-900);
}
a.btn-ghost-black:hover, a.btn-ghost-black:focus, a.btn-ghost-black.focus {
  color: var(--black-900);
}
a.btn-ghost-black.disabled, a.btn-ghost-black:disabled {
  color: var(--black-900);
}

.btn-ghost-gray {
  background-color: transparent;
  color: var(--gray-500);
}
.btn-ghost-gray:hover:not(:disabled):not(.disabled), .btn-ghost-gray:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--gray-900);
}
.btn-ghost-gray.disabled, .btn-ghost-gray:disabled {
  background-color: transparent;
  color: var(--gray-100);
}
a.btn-ghost-gray {
  color: var(--gray-500);
}
a.btn-ghost-gray:hover, a.btn-ghost-gray:focus, a.btn-ghost-gray.focus {
  color: var(--gray-500);
}
a.btn-ghost-gray.disabled, a.btn-ghost-gray:disabled {
  color: var(--gray-100);
}

.btn-ghost-gray-100 {
  background-color: transparent;
  color: var(--gray-100);
}
.btn-ghost-gray-100:hover:not(:disabled):not(.disabled), .btn-ghost-gray-100:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--gray-500);
}
.btn-ghost-gray-100.disabled, .btn-ghost-gray-100:disabled {
  background-color: transparent;
  color: var(--gray-100);
}
a.btn-ghost-gray-100 {
  color: var(--gray-100);
}
a.btn-ghost-gray-100:hover, a.btn-ghost-gray-100:focus, a.btn-ghost-gray-100.focus {
  color: var(--gray-100);
}
a.btn-ghost-gray-100.disabled, a.btn-ghost-gray-100:disabled {
  color: var(--gray-100);
}

.btn-ghost-primary-100 {
  background-color: transparent;
  color: var(--primary-100);
}
.btn-ghost-primary-100:hover:not(:disabled):not(.disabled), .btn-ghost-primary-100:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--primary-50);
}
.btn-ghost-primary-100.disabled, .btn-ghost-primary-100:disabled {
  background-color: transparent;
  color: var(--primary-50);
}
a.btn-ghost-primary-100 {
  color: var(--primary-100);
}
a.btn-ghost-primary-100:hover, a.btn-ghost-primary-100:focus, a.btn-ghost-primary-100.focus {
  color: var(--primary-100);
}
a.btn-ghost-primary-100.disabled, a.btn-ghost-primary-100:disabled {
  color: var(--primary-50);
}

.btn-ghost-warning {
  background-color: transparent;
  color: var(--warning-500);
}
.btn-ghost-warning:hover:not(:disabled):not(.disabled), .btn-ghost-warning:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--warning-900);
}
.btn-ghost-warning.disabled, .btn-ghost-warning:disabled {
  background-color: transparent;
  color: var(--gray-500);
}
a.btn-ghost-warning {
  color: var(--warning-500);
}
a.btn-ghost-warning:hover, a.btn-ghost-warning:focus, a.btn-ghost-warning.focus {
  color: var(--warning-500);
}
a.btn-ghost-warning.disabled, a.btn-ghost-warning:disabled {
  color: var(--gray-500);
}

.btn-ghost-primary-business {
  background-color: transparent;
  color: var(--primary-700-business);
}
.btn-ghost-primary-business:hover:not(:disabled):not(.disabled), .btn-ghost-primary-business:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: #373e62;
}
.btn-ghost-primary-business.disabled, .btn-ghost-primary-business:disabled {
  background-color: transparent;
  color: var(--primary-100-business);
}
a.btn-ghost-primary-business {
  color: var(--primary-700-business);
}
a.btn-ghost-primary-business:hover, a.btn-ghost-primary-business:focus, a.btn-ghost-primary-business.focus {
  color: var(--primary-700-business);
}
a.btn-ghost-primary-business.disabled, a.btn-ghost-primary-business:disabled {
  color: var(--primary-100-business);
}

.btn-ghost-secondary-business {
  background-color: transparent;
  color: var(--primary-50-business);
}
.btn-ghost-secondary-business:hover:not(:disabled):not(.disabled), .btn-ghost-secondary-business:focus:not(:disabled):not(.disabled) {
  background-color: transparent;
  color: var(--primary-50-business);
}
.btn-ghost-secondary-business.disabled, .btn-ghost-secondary-business:disabled {
  background-color: transparent;
  color: var(--primary-100-business);
}
a.btn-ghost-secondary-business {
  color: var(--primary-50-business);
}
a.btn-ghost-secondary-business:hover, a.btn-ghost-secondary-business:focus, a.btn-ghost-secondary-business.focus {
  color: var(--primary-50-business);
}
a.btn-ghost-secondary-business.disabled, a.btn-ghost-secondary-business:disabled {
  color: var(--primary-100-business);
}

.btn-outline-primary {
  background-color: transparent;
  border-color: var(--primary-500);
  color: var(--primary-500);
}
.btn-outline-primary:hover:not(.disabled):not(:disabled), .btn-outline-primary:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--primary-900);
  color: var(--primary-900);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  background-color: transparent;
  border-color: var(--primary-100);
  color: var(--primary-100);
}
a.btn-outline-primary {
  color: var(--primary-500);
}
a.btn-outline-primary:hover, a.btn-outline-primary:focus, a.btn-outline-primary.focus {
  color: var(--primary-500);
}
a.btn-outline-primary.disabled, a.btn-outline-primary:disabled {
  color: var(--primary-100);
}

.btn-outline-primary-50 {
  background-color: transparent;
  border-color: var(--primary-50);
  color: var(--primary-50);
}
.btn-outline-primary-50:hover:not(.disabled):not(:disabled), .btn-outline-primary-50:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--primary-100);
  color: var(--primary-100);
}
.btn-outline-primary-50.disabled, .btn-outline-primary-50:disabled {
  background-color: transparent;
  border-color: var(--primary-100);
  color: var(--primary-100);
}
a.btn-outline-primary-50 {
  color: var(--primary-50);
}
a.btn-outline-primary-50:hover, a.btn-outline-primary-50:focus, a.btn-outline-primary-50.focus {
  color: var(--primary-50);
}
a.btn-outline-primary-50.disabled, a.btn-outline-primary-50:disabled {
  color: var(--primary-100);
}

.btn-outline-secondary {
  background-color: transparent;
  border-color: var(--positive-500);
  color: var(--positive-500);
}
.btn-outline-secondary:hover:not(.disabled):not(:disabled), .btn-outline-secondary:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--secondary-900);
  color: var(--secondary-900);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  background-color: transparent;
  border-color: var(--secondary-100);
  color: var(--secondary-100);
}
a.btn-outline-secondary {
  color: var(--positive-500);
}
a.btn-outline-secondary:hover, a.btn-outline-secondary:focus, a.btn-outline-secondary.focus {
  color: var(--positive-500);
}
a.btn-outline-secondary.disabled, a.btn-outline-secondary:disabled {
  color: var(--secondary-100);
}

.btn-outline-secondary-50 {
  background-color: transparent;
  border-color: var(--secondary-50);
  color: var(--secondary-50);
}
.btn-outline-secondary-50:hover:not(.disabled):not(:disabled), .btn-outline-secondary-50:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--secondary-100);
  color: var(--secondary-100);
}
.btn-outline-secondary-50.disabled, .btn-outline-secondary-50:disabled {
  background-color: transparent;
  border-color: var(--secondary-100);
  color: var(--secondary-100);
}
a.btn-outline-secondary-50 {
  color: var(--secondary-50);
}
a.btn-outline-secondary-50:hover, a.btn-outline-secondary-50:focus, a.btn-outline-secondary-50.focus {
  color: var(--secondary-50);
}
a.btn-outline-secondary-50.disabled, a.btn-outline-secondary-50:disabled {
  color: var(--secondary-100);
}

.btn-outline-black {
  background-color: transparent;
  border-color: var(--black-900);
  color: var(--black-900);
}
.btn-outline-black:hover:not(.disabled):not(:disabled), .btn-outline-black:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--black-900);
  color: var(--black-900);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  background-color: transparent;
  border-color: var(--black-900);
  color: var(--black-900);
}
a.btn-outline-black {
  color: var(--black-900);
}
a.btn-outline-black:hover, a.btn-outline-black:focus, a.btn-outline-black.focus {
  color: var(--black-900);
}
a.btn-outline-black.disabled, a.btn-outline-black:disabled {
  color: var(--black-900);
}

.btn-outline-gray {
  background-color: transparent;
  border-color: var(--gray-500);
  color: var(--gray-500);
}
.btn-outline-gray:hover:not(.disabled):not(:disabled), .btn-outline-gray:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--gray-900);
  color: var(--gray-900);
}
.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  background-color: transparent;
  border-color: var(--gray-100);
  color: var(--gray-100);
}
a.btn-outline-gray {
  color: var(--gray-500);
}
a.btn-outline-gray:hover, a.btn-outline-gray:focus, a.btn-outline-gray.focus {
  color: var(--gray-500);
}
a.btn-outline-gray.disabled, a.btn-outline-gray:disabled {
  color: var(--gray-100);
}

.btn-outline-gray-100 {
  background-color: transparent;
  border-color: var(--gray-100);
  color: var(--gray-100);
}
.btn-outline-gray-100:hover:not(.disabled):not(:disabled), .btn-outline-gray-100:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--gray-500);
  color: var(--gray-500);
}
.btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
  background-color: transparent;
  border-color: var(--gray-100);
  color: var(--gray-100);
}
a.btn-outline-gray-100 {
  color: var(--gray-100);
}
a.btn-outline-gray-100:hover, a.btn-outline-gray-100:focus, a.btn-outline-gray-100.focus {
  color: var(--gray-100);
}
a.btn-outline-gray-100.disabled, a.btn-outline-gray-100:disabled {
  color: var(--gray-100);
}

.btn-outline-primary-100 {
  background-color: transparent;
  border-color: var(--primary-100);
  color: var(--primary-100);
}
.btn-outline-primary-100:hover:not(.disabled):not(:disabled), .btn-outline-primary-100:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--primary-50);
  color: var(--primary-50);
}
.btn-outline-primary-100.disabled, .btn-outline-primary-100:disabled {
  background-color: transparent;
  border-color: var(--primary-50);
  color: var(--primary-50);
}
a.btn-outline-primary-100 {
  color: var(--primary-100);
}
a.btn-outline-primary-100:hover, a.btn-outline-primary-100:focus, a.btn-outline-primary-100.focus {
  color: var(--primary-100);
}
a.btn-outline-primary-100.disabled, a.btn-outline-primary-100:disabled {
  color: var(--primary-50);
}

.btn-outline-warning {
  background-color: transparent;
  border-color: var(--warning-500);
  color: var(--warning-500);
}
.btn-outline-warning:hover:not(.disabled):not(:disabled), .btn-outline-warning:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--warning-900);
  color: var(--warning-900);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  background-color: transparent;
  border-color: var(--gray-500);
  color: var(--gray-500);
}
a.btn-outline-warning {
  color: var(--warning-500);
}
a.btn-outline-warning:hover, a.btn-outline-warning:focus, a.btn-outline-warning.focus {
  color: var(--warning-500);
}
a.btn-outline-warning.disabled, a.btn-outline-warning:disabled {
  color: var(--gray-500);
}

.btn-outline-primary-business {
  background-color: transparent;
  border-color: var(--primary-700-business);
  color: var(--primary-700-business);
}
.btn-outline-primary-business:hover:not(.disabled):not(:disabled), .btn-outline-primary-business:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: #373e62;
  color: #373e62;
}
.btn-outline-primary-business.disabled, .btn-outline-primary-business:disabled {
  background-color: transparent;
  border-color: var(--primary-100-business);
  color: var(--primary-100-business);
}
a.btn-outline-primary-business {
  color: var(--primary-700-business);
}
a.btn-outline-primary-business:hover, a.btn-outline-primary-business:focus, a.btn-outline-primary-business.focus {
  color: var(--primary-700-business);
}
a.btn-outline-primary-business.disabled, a.btn-outline-primary-business:disabled {
  color: var(--primary-100-business);
}

.btn-outline-secondary-business {
  background-color: transparent;
  border-color: var(--primary-50-business);
  color: var(--primary-50-business);
}
.btn-outline-secondary-business:hover:not(.disabled):not(:disabled), .btn-outline-secondary-business:focus:not(.disabled):not(:disabled) {
  background-color: transparent;
  border-color: var(--primary-50-business);
  color: var(--primary-50-business);
}
.btn-outline-secondary-business.disabled, .btn-outline-secondary-business:disabled {
  background-color: transparent;
  border-color: var(--primary-100-business);
  color: var(--primary-100-business);
}
a.btn-outline-secondary-business {
  color: var(--primary-50-business);
}
a.btn-outline-secondary-business:hover, a.btn-outline-secondary-business:focus, a.btn-outline-secondary-business.focus {
  color: var(--primary-50-business);
}
a.btn-outline-secondary-business.disabled, a.btn-outline-secondary-business:disabled {
  color: var(--primary-100-business);
}

.btn-lg {
  font-size: 14px;
  font-weight: 800;
  height: 52px;
  line-height: 1.71;
  min-width: 100px;
}
a.btn-lg {
  text-decoration: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-auto {
  display: block;
  min-width: auto;
  width: auto;
}

@media (min-width: 0) {
  .btn-xs-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 0) {
  .btn-xs-auto {
    display: inline-block;
    min-width: 100px;
    width: auto;
  }
}

@media (min-width: 576px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 576px) {
  .btn-sm-auto {
    display: inline-block;
    min-width: 100px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .btn-md-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .btn-md-auto {
    display: inline-block;
    min-width: 100px;
    width: auto;
  }
}

@media (min-width: 992px) {
  .btn-lg-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .btn-lg-auto {
    display: inline-block;
    min-width: 100px;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .btn-xl-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .btn-xl-auto {
    display: inline-block;
    min-width: 100px;
    width: auto;
  }
}

@media (min-width: 1400px) {
  .btn-xxl-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .btn-xxl-auto {
    display: inline-block;
    min-width: 100px;
    width: auto;
  }
}

.range-option-button {
  border: solid 1px #f3f2f2;
  border-radius: 11px;
  cursor: pointer;
  padding: 3px 7px;
}
.range-option-button:hover, .range-option-button.range-option-button--selected {
  background-color: #f1f4f6;
  border: solid 1px #f1f4f6;
  color: var(--black-900);
}

.btn-none {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  display: flex;
  outline: 0;
  padding: 0;
}
.btn-none:focus {
  outline: 0;
}

.btn-back {
  border: 1px solid var(--gray-500);
  border-radius: 8px;
}

.btn-gray {
  background-color: var(--gray-100);
  border-radius: 8px;
  color: var(--black-900);
}

.filter-button {
  background: var(--gray-50);
  border-radius: 16px;
  padding: 7px 15px;
}
.filter-button.filter-button--active {
  background: var(--primary-50);
  box-sizing: border-box;
}
.filter-button.filter-button--no-background {
  background: none;
}

.btn-small {
  height: 25px;
}

.btn-transparent {
  background: transparent;
  border-color: transparent;
}

.btn-hero {
  padding-left: 65px !important;
  padding-right: 65px !important;
}
@media (min-width: 992px) {
  .btn-hero {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
}

.ng-select > .ng-select-container {
  transition: border-color 0.5s, box-shadow 0.5s;
}
.ng-select.short .ng-dropdown-panel-items {
  max-height: 150px;
}
.ng-select.small.ng-select-single .ng-select-container {
  height: 32px;
  min-height: 32px;
}
.ng-select.small.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 4px;
}
.ng-select.clock .ng-arrow-wrapper {
  margin-top: 0;
}
.ng-select.clock .ng-arrow-wrapper .ng-arrow {
  border: 0;
  font-size: 20px;
  transform: rotate(0);
}
.ng-select.clock .ng-arrow-wrapper .ng-arrow::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "iconomi-icons" !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
  color: var(--primary-700);
  content: "W";
}
.ng-select.search-box .ng-arrow-wrapper {
  display: none;
}
.ng-select .ng-value {
  color: var(--black-900);
}
.ng-select.ng-select-opened > .ng-select-container {
  background: var(--white);
  border-color: var(--primary-700);
  box-shadow: 0 0 0 1px var(--primary-700);
  cursor: pointer;
  z-index: 1051 !important;
}
.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: 0 0 0 1px var(--primary-700);
}
.ng-select.ng-select-opened.purple > .ng-select-container {
  border-color: var(--purple);
  box-shadow: 0 0 0 1px var(--purple);
}
.ng-select.ng-select-opened.ng-select-searchable .ng-value,
.ng-select.ng-select-opened.ng-select-searchable .ng-placeholder {
  visibility: hidden;
}
.ng-select.ng-select-disabled > .ng-select-container {
  border-color: var(--gray-100);
  cursor: not-allowed;
}
.ng-select.ng-select-disabled input {
  color: rgba(55, 34, 86, 0.5);
  cursor: not-allowed !important;
}
.ng-select.ng-select-disabled .ng-value {
  color: var(--gray-300);
}
.ng-select.ng-select-disabled .ng-arrow-wrapper {
  cursor: not-allowed !important;
}
.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow {
  border-color: var(--gray-300);
}
.ng-select.ng-invalid.ng-touched:not(.ng-select-opened) > .ng-select-container {
  background-color: rgba(208, 12, 12, 0.1);
  border-color: var(--error-500);
  box-shadow: 0 0 0 1px var(--error-500);
}
.ng-select.ng-invalid.ng-touched:not(.ng-select-opened) > .ng-select-container .ng-placeholder {
  color: transparent;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-select-container {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: 10px;
  min-height: 50px;
}
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 20px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--gray-900);
  padding-right: 5px;
}
.ng-select.ng-select-single .ng-select-container {
  height: 50px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 0;
  padding-left: 20px;
  padding-right: 50px;
  top: 13px;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  border: 1px solid var(--gray-300);
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 7px;
  padding-top: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 2px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-bottom: 5px;
  padding-left: 3px;
  top: 5px;
}
.ng-select .ng-clear-wrapper {
  display: none;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
.ng-select .ng-arrow-wrapper {
  margin-top: -6px;
  padding-right: 20px;
  width: 35px;
}
.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: var(--primary-700);
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--primary-700);
  border-style: solid;
  border-width: 0 1.5px 1.5px 0;
  padding: 4px;
  transform: rotate(45deg);
}
.ng-select.purple .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: var(--purple);
}
.ng-select.purple .ng-arrow-wrapper .ng-arrow {
  border-color: var(--purple);
}

.ng-dropdown-panel {
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: -1px !important;
  width: calc(100% + 2px) !important;
}
.ng-dropdown-panel.ng-select-bottom {
  border-radius: 10px;
  border-top-color: var(--gray-300);
  top: 100%;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ng-dropdown-panel.ng-select-top {
  border-bottom-color: var(--gray-300);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  bottom: 100%;
  margin-bottom: -2px;
  padding-bottom: 2px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid var(--gray-300);
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid var(--gray-300);
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  font-weight: 800;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  padding: 8px 10px;
  user-select: none;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: var(--gray-100);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-300);
  color: var(--black-900);
  padding: 15px 20px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-of-type {
  border-bottom: 0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--gray-100);
  color: var(--black-900);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: var(--gray-300);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-weight: 400;
  font-size: 80%;
  padding-right: 5px;
}

ngb-datepicker {
  border: 0 !important;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  color: var(--black-900);
  z-index: 1100;
}

.ngb-dp-month {
  background-color: var(--white);
  border-radius: 0 0 4px 4px;
  padding-bottom: 5px;
}
.ngb-dp-month:first-child .ngb-dp-week, .ngb-dp-month:last-child .ngb-dp-week {
  padding: 0 10px !important;
}

.ngb-dp-arrow-btn {
  background: transparent;
  color: var(--primary-700);
  height: auto;
  min-width: auto;
  padding: 0;
}
.ngb-dp-arrow-btn:focus, .ngb-dp-arrow-btn:active {
  outline: 0 !important;
}
.ngb-dp-arrow-btn:disabled {
  background-color: transparent;
  color: var(--gray-300);
  cursor: not-allowed;
}

.ngb-dp-day:focus, .ngb-dp-day:active {
  outline: 0;
}
.ngb-dp-day .btn-light {
  background: 0 0;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  outline: 0;
  text-align: center;
  width: 2rem;
}
.ngb-dp-day .btn-light:hover {
  background: var(--primary-100);
  color: var(--white);
}
.ngb-dp-day .bg-primary {
  background: var(--primary-700);
  color: var(--white);
}
.ngb-dp-day .bg-primary.hover {
  background: var(--primary-100);
  color: var(--white);
}
.ngb-dp-day .bg-primary:hover {
  background: var(--primary-700);
  color: var(--white);
}
.ngb-dp-day.disabled {
  cursor: not-allowed !important;
}
.ngb-dp-day.disabled .btn-light {
  color: var(--gray-300);
}
.ngb-dp-day.disabled .btn-light:hover {
  background: var(--gray-300);
  color: var(--white);
}

.ngb-dp-navigation-chevron {
  border-width: 2px 2px 0 0 !important;
  height: 11px !important;
  width: 11px !important;
}

ngb-datepicker-navigation-select > .custom-select {
  appearance: none;
  background: url("/assets/images/icons/fills/dropdown.svg") no-repeat center right 5px;
  background-color: var(--primary-700);
  background-size: 14px;
  border: 1.5px solid var(--primary-700);
  border-radius: 3px;
  color: var(--white);
  height: 24px !important;
  width: 80px;
}
ngb-datepicker-navigation-select > .custom-select:last-of-type {
  margin-right: 20px;
}
ngb-datepicker-navigation-select > .custom-select option {
  background-color: var(--white);
  color: var(--black-900);
}

.ngb-dp-header {
  background-color: var(--gray-100) !important;
}

.ngb-dp-weekday {
  font-weight: 800;
  color: var(--gray-900) !important;
  font-size: 12px;
}

ngb-datepicker-month-view {
  padding: 0 10px 10px;
}
ngb-datepicker-month-view .ngb-dp-day,
ngb-datepicker-month-view .ngb-dp-week-number,
ngb-datepicker-month-view .ngb-dp-weekday {
  height: auto;
  width: 2.4rem;
}

.ngb-dp-weekdays {
  background-color: var(--gray-100) !important;
  border-bottom: 0 !important;
  margin: 0;
  padding: 0 10px !important;
}

.datepicker-hidden-input {
  border: 0;
  height: 0;
  outline: 0;
  padding: 0;
  width: 0;
}

.ngb-dp-navigation-select {
  flex-flow: row-reverse;
}

.card {
  background-color: var(--white);
  padding: 20px 15px;
  width: 100%;
}
.card.card-always-border {
  border-radius: 10px;
}
.card.primary {
  background-color: #005ad7;
}
.card.black {
  background-color: #000000;
}
.card.gray {
  background-color: var(--gray-100);
}
@media (min-width: 576px) {
  .card {
    border-radius: 10px;
    box-shadow: 0 3px 50px 0 rgba(150, 160, 169, 0.07);
    padding: 30px;
    transition: box-shadow 0.3s ease-in-out;
  }
  .card.card--hover:hover {
    box-shadow: 0 3px 50px 0 rgba(150, 160, 169, 0.3);
    transition: box-shadow 0.2s ease-in-out;
  }
}
.card.card--no-background {
  background: transparent;
}
@media (min-width: 768px) {
  .card.card--small {
    width: 470px;
  }
}
.card.loading {
  pointer-events: none;
  position: relative;
}
.card.loading * {
  filter: blur(5px);
}
.card.loading::after {
  background-image: url("/assets/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
}
.card.accordion {
  border: 1px solid var(--gray-300);
  box-shadow: none;
  padding: 20px;
}
.card.accordion.selected {
  border: 1px solid var(--primary-700);
}
.card.disabled {
  opacity: 0.3;
}

.card-top {
  left: -15px;
  padding: 20px 15px 0;
  position: relative;
  top: -20px;
  width: calc(100% + 30px);
}
@media (min-width: 576px) {
  .card-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    left: -30px;
    padding: 30px 30px 0;
    top: -30px;
    width: calc(100% + 60px);
  }
}

.radio-button-card {
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 20px;
}
.radio-button-card.active {
  border: 1px solid var(--primary-700);
}
.radio-button-card.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

@media (min-width: 768px) {
  .card-md {
    background-color: var(--white);
    padding: 20px 15px;
    width: 100%;
  }
  .card-md.card-always-border {
    border-radius: 10px;
  }
  .card-md.primary {
    background-color: #005ad7;
  }
  .card-md.black {
    background-color: #000000;
  }
  .card-md.gray {
    background-color: var(--gray-100);
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .card-md {
    border-radius: 10px;
    box-shadow: 0 3px 50px 0 rgba(150, 160, 169, 0.07);
    padding: 30px;
    transition: box-shadow 0.3s ease-in-out;
  }
  .card-md.card--hover:hover {
    box-shadow: 0 3px 50px 0 rgba(150, 160, 169, 0.3);
    transition: box-shadow 0.2s ease-in-out;
  }
}

.card--gray {
  background-color: var(--gray-50);
  border-radius: 10px;
  padding: 20px;
}

.card--border-only {
  border: 1px solid var(--gray-100);
  border-radius: 10px;
  padding: 20px;
}

.card--on-white {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.07);
}

.card-corporate {
  background-color: var(--primary-700-business);
}

.password-validator {
  padding-left: 30px;
}

.password-validator--touched {
  background: url("/assets/images/icons/assets/cancel.svg") no-repeat center left;
}

.password-validator--fulfilled {
  background: url("/assets/images/icons/assets/arrow-right.svg") no-repeat center left;
}

@font-face {
  font-display: swap;
  font-family: "iconomi-icons";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/icons/iconomi-icons.eot");
  src: url("../../fonts/icons/iconomi-icons.eot?#iefix") format("embedded-opentype"), url("../../fonts/icons/iconomi-icons.woff") format("woff"), url("../../fonts/icons/iconomi-icons.ttf") format("truetype"), url("../../fonts/icons/iconomi-icons.svg#iconomi-icons") format("svg");
}
.icon-wrapper {
  background: var(--primary-700);
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

[class^=icon-],
[class*=" icon-"] {
  display: inline-flex;
  vertical-align: text-top;
}
[class^=icon-]::before,
[class*=" icon-"]::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "iconomi-icons" !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

.icon-checked::before {
  content: "b";
}

.icon-lock::before {
  content: "c";
}

.icon-search::before {
  content: "e";
}

.icon-settings::before {
  content: "f";
}

.icon-upload::before {
  content: "g";
}

.icon-info::before {
  content: "h";
  transform: rotate(180deg);
}

.icon-edit::before {
  content: "j";
}

.icon-trash::before {
  content: "k";
}

.icon-cancel::before {
  content: "l";
}

.icon-arrow::before {
  content: "m";
}

.icon-arrow.icon-arrow-animate {
  transition: transform 0.3s linear;
}
.icon-arrow.icon-arrow-up {
  transform: rotate(180deg);
}
.icon-arrow.icon-arrow-left {
  transform: rotate(90deg);
}
.icon-arrow.icon-arrow-right {
  transform: rotate(-90deg);
}

.icon-undo::before {
  content: "n";
}

.icon-star-empty::before {
  content: "o";
}

.icon-arrow-long::before {
  content: "p";
}

.icon-arrow-long.icon-arrow-long-right {
  transform: scaleX(-1);
}
.icon-arrow-long.icon-arrow-long-right-bottom {
  transform: rotate(225deg);
}

.icon-calendar::before {
  content: "q";
}

.icon-copy::before {
  content: "r";
}

.icon-change::before {
  content: "a";
}

.icon-change-horizontal::before {
  content: "a";
  transform: rotate(90deg);
}

.icon-plus::before {
  content: "s";
}

.icon-invite-friends::before {
  content: "t";
}

.icon-eye::before {
  content: "w";
}

.icon-eye-disabled::before {
  content: "x";
}

.icon-link::before {
  content: "y";
}

.icon-transfer-crypto::before {
  content: "A";
}

.icon-airdrop::before {
  content: "z";
}

.icon-list::before {
  content: "B";
}

.icon-exchange::before {
  content: "C";
}

.icon-graph::before {
  content: "D";
}

.icon-wallet::before {
  content: "E";
}

.icon-cancel-circle::before {
  content: "F";
}

.icon-payoff::before {
  content: "G";
}

.icon-filter::before {
  content: "H";
}

.icon-deposit::before {
  content: "I";
}

.icon-withdraw::before {
  content: "J";
}

.icon-envelope::before {
  content: "u";
}

.icon-facebook::before {
  content: "v";
}

.icon-linkedin::before {
  content: "K";
}

.icon-reddit::before {
  content: "L";
}

.icon-twitter::before {
  content: "M";
}

.icon-youtube::before {
  content: "N";
}

.icon-support::before {
  content: "O";
}

.icon-user::before {
  content: "P";
}

.icon-notifications::before {
  content: "d";
}

.icon-notification-on::before {
  content: "i";
}

.icon-notification-off::before {
  content: "Q";
}

.icon-pulse::before {
  content: "R";
}

.icon-dots::before {
  content: "S";
}

.icon-contests::before {
  content: "T";
}

.icon-performance-earned::before {
  content: "U";
}

.icon-performance-payed::before {
  content: "V";
}

.icon-clock::before {
  content: "W";
}

.icon-share::before {
  content: "X";
}

.icon-pin::before {
  content: "Y";
}

.icon-like::before {
  content: "Z";
}

.icon-like-full::before {
  content: "0";
}

.icon-star::before {
  content: "1";
}

.icon-gift::before {
  content: "2";
}

.icon-automated-trading::before {
  content: "3";
}

.icon-asset-return::before {
  content: "4";
}

.icon-donation::before {
  content: "5";
}

.icon-url::before {
  content: "6";
}

.icon-emoji::before {
  content: "7";
}

.icon-photo::before {
  content: "8";
}

.icon-open::before {
  content: "9";
}

.icon-close::before {
  content: "!";
}

.icon-cryptocurrencies::before {
  content: '"';
}

.icon-italic::before {
  content: "#";
}

.icon-underline::before {
  content: "$";
}

.icon-bold::before {
  content: "%";
}

.icon-posts::before {
  content: "&";
}

.icon-shield::before {
  content: "'";
}

.icon-user-full::before {
  content: "(";
}

.icon-internet::before {
  content: ")";
}

.icon-bulb::before {
  content: "*";
}

.icon-earnings::before {
  content: "+";
}

.icon-filter-2::before {
  content: ",";
}

.icon-currency::before {
  content: "-";
}

.icon-savings::before {
  content: ".";
}

.icon-small-full-arrow::before {
  content: "/";
}

.icon-small-full-arrow--right {
  transform: rotate(-90deg);
}

.icon-rocket::before {
  content: "\e010";
}

.icon-social::before {
  content: ":";
}

.icon-activity::before {
  content: "<";
}

.icon-analytics::before {
  content: "=";
}

.icon-copiers::before {
  content: ">";
}

.icon-dashboard::before {
  content: "?";
}

.icon-settings-2::before {
  content: "@";
}

.icon-credit-card::before {
  content: "[";
}

.icon-bank::before {
  content: "]";
}

.icon-grid::before {
  content: "`";
}

.icon-shopping-cart::before {
  content: "{";
}

.icon-mobile::before {
  content: "^";
}

.icon-desktop::before {
  content: "_";
}

.icon-switch::before {
  content: "|";
}

.icon-discord::before {
  content: "}";
}

.icon-floppy::before {
  content: "~";
}

.icon-home::before {
  content: "\\";
}

.icon-strategy::before {
  content: "\e000";
}

.icon-posts-2::before {
  content: "\e001";
}

.icon-more::before {
  content: "\e002";
}

.icon-wallet-fill::before {
  content: "\e003";
}

.icon-activity-2::before {
  content: "\e004";
}

.icon-star-half::before {
  content: "\e006";
}

.icon-star-1::before {
  content: "\e007";
}

.icon-star-empty-1::before {
  content: "\e005";
}

.icon-rss-square::before {
  content: "\e008";
}

.icon-telegram::before {
  content: "\e009";
}

.icon-double-quotes::before {
  content: "\e00a";
}

.icon-money::before {
  content: "\e00b";
}

.icon-automation::before {
  content: "\e00c";
}

.icon-x::before {
  content: "\e00d";
}

.icon-arrow-diagonally::before {
  content: "\e00e";
}

.icon-expand::before {
  content: "\e00f";
}

.icon-lock-2::before {
  content: ";";
}

.toast-center-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  right: 0;
  top: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  right: 0;
  top: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  left: 12px;
  top: 12px;
}

.toast-top-right {
  right: 12px;
  top: 12px;
}

.toast-bottom-right {
  bottom: 12px;
  right: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 2100;
}
.toast-container .ngx-toastr {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3E%3Cpath fill='rgb(255,255,255)' d='M8 .4a7.6 7.6 0 1 1 0 15.2A7.6 7.6 0 0 1 8 .4zm0 .8a6.8 6.8 0 1 0 0 13.6A6.8 6.8 0 0 0 8 1.2zm0 9.2A.8.8 0 1 1 8 12a.8.8 0 0 1 0-1.6zM8 4a.8.8 0 0 1 .8.8v4a.8.8 0 1 1-1.6 0v-4A.8.8 0 0 1 8 4z'/%3E%3C/svg%3E");
  background-position: 15px 16px;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  font-size: 12px;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 15px 24px 15px 41px;
  pointer-events: auto;
  position: relative;
  width: 270px;
}
.toast-container .ngx-toastr.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3E%3Cpath fill='rgb(55, 34, 86)' d='M8 .4a7.6 7.6 0 1 1 0 15.2A7.6 7.6 0 0 1 8 .4zm0 .8a6.8 6.8 0 1 0 0 13.6A6.8 6.8 0 0 0 8 1.2zm0 9.2A.8.8 0 1 1 8 12a.8.8 0 0 1 0-1.6zM8 4a.8.8 0 0 1 .8.8v4a.8.8 0 1 1-1.6 0v-4A.8.8 0 0 1 8 4z'/%3E%3C/svg%3E");
}
.toast-container .ngx-toastr.vote-now-toastr {
  background: var(--primary-900);
  padding: 24px;
  width: 406px;
}
@media (max-width: 991px) {
  .toast-container .ngx-toastr.vote-now-toastr {
    display: none;
  }
}
.toast-container .ngx-toastr .toast-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.toast-container .ngx-toastr .toast-message {
  word-wrap: break-word;
}
.toast-container .ngx-toastr .toast-message a:hover {
  text-decoration: underline;
}
.toast-container .ngx-toastr .toast-close-button {
  background: transparent;
  border: 0;
  color: inherit;
  float: right;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.5;
  outline: none;
  padding: 0;
  position: relative;
  right: -10px;
  text-shadow: 0 1px 0 var(--white);
  top: -10px;
}
.toast-container .ngx-toastr .toast-progress {
  background-color: var(--black-900);
  bottom: 0;
  height: 4px;
  left: 0;
  opacity: 0.3;
  position: absolute;
}
.toast-container .ngx-toastr .toast-close-button:hover,
.toast-container .ngx-toastr .toast-close-button:focus {
  color: var(--black-900);
  cursor: pointer;
  opacity: 0.75;
  text-decoration: none;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  opacity: 1;
}
.toast-container .toast-top-center .ngx-toastr,
.toast-container .toast-bottom-center .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}
.toast-container .toast-top-full-width .ngx-toastr,
.toast-container .toast-bottom-full-width .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
  width: 96%;
}

.toast-success {
  background-color: var(--positive-500);
  color: var(--white);
}

.toast-error {
  background-color: var(--negative-500);
  color: var(--white);
}

.toast-info {
  background-color: var(--white);
  color: var(--black-900);
}

.toast-warning {
  background-color: var(--warning-500);
  color: var(--white);
}

.badge-gray-100 {
  border-radius: 23px;
  padding: 14px;
  text-align: center;
}

.badge-xs-warning, .badge-xs-positive, .badge-xs-gray {
  border-radius: 10px;
  font-size: 12px;
  line-height: 1.5;
  padding: 0 10px;
}

.badge-gray-100 {
  background: var(--gray-100);
}

.badge-xs-gray {
  background: var(--gray-100);
  color: var(--gray-900);
}

.badge-xs-positive {
  background: var(--positive-100);
  color: var(--positive-500);
}

.badge-md-gray {
  background: var(--gray-100);
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  padding: 0 30px;
  text-align: center;
}

.badge-xs-warning {
  background-color: rgba(255, 206, 110, 0.2);
  color: var(--warning-500);
}

.border-box {
  border: 1px solid;
  border-radius: 8px;
}

.border-box-primary {
  border-color: var(--primary-700);
}

.border-box-gray-300 {
  border-color: var(--gray-300);
}

.border-box-gray-100 {
  border-color: var(--gray-100);
}

.tag {
  border-radius: 9px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  padding: 0 10px;
  position: relative;
  text-align: center;
}

.tag-secondary {
  background-color: var(--secondary-100);
  color: var(--secondary-500);
}

.tag-error {
  background-color: rgba(208, 12, 12, 0.2);
  color: var(--error-500);
}

.tag-warning {
  background-color: rgba(255, 206, 110, 0.2);
  color: var(--warning-500);
}

.tag-warning-900 {
  background-color: #ffce6e;
  color: var(--white);
}

.tag-gray {
  background-color: rgba(178, 186, 194, 0.2);
  color: var(--gray-900);
}

.tag-primary {
  background-color: var(--primary-50);
  color: var(--primary-700);
}
.tag-primary--outline {
  background-color: transparent;
  border: 1px solid var(--primary-700);
  color: var(--primary-700);
}

.tag-primary-solid {
  background-color: var(--primary-700);
  color: var(--white);
}

.tag-premium {
  background-color: rgba(32, 35, 38, 0.8);
  color: var(--white);
}

.tag-positive {
  background-color: rgba(38, 191, 64, 0.2);
  color: var(--positive-500);
}

.tag-gold {
  background-color: var(--gold);
  color: var(--white);
}

.tag-silver {
  background-color: var(--gray-900);
  color: var(--white);
}

.tag-bronze {
  background-color: var(--bronze);
  color: var(--white);
}

.tag-secondary-solid {
  background-color: var(--secondary-500);
  color: var(--white);
}

.tag-new {
  background-color: var(--secondary-500);
  border-radius: 4px;
  color: var(--white);
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  padding: 0 5px;
}

.tag-beta {
  background-color: var(--purple);
  border-radius: 4px;
  color: var(--white);
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  padding: 0 5px;
}

.tag-percents {
  min-width: 53px;
  text-align: center;
}

.tag-box {
  border-radius: 4px;
}

.tag-white {
  background-color: var(--white);
  color: var(--gray-900);
}

.tag-notice {
  background-color: #ffe8b9;
  color: #e68a06;
}

.tooltip {
  display: block;
  position: absolute;
  word-wrap: break-word;
  z-index: 1100;
}
.tooltip .arrow {
  display: block;
  height: 7px;
  position: absolute;
  width: 14px;
}
.tooltip .arrow::before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}

.bs-tooltip-top, .tooltip--primary-500.bs-tooltip-auto[x-placement^=top], .bs-tooltip-auto[x-placement^=top] {
  padding: 7px 0 17px 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 10px;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: var(--white);
  border-width: 7px 7px 0;
  top: 0;
}
.bs-tooltip-top .tooltip-inner, .bs-tooltip-auto[x-placement^=top] .tooltip-inner {
  box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.1);
}

.bs-tooltip-right, .tooltip--primary-500.bs-tooltip-auto[x-placement^=right], .bs-tooltip-auto[x-placement^=right] {
  padding: 0 7px 0 17px;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  height: 14px;
  left: 10px;
  width: 7px;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: var(--white);
  border-width: 7px 7px 7px 0;
  right: 0;
}
.bs-tooltip-right .tooltip-inner, .bs-tooltip-auto[x-placement^=right] .tooltip-inner {
  box-shadow: -3px 0 20px 0 rgba(0, 0, 0, 0.1);
}

.bs-tooltip-bottom, .tooltip--primary-500.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-auto[x-placement^=bottom] {
  padding: 17px 0 7px 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 10px;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: var(--white);
  border-width: 0 7px 7px;
  bottom: 0;
}
.bs-tooltip-bottom .tooltip-inner, .bs-tooltip-auto[x-placement^=bottom] .tooltip-inner {
  box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.1);
}

.bs-tooltip-left, .tooltip--primary-500.bs-tooltip-auto[x-placement^=left], .bs-tooltip-auto[x-placement^=left] {
  padding: 0 17px 0 7px;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  height: 14px;
  right: 10px;
  width: 7px;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: var(--white);
  border-width: 7px 0 7px 7px;
  left: 0;
}
.bs-tooltip-left .tooltip-inner, .bs-tooltip-auto[x-placement^=left] .tooltip-inner {
  box-shadow: -3px 0 20px 0 rgba(0, 0, 0, 0.1);
}

.tooltip-inner {
  font-weight: 400;
  background-color: var(--white);
  border-radius: 4px;
  color: var(--gray-900);
  font-size: 12px;
  max-width: 200px;
  padding: 10px;
  text-align: center;
}

.tooltip-auto-width .tooltip-inner {
  max-width: initial;
}

.tooltip-width-100 .tooltip-inner {
  width: 100px;
}

.tooltip-big-radius .tooltip-inner {
  border-radius: 10px;
}

.tooltip--primary-500 .tooltip-inner {
  background-color: var(--primary-700);
  max-width: 355px;
  padding: 0;
}
.tooltip--primary-500.bs-tooltip-top .arrow::before, .tooltip--primary-500.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: var(--primary-700);
}
.tooltip--primary-500.bs-tooltip-right .arrow::before, .tooltip--primary-500.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: var(--primary-700);
}
.tooltip--primary-500.bs-tooltip-bottom .arrow::before, .tooltip--primary-500.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: var(--primary-700);
}
.tooltip--primary-500.bs-tooltip-left .arrow::before, .tooltip--primary-500.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: var(--primary-700);
}
table {
  border-collapse: collapse;
  width: 100%;
}
table.table--black th {
  border-bottom: 2px solid var(--black-700);
}
table.table--black td {
  border-bottom: 1px solid var(--black-700);
}

th {
  font-weight: 400;
  border-bottom: 2px solid var(--gray-100);
  color: var(--gray-900);
  font-size: 12px;
  padding: 0 10px 5px;
}
@media (min-width: 768px) {
  th {
    padding: 0 10px 20px;
  }
}
th:first-of-type {
  padding-left: 0;
}
th:last-of-type {
  padding-right: 0;
}

td {
  font-weight: 400;
  border-bottom: 1px solid var(--gray-100);
  color: var(--black-900);
  font-size: 14px;
  padding: 20px 10px;
}
td:first-of-type {
  padding-left: 0;
}
td:last-of-type {
  padding-right: 0;
}

tr.cursor-pointer *,
.d-table-row.cursor-pointer * {
  cursor: pointer;
}

tfoot td {
  border-top: 1px solid var(--gray-100);
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
.modal.no-animation .modal-dialog {
  animation: none;
}
@media (min-width: 768px) {
  .modal.no-animation .modal-dialog {
    margin-top: 40px;
  }
}
.modal.full-screen .modal-dialog {
  animation: none;
  height: 100%;
  margin: 0;
}
.modal.full-screen .modal-content {
  background: var(--black-900);
  border-radius: 0;
  height: 100%;
  padding: 0;
}
.modal.full-screen .modal-content > :first-child {
  height: 100%;
}

@keyframes slideModal {
  0% {
    top: 0;
    transform: translate(0%, 0%);
  }
  100% {
    top: 50%;
    transform: translate(0%, -50%);
  }
}
.modal-dialog {
  margin: 0 0 0;
  pointer-events: none;
  position: relative;
  width: auto;
}
@media screen and (min-height: 768px) {
  .modal-dialog {
    animation: slideModal 0.3s forwards;
  }
}
.modal-dialog.transparent-bg .modal-content {
  background-color: transparent;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 0);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 0);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 0);
}
.modal-dialog-centered::before {
  content: "";
  display: block;
  height: calc(100vh - 0);
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  background-clip: padding-box;
  background-color: var(--white);
  border: 0 solid transparent;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(150, 160, 169, 0.07);
  color: var(--black-900);
  display: flex;
  flex-direction: column;
  outline: 0;
  padding-bottom: 10px;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: var(--bg-popup);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1999;
}
.modal-backdrop.show {
  animation: showBackdrop 0.3s forwards;
}

@keyframes showBackdrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
.modal-header {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-100);
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 20px 30px;
}
.modal-header .close {
  margin: 30px 0 30px auto;
  padding: 30px 30px 20px 30px;
}
.modal-header.no-border {
  border-bottom: 0;
  padding-bottom: 0;
}

.modal-title {
  margin-bottom: 0;
}

.modal-body {
  flex: 1 1 auto;
  padding: 30px 30px 20px;
  position: relative;
}
.modal-body.scrollable {
  overflow-y: auto;
}
.modal-body.primary {
  background-image: radial-gradient(circle at 66% 14%, var(--primary-700), #005ad7 58%);
  border-radius: 10px;
  margin-bottom: -10px;
}

.modal-footer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px 30px;
}
.modal-footer > :not(:first-child) {
  margin-left: 15px;
}
.modal-footer > :not(:last-child) {
  margin-right: 15px;
}

.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 0 auto;
    width: 100%;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
}
@media (min-width: 576px) {
  .modal-sm {
    width: 370px;
  }
  .modal-lg {
    width: 570px;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    width: 768px;
  }
}
@media (max-width: 991px) {
  .mobile-size .modal-content {
    margin: 0 auto;
    width: 360px;
  }
}

.loading-state-image, .loading-state-circle, .loading-state-short, .loading-state-long {
  background-color: transparent !important;
  color: transparent !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.loading-state-image *, .loading-state-circle *, .loading-state-short *, .loading-state-long * {
  display: none;
}
.loading-state-image::before, .loading-state-circle::before, .loading-state-short::before, .loading-state-long::before {
  background-color: var(--gray-100);
  border-radius: 10px;
  color: transparent;
  content: "&nbsp;";
  display: inline-block;
  height: 100%;
  line-height: calc(100% - 1px) !important;
  width: 100%;
}

.loading-state-long {
  border-radius: 5px;
  width: 135px;
}

.loading-state-short {
  border-radius: 5px;
  width: 80px;
}

.loading-state-image::before {
  border-radius: inherit;
  line-height: inherit !important;
}

.loader {
  background-image: url("/assets/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  height: 30px;
  width: 30px;
}

.grayed-out {
  opacity: 0.3;
  pointer-events: none;
}

.info-box {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(150, 160, 169, 0.07);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 55px;
  position: relative;
}
.info-box.info-box--warning-bg {
  background-color: var(--warning-100);
}
.info-box::before {
  border-radius: 10px 0 0 10px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}
.info-box::after {
  content: "h";
  font-family: "iconomi-icons";
  font-size: 20px;
  left: 25px;
  position: absolute;
  top: 20px;
}

.info-box::before {
  background-color: var(--primary-700);
}
.info-box::after {
  color: var(--primary-700);
}
.info-box.info-box-with-border {
  border-color: var(--gray-100);
}

.info-box-warning::before {
  background-color: var(--warning-500);
}
.info-box-warning::after {
  color: var(--warning-500);
}
.info-box-warning.info-box-with-border {
  border-color: var(--gray-100);
}

.info-box-error::before {
  background-color: var(--error-500);
}
.info-box-error::after {
  color: var(--error-500);
}
.info-box-error.info-box-with-border {
  border-color: var(--gray-100);
}

.info-box-gray::before {
  background-color: var(--gray-100);
}
.info-box-gray::after {
  color: var(--gray-100);
}
.info-box-gray.info-box-with-border {
  border-color: var(--gray-100);
}

.info-box-secondary::before {
  background-color: var(--secondary-500);
}
.info-box-secondary::after {
  color: var(--secondary-500);
}
.info-box-secondary.info-box-with-border {
  border-color: var(--gray-100);
}

.info-box-purple::before {
  background-color: var(--purple);
}
.info-box-purple::after {
  color: var(--purple);
}
.info-box-purple.info-box-with-border {
  border-color: var(--gray-100);
}

.info-box-no-icon {
  padding: 30px;
}
.info-box-no-icon::after {
  content: "";
}

.info-box-with-border {
  border-style: solid;
  border-width: 1px 1px 1px 0;
  box-shadow: none;
}

.illustration-s {
  height: 100px;
}

.illustration-m {
  height: 120px;
}

.illustration-l {
  height: 200px;
}

.action-notice {
  font-weight: 400;
  background-color: var(--primary-50);
  border-radius: 8px;
  color: var(--primary-700);
  font-size: 12px;
  padding: 20px 20px 20px 50px;
  position: relative;
}
.action-notice * {
  display: inline-block;
}
.action-notice::before {
  color: var(--primary-700);
  content: "h";
  font-family: "iconomi-icons";
  font-size: 20px;
  left: 20px;
  line-height: 20px;
  position: absolute;
  top: 20px;
}
.action-notice.action-notice--bulb::before {
  content: "*";
  font-size: 16px;
}
.action-notice.action-notice--no-icon {
  padding: 20px;
}
.action-notice.action-notice--no-icon.action-notice--small {
  padding: 10px;
}
.action-notice.action-notice--no-icon::before {
  content: "";
}
.action-notice.action-notice--small {
  padding: 10px 10px 10px 40px;
}
.action-notice.action-notice--small::before {
  left: 10px;
  top: 10px;
}
.action-notice.action-notice--warning::before {
  color: var(--warning-500);
}
.action-notice.action-notice--warning-bg {
  background-color: var(--warning-100);
}

.list-number {
  font-weight: 800;
  background-color: var(--primary-700);
  border-radius: 100%;
  color: var(--white);
  flex: 0 0 20px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.h-100 .carousel-inner {
  height: 100%;
}
.h-100 .carousel-inner .carousel-item {
  height: 100%;
}

.input-group {
  border: solid 1px var(--gray-300);
  border-radius: 3px;
  display: flex;
  line-height: normal;
  padding: 15px 20px;
  transition: border-color 0.5s, box-shadow 0.5s;
  width: 100%;
}
.input-group input {
  border: 0;
  flex-grow: 1;
}
.input-group .dropdown,
.input-group button,
.input-group input {
  height: 19px;
}
.input-group button {
  padding-left: 0;
}
.input-group button .arrow-down {
  margin-left: 10px;
}
.input-group #dial-code {
  color: var(--black-900);
  margin-right: 5px !important;
  padding: 0 !important;
}

.hamburger {
  overflow: visible;
  transition-duration: 0.2s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
}
.hamburger.opened .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger.opened .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}
.hamburger.opened .hamburger-inner::before {
  opacity: 0;
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
}
.hamburger.white .hamburger-inner,
.hamburger.white .hamburger-inner::after,
.hamburger.white .hamburger-inner::before {
  background-color: var(--white);
}

.hamburger-box {
  display: inline-block;
  height: 18px;
  position: relative;
  width: 24px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: var(--black-900);
  border-radius: 4px;
  height: 2px;
  position: absolute;
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 24px;
}

.hamburger-inner {
  display: block;
  margin-top: -2px;
  top: 2px;
}
.hamburger-inner::before {
  top: 8px;
  transition-duration: 0.2s;
  transition-property: transform, opacity;
  transition-timing-function: ease;
}
.hamburger-inner::after {
  bottom: -10px;
  top: 16px;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.blinking-dot {
  align-items: center;
  background-color: var(--warning-500);
  border-radius: 50%;
  display: flex;
  flex: 0 0 8px;
  height: 8px;
  justify-content: center;
  position: relative;
  width: 8px;
}
.blinking-dot.blinking-dot--green {
  background-color: var(--secondary-500);
}
.blinking-dot.blinking-dot--green::before {
  border: 4px solid var(--positive-100);
}
.blinking-dot.blinking-dot--green::after {
  border: 2px solid var(--positive-100);
}
.blinking-dot.blinking-dot--negative {
  background-color: var(--negative-500);
}
.blinking-dot.blinking-dot--negative::before {
  animation: 4s expand-negative cubic-bezier(0.29, 0, 0, 1) infinite;
  animation-delay: 1s;
  border: 4px solid var(--negative-500);
}
.blinking-dot.blinking-dot--negative::after {
  animation: 4.01s expand-negative cubic-bezier(0.29, 0, 0, 1) infinite;
  animation-delay: 1.5s;
  border: 2px solid var(--negative-500);
}
.blinking-dot.blinking-dot--stop-on-hover:hover::before, .blinking-dot.blinking-dot--stop-on-hover:hover::after {
  animation: none;
}
.blinking-dot::before {
  animation: 4s expand cubic-bezier(0.29, 0, 0, 1) infinite;
  animation-delay: 1s;
  border: 4px solid var(--warning-500);
  border-radius: inherit;
  content: "";
  height: inherit;
  opacity: 0;
  position: absolute;
  width: inherit;
}
.blinking-dot::after {
  animation: 4.01s expand cubic-bezier(0.29, 0, 0, 1) infinite;
  animation-delay: 1.5s;
  border: 2px solid var(--warning-500);
  border-radius: inherit;
  content: "";
  height: inherit;
  opacity: 0;
  position: absolute;
  width: inherit;
}

@keyframes expand {
  1% {
    height: 0;
    opacity: 1;
    width: 0;
  }
  50% {
    background-color: none;
    border-color: var(--warning-100);
    border-width: 3px;
    height: 400%;
    opacity: 0;
    width: 400%;
  }
}
@keyframes expand-negative {
  1% {
    height: 0;
    opacity: 1;
    width: 0;
  }
  50% {
    background-color: none;
    border-color: var(--negative-500);
    border-width: 3px;
    height: 400%;
    opacity: 0;
    width: 400%;
  }
}
.status-circle {
  border-radius: 100%;
  height: 6px;
  width: 6px;
}
.status-circle.in-progress {
  background-color: var(--warning-500);
}
.status-circle.cancelled {
  background-color: var(--error-500);
}
.status-circle.done {
  background-color: var(--positive-500);
}

highcharts-chart {
  overflow: visible !important;
}

.highcharts-container {
  overflow: visible !important;
}

.emoji-image,
.emoji-image-native {
  display: inline-block;
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
  height: 18px;
  margin: 0 1px -4px;
  width: 18px;
}

.emoji-image {
  background-image: url("/assets/images/emojies.webp");
}