.hamburger {
  overflow: visible;
  transition-duration: .2s;
  transition-property: opacity, filter;
  transition-timing-function: linear;

  &.opened {
    .hamburger-inner {
      transform: translate3d(0, 8px, 0) rotate(45deg);

      &::after {
        transform: translate3d(0, -16px, 0) rotate(-90deg);
      }

      &::before {
        opacity: 0;
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      }
    }
  }

  &.white {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: var(--white);
    }
  }
}

.hamburger-box {
  display: inline-block;
  height: 18px;
  position: relative;
  width: 24px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: var(--black-900);
  border-radius: 4px;
  height: 2px;
  position: absolute;
  transition-duration: .2s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 24px;
}

.hamburger-inner {
  display: block;
  margin-top: -2px;
  top: 2px;

  &::before {
    top: 8px;
    transition-duration: .2s;
    transition-property: transform, opacity;
    transition-timing-function: ease;
  }

  &::after {
    bottom: -10px;
    top: 16px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }
}
