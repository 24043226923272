@import '../variables/z-indexes';
@import '../mixins/media-queries';
@import '../variables/grid-breakpoints';

$modal-dialog-margin: 0;
$modal-fade-transform: translate(0, 50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;
$modal-content-color: var(--black-900);
$modal-content-bg: var(--white);
$modal-content-border-width: 0;
$modal-content-border-color: transparent;
$modal-content-border-radius: 10px;
$modal-content-box: 0 3px 50px 0 rgba(150, 160, 169, .07);
$modal-backdrop-bg: var(--bg-popup);
$modal-header-padding: 30px 30px 20px 30px;
$modal-header-border-width: 1px;
$modal-header-border-color: var(--gray-100);
$modal-header-padding-y: -30px;
$modal-header-padding-x: 0;
$modal-inner-padding: 20px 30px;

$modal-xl: 768px;
$modal-lg: 570px;
$modal-sm: 370px;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Container that the modal scrolls within
.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  z-index: $z-index-modal;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  &.no-animation {
    .modal-dialog {
      animation: none;
      @include respond-above(md) {
        margin-top: 40px;
      }
    }
  }

  &.full-screen {
    .modal-dialog {
      animation: none;
      height: 100%;
      margin: 0;
    }

    .modal-content {
      background: var(--black-900);
      border-radius: 0;
      height: 100%;
      padding: 0;

      > :first-child {
        height: 100%;
      }
    }
  }
}

@keyframes slideModal {
  0% {
    top: 0;
    transform: translate(0%, 0%);
  }

  100% {
    top: 50%;
    transform: translate(0%, -50%);
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  margin: 0 $modal-dialog-margin $modal-dialog-margin;
  pointer-events: none;
  position: relative;
  width: auto;

  @media screen and (min-height: 768px) {
    animation: slideModal .3s forwards;
  }

  &.transparent-bg {
    .modal-content {
      background-color: transparent;
    }
  }

}

.modal-dialog-scrollable {
  display: flex; // IE10/11
  max-height: calc(100% - #{$modal-dialog-margin * 2});

  .modal-content {
    max-height: calc(100vh - #{$modal-dialog-margin * 2}); // IE10/11
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - #{$modal-dialog-margin * 2});

  // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
  &::before {
    content: '';
    display: block; // IE10
    height: calc(100vh - #{$modal-dialog-margin * 2});
  }

  // Ensure `.modal-body` shows scrollbar (IE10/11)
  &.modal-dialog-scrollable {
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

// Actual modal
.modal-content {
  background-clip: padding-box;
  background-color: $modal-content-bg;
  border: $modal-content-border-width solid $modal-content-border-color;
  // counteract the pointer-events: none; in the .modal-dialog
  border-radius: $modal-content-border-radius;
  box-shadow: $modal-content-box;
  color: $modal-content-color;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding-bottom: 10px;
  // Remove focus outline from opened modal
  pointer-events: auto;
  position: relative;

  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
}

// Modal background
.modal-backdrop {
  background-color: $modal-backdrop-bg;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $z-index-modal - 1;

  &.show {
    animation: showBackdrop .3s forwards;
  }
}

@keyframes showBackdrop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .9;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  align-items: flex-start; // so the close btn always stays on the upper right corner
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  display: flex;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: $modal-header-padding;

  .close {
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
    // auto on the left force icon to the right even when there is no .modal-title
    padding: $modal-header-padding;
  }

  &.no-border {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  flex: 1 1 auto;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  padding: 30px 30px 20px;
  position: relative;

  &.scrollable {
    overflow-y: auto;
  }

  &.primary {
    background-image: radial-gradient(circle at 66% 14%, var(--primary-700), #005ad7 58%);
    border-radius: 10px;
    margin-bottom: -10px;
  }
}

// Footer (for actions)
.modal-footer {
  align-items: center; // vertically center
  display: flex;
  justify-content: center; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: $modal-inner-padding;

  // Easily place margin between footer elements
  > :not(:first-child) {
    margin-left: 15px;
  }

  > :not(:last-child) {
    margin-right: 15px;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

// Scale up the modal
@include respond-above(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    margin: 0 auto;
    width: 100%;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

    .modal-content {
      max-height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }

  .modal-dialog-centered {
    min-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

    &::before {
      height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }
}

@include respond-above(sm) {
  .modal-sm {
    width: $modal-sm;
  }

  .modal-lg {
    width: $modal-lg;
  }
}

@include respond-above(lg) {
  .modal-xl {
    width: $modal-xl;
  }
}

.mobile-size {
  @include respond-below(lg) {
    .modal-content {
      margin: 0 auto;
      width: 360px;
    }
  }
}
