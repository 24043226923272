@charset 'UTF-8';
@import '../mixins/icons-style';

@font-face {
  font-display: swap;
  font-family: 'iconomi-icons';
  font-style: normal;
  font-weight: normal;
  // sass-lint:disable-block indentation, no-duplicate-properties
  src: url('../../fonts/icons/iconomi-icons.eot');
  src: url('../../fonts/icons/iconomi-icons.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/icons/iconomi-icons.woff') format('woff'),
  url('../../fonts/icons/iconomi-icons.ttf') format('truetype'),
  url('../../fonts/icons/iconomi-icons.svg#iconomi-icons') format('svg');
}

.icon-wrapper {
  background: var(--primary-700);
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

// sass-lint:disable-block no-important
[class^='icon-'],
[class*=' icon-'] {

  display: inline-flex;
  vertical-align: text-top;

  &::before {
    @include icon-font();
  }
}

.icon-checked::before {
  content: '\62';
}

.icon-lock::before {
  content: '\63';
}

.icon-search::before {
  content: '\65';
}

.icon-settings::before {
  content: '\66';
}

.icon-upload::before {
  content: '\67';
}

.icon-info::before {
  content: '\68';
  transform: rotate(180deg);
}

.icon-edit::before {
  content: '\6a';
}

.icon-trash::before {
  content: '\6b';
}

.icon-cancel::before {
  content: '\6c';
}

.icon-arrow::before {
  content: '\6d';
}

.icon-arrow {
  &.icon-arrow-animate {
    transition: transform .3s linear;
  }

  &.icon-arrow-up {
    transform: rotate(180deg);
  }

  &.icon-arrow-left {
    transform: rotate(90deg);
  }

  &.icon-arrow-right {
    transform: rotate(-90deg);
  }
}

.icon-undo::before {
  content: '\6e';
}

.icon-star-empty::before {
  content: '\6f';
}

.icon-arrow-long::before {
  content: '\70';
}

.icon-arrow-long {
  &.icon-arrow-long-right {
    transform: scaleX(-1);
  }

  &.icon-arrow-long-right-bottom {
    transform: rotate(225deg);
  }
}

.icon-calendar::before {
  content: '\71';
}

.icon-copy::before {
  content: '\72';
}

.icon-change::before {
  content: '\61';
}

.icon-change-horizontal::before {
  content: '\61';
  transform: rotate(90deg);
}

.icon-plus::before {
  content: '\73';
}

.icon-invite-friends::before {
  content: '\74';
}

.icon-eye::before {
  content: '\77';
}

.icon-eye-disabled::before {
  content: '\78';
}

.icon-link::before {
  content: '\79';
}

.icon-transfer-crypto::before {
  content: '\41';
}

.icon-airdrop::before {
  content: '\7a';
}

.icon-list::before {
  content: '\42';
}

.icon-exchange::before {
  content: '\43';
}

.icon-graph::before {
  content: '\44';
}

.icon-wallet::before {
  content: '\45';
}

.icon-cancel-circle::before {
  content: '\46';
}

.icon-payoff::before {
  content: '\47';
}

.icon-filter::before {
  content: '\48';
}

.icon-deposit::before {
  content: '\49';
}

.icon-withdraw::before {
  content: '\4a';
}

.icon-envelope::before {
  content: '\75';
}

.icon-facebook::before {
  content: '\76';
}

.icon-linkedin::before {
  content: '\4b';
}

.icon-reddit::before {
  content: '\4c';
}

.icon-twitter::before {
  content: '\4d';
}

.icon-youtube::before {
  content: '\4e';
}

.icon-support::before {
  content: '\4f';
}

.icon-user::before {
  content: '\50';
}

.icon-notifications::before {
  content: '\64';
}

.icon-notification-on::before {
  content: '\69';
}

.icon-notification-off::before {
  content: '\51';
}

.icon-pulse::before {
  content: '\52';
}

.icon-dots::before {
  content: '\53';
}

.icon-contests::before {
  content: '\54';
}

.icon-performance-earned::before {
  content: '\55';
}

.icon-performance-payed::before {
  content: '\56';
}

.icon-clock::before {
  content: '\57';
}

.icon-share::before {
  content: '\58';
}

.icon-pin::before {
  content: '\59';
}

.icon-like::before {
  content: '\5a';
}

.icon-like-full::before {
  content: '\30';
}

.icon-star::before {
  content: '\31';
}

.icon-gift::before {
  content: '\32';
}

.icon-automated-trading::before {
  content: '\33';
}

.icon-asset-return::before {
  content: '\34';
}

.icon-donation::before {
  content: '\35';
}

.icon-url::before {
  content: '\36';
}

.icon-emoji::before {
  content: '\37';
}

.icon-photo::before {
  content: '\38';
}

.icon-open::before {
  content: '\39';
}

.icon-close::before {
  content: '\21';
}

.icon-cryptocurrencies::before {
  content: '\22';
}

.icon-italic::before {
  content: '\23';
}

.icon-underline::before {
  content: '\24';
}

.icon-bold::before {
  content: '\25';
}

.icon-posts::before {
  content: '\26';
}

.icon-shield::before {
  content: '\27';
}

.icon-user-full::before {
  content: '\28';
}

.icon-internet::before {
  content: '\29';
}

.icon-bulb::before {
  content: '\2a';
}

.icon-earnings::before {
  content: '\2b';
}

.icon-filter-2::before {
  content: '\2c';
}

.icon-currency::before {
  content: '\2d';
}

.icon-savings::before {
  content: '\2e';
}

.icon-small-full-arrow::before {
  content: '\2f';
}

.icon-small-full-arrow--right {
  transform: rotate(-90deg);
}

.icon-rocket::before {
  content: '\e010';
}

.icon-social::before {
  content: '\3a';
}

.icon-activity::before {
  content: '\3c';
}

.icon-analytics::before {
  content: '\3d';
}

.icon-copiers::before {
  content: '\3e';
}

.icon-dashboard::before {
  content: '\3f';
}

.icon-settings-2::before {
  content: '\40';
}

.icon-credit-card::before {
  content: '\5b';
}

.icon-bank::before {
  content: '\5d';
}

.icon-grid::before {
  content: '\60';
}

.icon-shopping-cart::before {
  content: '\7b';
}

.icon-mobile::before {
  content: '\5e';
}

.icon-desktop::before {
  content: '\5f';
}

.icon-switch::before {
  content: '\7c';
}

.icon-discord::before {
  content: '\7d';
}

.icon-floppy::before {
  content: '\7e';
}

.icon-home::before {
  content: '\5c';
}

.icon-strategy::before {
  content: '\e000';
}

.icon-posts-2::before {
  content: '\e001';
}

.icon-more::before {
  content: '\e002';
}

.icon-wallet-fill::before {
  content: '\e003';
}

.icon-activity-2::before {
  content: '\e004';
}

.icon-star-half::before {
  content: '\e006';
}

.icon-star-1::before {
  content: '\e007';
}

.icon-star-empty-1::before {
  content: '\e005';
}

.icon-rss-square::before {
  content: '\e008';
}

.icon-telegram::before {
  content: '\e009';
}

.icon-double-quotes::before {
  content: '\e00a';
}

.icon-money::before {
  content: '\e00b';
}

.icon-automation::before {
  content: '\e00c';
}

.icon-x::before {
  content: '\e00d';
}

.icon-arrow-diagonally::before {
  content: '\e00e';
}

.icon-expand::before {
  content: '\e00f';
}

.icon-lock-2::before {
  content: '\3b';
}
