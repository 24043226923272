.border-box {
  border: 1px solid;
  border-radius: 8px;
}

.border-box-primary {
  border-color: var(--primary-700);
}

.border-box-gray-300 {
  border-color: var(--gray-300);
}

.border-box-gray-100 {
  border-color: var(--gray-100);
}
