.blinking-dot {
  align-items: center;
  background-color: var(--warning-500);
  border-radius: 50%;
  display: flex;
  flex: 0 0 8px;
  height: 8px;
  justify-content: center;
  position: relative;
  width: 8px;

  &.blinking-dot--green {
    background-color: var(--secondary-500);

    &::before {
      border: 4px solid var(--positive-100);
    }

    &::after {
      border: 2px solid var(--positive-100);
    }
  }

  &.blinking-dot--negative {
    background-color: var(--negative-500);

    &::before {
      animation: 4s expand-negative cubic-bezier(.29, 0, 0, 1) infinite;
      animation-delay: 1s;
      border: 4px solid var(--negative-500);
    }

    &::after {
      animation: 4.01s expand-negative cubic-bezier(.29, 0, 0, 1) infinite;
      animation-delay: 1.5s;
      border: 2px solid var(--negative-500);
    }
  }

  &.blinking-dot--stop-on-hover {
    &:hover {
      &::before,
      &::after {
        animation: none;
      }
    }
  }

  &::before {
    animation: 4s expand cubic-bezier(.29, 0, 0, 1) infinite;
    animation-delay: 1s;
    border: 4px solid var(--warning-500);
    border-radius: inherit;
    content: '';
    height: inherit;
    opacity: 0;
    position: absolute;
    width: inherit;
  }

  &::after {
    animation: 4.01s expand cubic-bezier(.29, 0, 0, 1) infinite;
    animation-delay: 1.5s;
    border: 2px solid var(--warning-500);
    border-radius: inherit;
    content: '';
    height: inherit;
    opacity: 0;
    position: absolute;
    width: inherit;
  }
}

@keyframes expand {
  1% {
    height: 0;
    opacity: 1;
    width: 0;
  }

  50% {
    background-color: none;
    border-color: var(--warning-100);
    border-width: 3px;
    height: 400%;
    opacity: 0;
    width: 400%;
  }
}

@keyframes expand-negative {
  1% {
    height: 0;
    opacity: 1;
    width: 0;
  }

  50% {
    background-color: none;
    border-color: var(--negative-500);
    border-width: 3px;
    height: 400%;
    opacity: 0;
    width: 400%;
  }
}

.status-circle {
  border-radius: 100%;
  height: 6px;
  width: 6px;

  &.in-progress {
    background-color: var(--warning-500);
  }

  &.cancelled {
    background-color: var(--error-500);
  }

  &.done {
    background-color: var(--positive-500);
  }
}
