@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake-animation {
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, 0);
  }
}

.floating {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: floating;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}
