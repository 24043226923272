@import '../variables/z-indexes';
@import '../mixins/media-queries';
@import 'bootstrap/scss/mixins';
// sass-lint:disable no-important, no-universal-selectors
// ========================================
// MAIN BODY LAYOUT
// ========================================

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

@page {
  margin: 0;
  size: auto;
}

html,
body {
  @media print {
    height: 100%;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    page-break-after: always;
  }
}

html {
  -webkit-text-size-adjust: 100%; // 4
}

body {
  @include typography-regular();
  background-color: var(--bg-content);
  color: var(--white); // default typography color
  font-family: 'Nunito', Arial, sans-serif;
  font-feature-settings: 'lnum';
  font-size: 14px; // default font-size
  line-height: 1.5; // default & only line height, do not change
  margin: 0;
  min-height: 100vh;
  position: relative;

  &.splash-screen {
    overflow: hidden;
    position: relative;

    &::before {
      background: url('/assets/images/icons/assets/logo.svg') no-repeat center;
      background-color: var(--gray-50);
      content: '';
      height: 100vh;
      position: absolute;
      width: 100vw;
      z-index: 1000000;
    }
  }

  @include respond-below(md) {
    font-size: 16px;
  }

  &.is-menu-opened {
    height: 100vh;
    overflow: hidden;
  }

  &.white {
    background-color: var(--white);
  }

  @media print {

    background-color: var(--white);

    * {
      visibility: hidden;
    }

    .section-to-print {
      left: 0;
      position: absolute;
      top: 0;
      visibility: visible;

      * {
        visibility: visible;
      }
    }
  }
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p {
  margin: 0;
}

label {
  display: inline-block;
  margin: 0;
}

input,
textarea,
select,
a {
  outline: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:not([href]) {
    color: inherit;
  }
}

.content-wrapper {
  z-index: $z-index-above-root;
}

%separator {
  height: 1px;
  width: 100%;
}

.separator-gray-100 {
  @extend %separator;
  background-color: var(--gray-100);
}

.separator-gray-300 {
  @extend %separator;
  background-color: var(--gray-300);
}

.separator-gray-900 {
  @extend %separator;
  background-color: var(--gray-900);
}

.separator-blue-500 {
  @extend %separator;
  background-color: var(--primary-700);
  height: 2px;
}

.separator-black-700 {
  @extend %separator;
  background-color: var(--black-700);
}

.separator-2 {
  height: 2px;
}

.separator-vertical {
  background-color: var(--gray-100);
  height: 100%;
  width: 1px;
}

.d-full-viewport-height {
  height: 100vh;
}

.d-min-viewport-height {
  min-height: 100vh;
}

.logo-img {
  height: 30px;
}

.dark-wrapper {
  background-color: var(--bg-menu-left);
}

.light-wrapper {
  background-color: var(--gray-50);
}

i {
  align-self: center;
  display: flex;
}

:focus {
  outline: 0;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  line-height: inherit;
  margin: 0; // Remove the margin in Firefox and Safari
}

button,
input {
  overflow: visible; // Show the overflow in Edge
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
}

button,
select {
  text-transform: none; // Remove the inheritance of text transform in Firefox
}

// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role='button'] {
  cursor: pointer;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990
select {
  word-wrap: normal;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type='button'], // 1
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; // 2
}

// Opinionated: add 'hand' cursor to non-disabled button elements.
button,
[type='button'],
[type='reset'],
[type='submit'] {
  &:not(:disabled) {
    cursor: pointer;
  }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  border: solid 1px var(--gray-300);
  // Textareas should really only resize vertically so they don't break their (horizontal) containers.
  border-radius: 10px;
  font-size: 14px;
  height: 150px;
  line-height: normal;
  overflow: auto; // Remove the default vertical scrollbar in IE.
  padding: 15px 20px;
  resize: none;
  transition: border-color .5s, box-shadow .5s;
  width: 100%;

  &::placeholder {
    @include typography-regular();
    color: var(--gray-300);
    font-size: 14px;
    line-height: normal;

    @include respond-below(md) {
      font-size: 16px;
    }
  }

  &:focus {
    border-color: var(--primary-700);
    box-shadow: 0 0 0 1px var(--primary-700);
  }
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  // This overrides the extra rounded corners on search inputs in iOS so that our
  // `.form-control` class can properly style them. Note that this cannot simply
  // be added to `.form-control` as it's not specific enough. For details, see
  // https://github.com/twbs/bootstrap/issues/11586.
  -webkit-appearance: none;
  outline-offset: -2px; // 2. Correct the outline style in Safari.
}

//
// Remove the inner padding in Chrome and Safari on macOS.
//

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

[hidden] {
  display: none !important;
}

.hide-value {
  filter: blur(7px);
}

.visibility-hidden {
  visibility: hidden;
}

.z-index-100 {
  z-index: 100;
}

.maintenance-absolute {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  right: 0;
  top: 120px;
  z-index: 10;

  .info-box {
    box-shadow: none;
  }
}

.ultimate-chat-pending-transactions {
  // sass-lint:disable no-important
  bottom: 45px !important;
  right: 6px !important;
}

.ultimate-chat-opened {
  // sass-lint:disable no-important
  z-index: 12000 !important;
}

.ultimate-chat-hidden {
  // sass-lint:disable no-important
  right: -10000px !important;
}
