@import '../variables/variables';
@import '../mixins/media-queries';

.floating-header {
  background-image: linear-gradient(101deg, #005ad7, #005ad7 100%);
  height: 325px;
  position: absolute;
  right: 0;
  top: map-get($top-menu-height, 'xs');
  width: 100%;
  z-index: -1;

  @include respond-above(sm) {
    top: map-get($top-menu-height, 'sm');
  }

  @include respond-above(md) {
    top: map-get($top-menu-height, 'md');
  }

  @include respond-above(lg) {
    top: map-get($top-menu-height, 'lg');
  }

  @include respond-above(xl) {
    top: map-get($top-menu-height, 'xl');
  }
}

.positive-500 {
  background-color: var(--positive-500);
}

.gray-300 {
  background-color: var(--gray-300);
}
