// ========================================
// Z INDEXES
// ========================================

$z-index-hide: -1;
$z-index-root: 0;
$z-index-above-root: 1;
$z-index-maintenance-warning: 700;
$z-index-header: 800;
$z-index-loading-bar: 810;
$z-index-search: 820;
$z-index-below-side-menu: 850;
$z-index-menu-toggle: 900;
$z-index-side-menu: 900;
$z-index-dialog: 999;
$z-index-datepicker: 1100;
$z-index-tooltip: 1100;
$z-index-modal: 2000;
$z-index-toastr: 2100;
$z-index-cookies: 12000;
