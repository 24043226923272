// sass-lint:disable no-important, no-universal-selectors
%loading-state {
  background-color: transparent !important;
  color: transparent !important;
  overflow: hidden !important;
  white-space: nowrap !important;

  * {
    display: none;
  }

  &::before {
    background-color: var(--gray-100);
    border-radius: 10px;
    color: transparent;
    content: '&nbsp;';
    display: inline-block;
    height: 100%;
    line-height: calc(100% - 1px) !important;
    width: 100%;
  }
}

.loading-state-long {
  @extend %loading-state;
  border-radius: 5px;
  width: 135px;
}

.loading-state-short {
  @extend %loading-state;
  border-radius: 5px;
  width: 80px;
}

.loading-state-circle {
  @extend %loading-state;
}

.loading-state-image {
  @extend %loading-state;

  &::before {
    border-radius: inherit;
    line-height: inherit !important;
  }
}

.loader {
  background-image: url('/assets/images/loader.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  height: 30px;
  width: 30px;
}

.grayed-out {
  opacity: .3;
  pointer-events: none;
}
