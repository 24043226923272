@import '../variables/z-indexes';
@import '../mixins/typography';

// sass-lint:disable no-important, property-units
ngb-datepicker {
  border: 0 !important;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, .15);
  color: var(--black-900);
  z-index: $z-index-datepicker;
}

.ngb-dp-month {
  background-color: var(--white);
  border-radius: 0 0 4px 4px;
  padding-bottom: 5px;

  &:first-child,
  &:last-child {
    .ngb-dp-week {
      padding: 0 10px !important;
    }
  }
}

.ngb-dp-arrow-btn {
  background: transparent;
  color: var(--primary-700);
  height: auto;
  min-width: auto;
  padding: 0;

  &:focus,
  &:active {
    outline: 0 !important;
  }

  &:disabled {
    background-color: transparent;
    color: var(--gray-300);
    cursor: not-allowed;
  }
}

.ngb-dp-day {
  &:focus,
  &:active {
    outline: 0;
  }

  .btn-light {
    background: 0 0;
    border-radius: .25rem;
    height: 2rem;
    line-height: 2rem;
    outline: 0;
    text-align: center;
    width: 2rem;

    &:hover {
      background: var(--primary-100);
      color: var(--white);
    }
  }

  .bg-primary {
    // selected
    background: var(--primary-700);
    color: var(--white);

    &.hover {
      background: var(--primary-100);
      color: var(--white);
    }

    &:hover {
      background: var(--primary-700);
      color: var(--white);
    }
  }

  &.disabled {
    cursor: not-allowed !important;

    .btn-light {
      color: var(--gray-300);

      &:hover {
        background: var(--gray-300);
        color: var(--white);
      }
    }
  }
}

.ngb-dp-navigation-chevron {
  border-width: 2px 2px 0 0 !important;
  height: 11px !important;
  width: 11px !important;
}

ngb-datepicker-navigation-select {
  > .custom-select {
    appearance: none;
    background: url('/assets/images/icons/fills/dropdown.svg') no-repeat center right 5px;
    background-color: var(--primary-700);
    background-size: 14px;
    border: 1.5px solid var(--primary-700);
    border-radius: 3px;
    color: var(--white);
    height: 24px !important;
    width: 80px;

    &:last-of-type {
      margin-right: 20px;
    }

    option {
      background-color: var(--white);
      color: var(--black-900);
    }
  }
}

.ngb-dp-header {
  background-color: var(--gray-100) !important;
}

.ngb-dp-weekday {
  @include typography-extra-bold();
  color: var(--gray-900) !important;
  font-size: 12px;
}

ngb-datepicker-month-view {
  padding: 0 10px 10px;

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    height: auto;
    width: 2.4rem;
  }
}

.ngb-dp-weekdays {
  background-color: var(--gray-100) !important;
  border-bottom: 0 !important;
  margin: 0;
  padding: 0 10px !important;
}

.datepicker-hidden-input {
  border: 0;
  height: 0;
  outline: 0;
  padding: 0;
  width: 0;
}

.ngb-dp-navigation-select {
  flex-flow: row-reverse;

}
