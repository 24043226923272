// ========================================
// COLORS
// ========================================
@import '../mixins/root-properties';

:root {
  @include root-property(--bg-content, #eaeff4);
  @include root-property(--bg-menu-left, #181f47);
  @include root-property(--bg-menu-left-selected, #0e132b);
  @include root-property(--bg-menu-top, #ffffff);
  @include root-property(--bg-popup, #1a2238);
  @include root-property(--black-900, #202326);
  @include root-property(--black-700, #2f3337);
  @include root-property(--black-500, #404448);
  @include root-property(--black-300, #4a4e52);
  @include root-property(--error-100, #f1b6b6);
  @include root-property(--error-300, #f4abab);
  @include root-property(--error-500, #d00c0c);
  @include root-property(--error-900, #b30303);
  @include root-property(--gray-50, #f6f8fa);
  @include root-property(--gray-100, #eef2f5);
  @include root-property(--gray-300, #cdd3d9);
  @include root-property(--gray-500, #b2bac2);
  @include root-property(--gray-900, #96a0a9);
  @include root-property(--gray-900, #78828a);
  @include root-property(--info-100, #c2c2db);
  @include root-property(--info-500, #343287);
  @include root-property(--info-900, #151357);
  @include root-property(--negative-100, #f9cece);
  @include root-property(--negative-500, #eb5c5c);
  @include root-property(--negative-900, #dd3030);
  @include root-property(--positive-100, #beecc6);
  @include root-property(--positive-500, #26bf40);
  @include root-property(--positive-900, #177326);
  @include root-property(--primary-50, #ebf2fe);
  @include root-property(--primary-100, #d8e4fc);
  @include root-property(--primary-500, #005ad7);
  @include root-property(--primary-500, #3a79f2);
  @include root-property(--primary-700, #005ad7);
  @include root-property(--primary-900, #2955a9);
  @include root-property(--secondary-50, #eafff5);
  @include root-property(--secondary-100, #d0f5e3);
  @include root-property(--secondary-500, #16cd72);
  @include root-property(--secondary-900, #12a45b);
  @include root-property(--tertiary-100, #bac1ce);
  @include root-property(--tertiary-500, #31476d);
  @include root-property(--tertiary-900, #1a2239);
  @include root-property(--warning-100, #fff0d4);
  @include root-property(--warning-300, #ffce6e);
  @include root-property(--warning-500, #ffce6e);
  @include root-property(--warning-900, #ffb03f);
  @include root-property(--white, #ffffff);
  @include root-property(--gold, #bdb37d);
  @include root-property(--gold-500, #f6ce6f);
  @include root-property(--bronze, #c58c6f);
  @include root-property(--orange, #ff5d00);
  @include root-property(--purple, #7a60be);
  @include root-property(--orange-300, #f7931a);
  @include root-property(--primary-50-business, #d4d8ec);
  @include root-property(--primary-100-business, #afb6dc);
  @include root-property(--primary-300-business, #7783bd);
  @include root-property(--primary-500-business, #6d7fd4);
  @include root-property(--primary-700-business, #031137);
  @include root-property(--primary-900-business, #0f142c);
  @include root-property(--secondary-50-business, #0c0c0d);
}


