@import '../mixins/typography';

.list-number {
  @include typography-extra-bold();
  background-color: var(--primary-700);
  border-radius: 100%;
  color: var(--white);
  flex: 0 0 20px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px;
}
