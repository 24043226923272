@import '../variables/z-indexes';
@import '../mixins/typography';

$tooltip-arrow-width: 14px;
$tooltip-arrow-height: 7px;
$arrow-size: 14px;
$tooltip-arrow-color: var(--white);
$tooltip-max-width: 200px;
$tooltip-padding: 10px;
$tooltip-bg: var(--white);
$tooltip-offset: 10px;

// sass-lint:disable no-important
.tooltip {
  display: block;
  position: absolute;
  word-wrap: break-word;
  z-index: $z-index-tooltip;

  .arrow {
    display: block;
    height: $tooltip-arrow-height;
    position: absolute;
    width: $tooltip-arrow-width;

    &::before {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0 $tooltip-offset + $tooltip-arrow-height 0;

  .arrow {
    bottom: $tooltip-offset;

    &::before {
      border-top-color: $tooltip-arrow-color;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      top: 0;
    }
  }

  .tooltip-inner {
    box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, .1);
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height 0 $tooltip-offset + $tooltip-arrow-height;

  .arrow {
    height: $tooltip-arrow-width;
    left: $tooltip-offset;
    width: $tooltip-arrow-height;

    &::before {
      border-right-color: $tooltip-arrow-color;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      right: 0;
    }
  }

  .tooltip-inner {
    box-shadow: -3px 0 20px 0 rgba(0, 0, 0, .1);
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-offset + $tooltip-arrow-height 0 $tooltip-arrow-height 0;

  .arrow {
    top: $tooltip-offset;

    &::before {
      border-bottom-color: $tooltip-arrow-color;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      bottom: 0;
    }
  }

  .tooltip-inner {
    box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, .1);
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-offset + $tooltip-arrow-height 0 $tooltip-arrow-height;

  .arrow {
    height: $tooltip-arrow-width;
    right: $tooltip-offset;
    width: $tooltip-arrow-height;

    &::before {
      border-left-color: $tooltip-arrow-color;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      left: 0;
    }
  }

  .tooltip-inner {
    box-shadow: -3px 0 20px 0 rgba(0, 0, 0, .1);
  }
}

.bs-tooltip-auto {
  &[x-placement^='top'] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^='right'] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^='left'] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  @include typography-regular();
  background-color: $tooltip-bg;
  border-radius: 4px;
  color: var(--gray-900);
  font-size: 12px;
  max-width: $tooltip-max-width;
  padding: $tooltip-padding;
  text-align: center;
}

.tooltip-auto-width {
  .tooltip-inner {
    max-width: initial;
  }
}

.tooltip-width-100 {
  .tooltip-inner {
    width: 100px;
  }
}

.tooltip-big-radius {
  .tooltip-inner {
    border-radius: 10px;
  }
}

.tooltip--primary-500 {
  .tooltip-inner {
    background-color: var(--primary-700);
    max-width: 355px;
    padding: 0;
  }

  &.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: var(--primary-700);
      }
    }
  }

  &.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: var(--primary-700);
      }
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: var(--primary-700);
      }
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: var(--primary-700);
      }
    }
  }

  &.bs-tooltip-auto {
    &[x-placement^='top'] {
      @extend .bs-tooltip-top;
    }

    &[x-placement^='right'] {
      @extend .bs-tooltip-right;
    }

    &[x-placement^='bottom'] {
      @extend .bs-tooltip-bottom;
    }

    &[x-placement^='left'] {
      @extend .bs-tooltip-left;
    }
  }
}
